import { CfOrderGetOneDataEntity } from '../../../../api/cf/api-cf-order-get-one/api-cf-order-get-one';
import { CloudFolderFileInfo } from '../../../../api/front/cloud-folder/api-get-cloud-folder-order-file';
import './image-list.one-stop.scss';
import { OneStopImage } from './one-stop-image';
import '../../../ui/loading/loading-popup.scss';
import { SelectCondition } from '../../order-preparation/order-preparation';
import { useState } from 'react';

type Props = {
  imgData: CloudFolderFileInfo[],
  kijshopCd: string,
  orderData: CfOrderGetOneDataEntity | null,
}

export const OneStopImageList = (props: Props) => {
  const {
    imgData,
    kijshopCd,
    orderData,
  } = props;
  const [open, setOpen] = useState(false);
  return (
    <div className={`one-stop-image-cnt ${open ? 'open' : ''}`}>
      <h2 onClick={() => setOpen(!open)}>
        <div>
          <span>
            セレクト画像一覧
          </span>
          <span>
            &nbsp;{`(${imgData.length}枚)`}
          </span>
        </div>
        <div>
          <SelectCondition selected={open} />
        </div>
      </h2>
      <hr />
      <div className={`one-stop-image-wrap  ${orderData ? '' : 'loading' }`}>
        {orderData ? (
          <div className="one-stop-image-list">
            {
              orderData && imgData.map((img) => (
                <OneStopImage
                  key={img.id}
                  imgData={img}
                  kijshopCd={kijshopCd}
                  orderId={orderData.id}
                />
              )
            )}
          </div>
        ) : (
          <div
          className="loading_popup__dots"
          style={{ fontSize: '0.5rem', animationDuration: `${1}s`, WebkitAnimationDuration: `${1}s` }}
          />
        )}
      </div>
    </div>
  )
}
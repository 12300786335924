import './cloud-folder-search-order.scss';
import React, { useCallback, useState } from 'react';
import { Input } from '../../ui/input/input';
import { Radiobutton } from '../../ui/input/radiobutton';
import { Button } from '../../ui/button/button';
import { useDispatch } from 'react-redux';
import { dialogActions } from '../slice/dialog-slice';

export type CloudFolderSearch = {
  type?: 'arrival' | 'delivery',
  startDate?: string,
  endDate?: string,
  name?: string,
  id?: string,
}

type Props = {
  searchCondition: CloudFolderSearch,
  setSearchCondition: (v: CloudFolderSearch) => void,
}

export const CloudFolderSearchOrder = (props: Props) => {
  const { searchCondition, setSearchCondition } = props;
  const dispatch = useDispatch();
  const [type, setType] = useState<'arrival' | 'delivery'>(searchCondition.type || 'arrival');

  const [startDate, setStartDate] = useState( searchCondition.startDate || '');
  const [endDate, setEndDate] = useState(searchCondition.endDate || '');
  const [name, setName] = useState(searchCondition.name || '');
  const [id, setId] = useState(searchCondition.id || '');

  const handlerCloseDialog = useCallback(() => {
    dispatch(dialogActions.pop());
  }, []);

  const handlerSearch = useCallback(() => {
    const search: CloudFolderSearch = { type, startDate, endDate, name, id };
    setSearchCondition(search);
    handlerCloseDialog();
  }, [type, startDate, endDate, name, id]);

  return (
    <div className="dialog_contents cloud-folder-search-order">
      <div>
        <div className={'cloud-folder-search-order__date__type-select'}>
          <Radiobutton
            label="入荷日"
            checkState={type}
            value="arrival"
            onClick={() => setType('arrival')}
          />
          <Radiobutton
            label="納期"
            checkState={type}
            value="delivery"
            onClick={() => setType('delivery')}
          />
        </div>
        <div className={'cloud-folder-search-order__date__input'}>
          <div>
            <Input
              type={'date'}
              value={startDate}
              max={endDate}
              onChange={(e) => setStartDate(e.target.value)}
              onBlur={() => {
                if (endDate && startDate > endDate) setStartDate(endDate);
              }}
            />
          </div>
          〜
          <div>
            <Input
              type={'date'}
              value={endDate}
              min={startDate}
              onChange={(e) => setEndDate(e.target.value)}
              onBlur={() => {
                if (startDate && startDate > endDate) setEndDate(startDate);
              }}
            />
          </div>
        </div>
      </div>
      <div className={'cloud-folder-search-order__info'}>
        <div className={'cloud-folder-search-order__info__label'}>
          注文名（お客様名）
        </div>
        <div className={'cloud-folder-search-order__info__input'}>
          <Input
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
      </div>
      <div className={'cloud-folder-search-order__info'}>
        <div className={'cloud-folder-search-order__info__label'}>
          フォルダ番号
        </div>
        <div className={'cloud-folder-search-order__info__input'}>
          <Input
            value={id}
            onChange={(e) => setId(e.target.value)}
            onBlur={(e) => {
              setId(e.target.value.replace(/\D/g, ''));
            }}
          />
        </div>
      </div>

      <div className="dialog_contents__footer">
        <Button
          label="キャンセル"
          onClick={handlerCloseDialog}
          color={'light'}
        />
        <Button
          label="OK"
          onClick={handlerSearch}
        />
      </div>
    </div>
  );
};

import { EditableImage } from '../image-edit/editable-image';
import { ResponseBase } from '../../../api/response-base';
import { CustomError } from '../../../models/custom-error';
import { ApiImagesPost, ImagesPostResponse } from '../../../api/front/images/api-images';
import { DateFormatter } from '../../../utilities/date-formatter';

export class UploadRequest {

  static MAX_RETRY = 3;

  request: ApiImagesPost;
  busy = false;

  private retryCounter = 0;
  private readonly onStart: () => void;
  private readonly onSuccess: (path: string) => void;
  private readonly onError: (msg: string) => void;

  constructor(
    editableImage: EditableImage,
    originalFile: File,
    onStart: () => void,
    onSuccess: (path: string) => void,
    onError: (msg: string) => void,
  ) {
    this.onStart = onStart;
    this.onSuccess = onSuccess;
    this.onError = onError
    /* アップロード画像（kind === '6'）の場合は exif 情報を必須で送る */
    const imageData = editableImage.kind === '6' ? {
      id: editableImage.id,
      exifCreated: editableImage.exif.createDate ? DateFormatter.date2str(editableImage.exif.createDate, 'YYYYMMDD_HHmmSS') : '',
      exifColorSpace: editableImage.exif.colorSpace || '',
      exifMake: editableImage.exif.make || '',
      exifModel: editableImage.exif.model || '',
      exifOrientation: editableImage.exif.orientation || 0,
      width: Number(editableImage.realWidth) || 0,
      height: Number(editableImage.realHeight) || 0,
    } : {};
    this.request = new ApiImagesPost({
      kijshopCd: editableImage.kijshopCd,
      shopOrderId: editableImage.shopOrderId,
      kind: editableImage.kind,
      filename: editableImage.name,
      data: originalFile,
      hash: editableImage.hash,
      dataThumb: editableImage.thumbnailBase64.replace(/^data:\w+\/\w+;base64,/, ''),
      ...imageData,
    });
    // this.request = new ApiProductImagePost({
    //   kijshopCd: editableImage.kijshopCd,
    //   shopOrderId: editableImage.shopOrderId,
    //   ...(editableImage.orderId ? (
    //     {
    //       inputGroup: {
    //         orderId: editableImage.orderId,
    //       }
    //     }
    //   ) : (
    //     {}
    //   )),
    //   orderSelect: {
    //     selectFileName: {
    //       file: editableImage.original,
    //     }
    //   },
    //   orderMethodId: '40',
    //   itemNo: '1',
    //   page: '1',
    // });
  }

  async upload() {
    try {
      this.busy = true;
      this.onStart();
      const res = await this.request.do();
      if (res instanceof CustomError) {
        console.log('[upload] error ... : ', res);
        await this.retry();
      } else {
        if (String(res.error.errorCode) === '400') {
          console.error('error: 400');
          await this.retry();
        } else {
          const path = (res as ResponseBase<ImagesPostResponse>).body.data?.filename || '';
          console.log('[upload] success : ', path);
          this.onSuccess(path);
        }
      }
    } catch (e) {
      console.log('[upload] error ... : ', e);
      await this.retry();
    }
  }

  private async retry() {
    if (this.retryCounter >= UploadRequest.MAX_RETRY) {
      this.onError('画像のアップロードに失敗しました。');
      return;
    }
    this.retryCounter += 1;
    console.log('[upload] retry : ', this.retryCounter);
    await this.upload();
  }

}

import { XmlClass } from '../xml-class';
import { IOrderInfoData } from '../../interface/order/i-order-info-data';
import { XmlClassName } from '../../model/xml-class-name';
import { TOrderInfoDataMetaModel, TOrderInfoDataViewModel } from '../../model/order/xml-order-info-data-model';
import { OrderPartsXml } from './order-parts-xml';
import { OrderPageBreakXml } from './order-page-break-xml';
import { OrderPageDataXml } from './order-page-data-xml';

type TView = Partial<TOrderInfoDataViewModel>;
type TMeta = Partial<TOrderInfoDataMetaModel>;

/**
 * order-info-data は商品,オプション商品,レタッチ単位で作成（把握分）
 */
export class OrderInfoDataXml extends XmlClass<IOrderInfoData, TView, TMeta, [string]> {

  // indexes!: [string];

  name: XmlClassName = 'order-info-data';
  viewModel: TView = {};
  metaModel: TMeta = {};

  // order-info-data に紐づく order-parts
  orderParts?: OrderPartsXml;
  // order-info-data に紐づく order-page-break
  orderPageBreak?: OrderPageBreakXml;

  // オプション商品を含む全ての order-page-data（XML構造化のタイミングで外部から設置）
  allOrderPageDataXml: OrderPageDataXml[] = [];
  // オプション商品の order-info-data
  optionOrderInfoDataXml: OrderInfoDataXml[] = [];

  constructor(shopOrderId: string, data: { version: string, indexes: [number | string] }) {
    super(shopOrderId, data);
  }

  // ページ数取得
  get pageCount() {
    // const partsData = (this.orderParts?.orderPartsData(2)) ? this.orderParts?.orderPartsData(2) : this.orderParts?.orderPartsData(1);
    const partsData = this.orderParts?.orderPartsDataMain();
    if (!partsData?.viewModel) {
      return 0;
    }
    return Number(partsData.viewModel.pageCount || 0);
  }
  // 画像枚数上限/下限取得
  get imageCount() {
    // const partsData = (this.orderParts?.orderPartsData(2)) ? this.orderParts?.orderPartsData(2) : this.orderParts?.orderPartsData(1);
    const partsData = this.orderParts?.orderPartsDataMain();
    if (!partsData?.viewModel) {
      return {
        minImageCount: 0,
        maxImageCount: 0,
      };
    }
    return {
      minImageCount: Math.floor((Number(partsData.viewModel.minPageImageCount || 1) * this.pageCount) + Number(partsData.viewModel.minImageAdd || 0)),
      maxImageCount: Math.ceil((Number(partsData.viewModel.maxPageImageCount || 1) * this.pageCount) +  + Number(partsData.viewModel.maxImageAdd || 0)),
    };
  }
  // 全 order-page-data 取得
  get orderPageDataArr(): OrderPageDataXml[] {
    let arr: OrderPageDataXml[] = [];
    this.orderParts?.orderPartsDataArr.forEach((partsData) => partsData.orderPage?.orderPageDataArr.forEach((pageData) => {
      arr.push(pageData);
    }));
    return arr;
  }

  protected init() {
    this.xmlUniqueName = this.xmlUniqueName || `PPM_${this.shopOrderId}_11_11_orderInfoData_${this.indexes[0]}.xml`;
    this.xmlParentName = this.xmlParentName || `PPM_${this.shopOrderId}_11_11_orderInfo.xml`;
    this.rootTagModel = {
      ...this.rootTagModel,
      parentXPath: '/lnwOrderInfo/orderInfo',
      parentPath: this.xmlParentName,
    };
    this.docking({}, {});
  }

  protected docking(v: TView, m: TMeta) {
    this.xmlModel = {
      lnwOrderInfoData: {
        $: { ...this.rootTagModel },
        data: [{
          $: {
            id: m.id,
            agentOrderShopCode: m.agentOrderShopCode,
            parentID: m.parentId,
          },
          deliveryData: v.deliveryData ? [{
            $: { id: v.deliveryData },
          }] : undefined,
          category: v.category && [{
            $: {
              categoryID: v.category?.id,
              name: v.category?.name,
            },
          }],
          preSelectInfo: (v.productTagId || v.coverId || v.coverColorId || v.boardInfo) ? [{
            $: {
              productTagID: v.productTagId,
              coverID: v.coverId,
              coverColorID: v.coverColorId,
              boardInfoID: v.boardInfo,
            },
          }] : undefined,
          item: v.item && [{
            $: {
              itemID: v.item?.id,
              name: v.item?.name,
            },
          }],
          goods: v.goods && [{
            $: {
              name: v.goods?.name,
              goodsID: v.goods?.goodsId,
              shortName: v.goods?.shortName,
              productType: v.goods?.productType,
            },
          }],
          executeProcess: v.surrogateProcess?.id !== '2010' && v.executeProcess ? [v.executeProcess] : undefined,
          // レタッチでのバグ再現
          excuteProcess: v.surrogateProcess?.id === '2010' && v.executeProcess ? [v.executeProcess] : undefined,
          openDirection: v.openDirection ? [v.openDirection] : undefined,
          surrogateProcess: v.surrogateProcess ? [{
            $: {
              id: v.surrogateProcess.id,
              name: v.surrogateProcess.name,
              no: v.surrogateProcess.no,
            },
            ...v.surrogateProcess.data
          }] : undefined,
          selectImageFolder: v.selectImageFolder ? [v.selectImageFolder] : undefined,
          selectCompletionProductFolder: v.selectCompletionProductFolder ? [v.selectCompletionProductFolder] : undefined,
          designType: v.designType && [{
            $: {
              templateGroupID: v.designType.templateGroupId,
              templateID: v.designType.templateId,
              name: v.designType.name,
            },
          }],
          infoOutputCount: v.infoOutputCount ? [v.infoOutputCount] : undefined,
          mailRemarks: v.mailRemarks ? [v.mailRemarks] : undefined,
          isNeedAgreement: m.isNeedAgreement ? [m.isNeedAgreement] : undefined,
          productItemInfo: (v.descriptionInfo?.name || v.descriptionInfo?.date || v.descriptionInfo?.location || v.descriptionInfo?.pageType) ? [{
            $: {
              name: v.descriptionInfo?.name,
              date: v.descriptionInfo?.date,
              location: v.descriptionInfo?.location,
            },
            // pagetype: [{
            //   jptg380012: v.descriptionInfo.pageType ? [v.descriptionInfo.pageType] : undefined,
            // }],
            pagetype: ((v.descriptionInfo?.name || v.descriptionInfo?.date || v.descriptionInfo?.location) && v.descriptionInfo?.pageType) ? [v.descriptionInfo.pageType] : (v.descriptionInfo?.pageType ? [{}] : undefined),
          }] : undefined,
          optionEnableCondition: v.optionEnableCondition && [{
            $: {
              parentMaxPageCount: v.optionEnableCondition.parentMaxPageCount,
              parentMinPageCount: v.optionEnableCondition.parentMinPageCount,
            },
          }],
          totalPageCount: v.totalPageCount ? [v.totalPageCount] : undefined,
          remarks: v.remarks ? [v.remarks.replace(/\r\n/g, '\\r\\n').replace(/\n/g, '\\r\\n')] : undefined,
          orderExtensionInfo: v.orderExtensionInfo && [{
            $: {
              orderMethodKbn: v.orderExtensionInfo.orderMethodKbn,
              selectADFlg: v.orderExtensionInfo.selectADFlg,
              selectNoticePattern: v.orderExtensionInfo.selectNoticePattern,
              selectPriceInfoId: v.orderExtensionInfo.selectPriceInfoId,
            }
          }],
        }],
        orderParts: m.path?.orderParts ? [{
          $: { path: m.path?.orderParts },
        }] : undefined,
        orderPageBreak: m.path?.orderPageBreak ? [{
          $: { path: m.path?.orderPageBreak },
        }] : undefined,
        layoutFinish: m.layoutFinish ? [{
          $: { flag: m.layoutFinish },
        }] : undefined,
      },
    };
  }

  protected split(x: IOrderInfoData) {
    if (!x?.lnwOrderInfoData?.data || !x.lnwOrderInfoData.$) {
      this.viewModel = {};
      this.metaModel = {};
      return;
    }
    const data = x.lnwOrderInfoData.data?.[0]
    this.viewModel = {
      deliveryData: data?.deliveryData?.[0]?.$.id,
      executeProcess: data?.surrogateProcess?.[0]?.$.id !== '2010' ? (data?.executeProcess?.[0] || (data?.executeProcess ? {} : undefined)) : data?.excuteProcess?.[0],
      // surrogateProcessId: data?.surrogateProcess?.[0]?.$.id,
      surrogateProcess: data?.surrogateProcess?.[0] ? {
        id: data.surrogateProcess[0].$.id,
        no: data.surrogateProcess[0].$.no,
        name: data?.surrogateProcess[0].$.name,
        data: (() => {
          const { $, ..._obj } = data?.surrogateProcess?.[0] || { $: '' };
          const obj: { [key: string]: [''] } = {};
          Object.keys(_obj).forEach((key) => {
            obj[key] = [''];
          });
          return obj;
        })(),
      } : undefined,
      selectImageFolder: data?.selectImageFolder?.[0],
      selectCompletionProductFolder: data?.selectCompletionProductFolder?.[0],
      category: data?.category?.[0]?.$ && {
        id: data.category?.[0]?.$?.categoryID || '',
        name: data.category?.[0]?.$?.name || '',
      },
      item: data?.item?.[0]?.$ && {
        id: data.item?.[0]?.$?.itemID || '',
        name: data.item?.[0]?.$?.name || '',
      },
      goods: data?.goods?.[0]?.$ && {
        goodsId: data.goods?.[0]?.$?.goodsID || '',
        name: data.goods[0]?.$?.name || '',
        shortName: data.goods?.[0]?.$?.shortName || '',
        productType: data.goods?.[0]?.$?.productType,
      },
      orderMethod: data?.surrogateProcess?.[0].$ && {
        id: data.surrogateProcess?.[0]?.$?.id || '',
        name: data.surrogateProcess?.[0]?.$?.name || '',
      },
      optionEnableCondition: data?.optionEnableCondition?.[0]?.$ && {
        parentMinPageCount: data.optionEnableCondition?.[0]?.$?.parentMinPageCount,
        parentMaxPageCount: data.optionEnableCondition?.[0]?.$?.parentMaxPageCount,
      },
      designType: data?.designType?.[0]?.$ && {
        templateGroupId: data.designType?.[0]?.$?.templateGroupID || '',
        templateId: data.designType?.[0]?.$?.templateID || '',
        name: data.designType?.[0]?.$?.name || '',
      },
      descriptionInfo: data?.productItemInfo?.[0]?.$ && {
        name: data.productItemInfo?.[0]?.$?.name,
        date: data.productItemInfo?.[0]?.$?.date,
        location: data.productItemInfo?.[0]?.$?.location,
        // pageType: data.productItemInfo[0].pagetype?.[0].jptg380012 || '',
        pageType: data?.productItemInfo?.[0]?.pagetype?.[0] || (data?.productItemInfo?.[0]?.pagetype ? {} : undefined),
      },
      remarks: data?.remarks?.[0]?.replace(/\\r\\n/g, '\r\n') || '',
      productTagId: data?.preSelectInfo?.[0]?.$?.productTagID,
      coverId: data?.preSelectInfo?.[0]?.$?.coverID,
      coverColorId: data?.preSelectInfo?.[0]?.$?.coverColorID,
      boardInfo: data?.preSelectInfo?.[0]?.$?.boardInfoID,
      infoOutputCount: data?.infoOutputCount?.[0],
      mailRemarks: data?.mailRemarks?.[0],
      openDirection: data?.openDirection?.[0],
      totalPageCount: data?.totalPageCount?.[0],
      orderExtensionInfo: data?.orderExtensionInfo?.[0]?.$ && {
        orderMethodKbn: data.orderExtensionInfo?.[0].$.orderMethodKbn || '',
        selectADFlg: data.orderExtensionInfo?.[0].$.selectADFlg || '',
        selectNoticePattern: data.orderExtensionInfo?.[0].$.selectNoticePattern || '',
        selectPriceInfoId: data.orderExtensionInfo?.[0].$.selectPriceInfoId,
      },
    };
    this.metaModel = {
      id: data?.$?.id,
      agentOrderShopCode: data?.$?.agentOrderShopCode,
      parentId: data?.$?.parentID,
      isNeedAgreement: data?.isNeedAgreement?.[0],
      path: {
        orderPageBreak: x.lnwOrderInfoData?.orderPageBreak?.[0]?.$?.path || '',
        orderParts: x.lnwOrderInfoData?.orderParts?.[0]?.$?.path || '',
      },
      layoutFinish: x.lnwOrderInfoData?.layoutFinish?.[0]?.$.flag,
    };
    this.rootTagModel = Object.assign(this.rootTagModel, x.lnwOrderInfoData.$);
  }

  changeIndexes(indexes: [string]) {
    this.indexes = indexes;
    this.xmlUniqueName = `PPM_${this.shopOrderId}_11_11_orderInfoData_${this.indexes[0]}.xml`;
    this.xmlParentName = `PPM_${this.shopOrderId}_11_11_orderInfo.xml`;
    this.rootTagModel = {
      ...this.rootTagModel,
      parentPath: this.xmlParentName,
    };
  }
}

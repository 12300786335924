import { ApiBaseFront } from "../api-base-front";

type Params = {
  kijshopCd: string,
  shopOrderId: string,
};

export class ApiCreateSelectId extends ApiBaseFront {
  constructor(param: Params) {
    const {
      kijshopCd,
      shopOrderId,
    } = param;

    super(
      'POST',
      `/api/v1/request/${kijshopCd}/${shopOrderId}/create/selectId`,
      'JSON',
      {},
    );
  }
}

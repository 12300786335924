import { ApiBaseFront } from "../api-base-front";

type Params = {
  kijshopCd: string,
};

export type ShopExpiredDataEntity = {
  /** 保存期間 */
  orderExpiredDay: number,
}

export class ApiGetShopExpired extends ApiBaseFront<ShopExpiredDataEntity> {
  constructor(param: Params) {
    const {
      kijshopCd,
    } = param;

    super(
      'GET',
      `/api/v1/c-folder/shop/${kijshopCd}/keep/expired`,
      'JSON',
      {},
    );
  }
}

import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch } from "../../../../app/hooks";
import { apiActions } from "../../../../slices/api-slice";
import { ApiMetaShopOrderGet, MetaShopOrderGetResponse } from "../../../../api/front/meta/api-meta-shop-order";
import { ResponseBase } from "../../../../api/response-base";
import { RoutingPath } from "../../../../routes/routing-path";
import { push } from "connected-react-router";

type Props = {
  kijshopCd: string,
  shopOrderId: string,
}

export const useCheckOneStop = (props: Props) => {
  const {
    kijshopCd,
    shopOrderId,
  } = props;
  const [isOneStop, setIsOneStop] = useState(false);
  const dispatch = useAppDispatch();
  
  const getMeta = useCallback(() => {
    dispatch(apiActions.run(
      new ApiMetaShopOrderGet({
        kijshopCd,
        shopOrderId,
      },
    ), {
      onSuccess: (res: ResponseBase<MetaShopOrderGetResponse>) => {
        if (res.body?.data?.cfOrderId) {
          dispatch(push(RoutingPath.cnv.oneStopOrdersPreparation({ kijshopCd, shopOrderId })));
        }
    }}));
  }, [dispatch, kijshopCd, shopOrderId]);
  
  
  useEffect(() => {
    getMeta();
  }, [kijshopCd, shopOrderId]);
}
import { ApiBaseFront } from "../api-base-front";

type Params = {
  kijshopCd: string,
  shopOrderId: string,
  // 再発注フラグ
  isReRequest: number,
  debug: 0 | 1 | 2,
};

export class ApiLabOrderAlignment extends ApiBaseFront<{}> {
  constructor(param: Params) {
    const {
      kijshopCd,
      shopOrderId,
      ...defaultProps
    } = param;

    super(
      'POST',
      `/api/v1/request/${kijshopCd}/${shopOrderId}/orderAlignment`,
      'JSON',
      defaultProps,
    );
  }
}

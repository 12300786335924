import { useCallback, useEffect, useMemo, useState } from "react";
import { ApiCfOrderGetOne, CfOrderGetOneDataEntity } from "../../../../api/cf/api-cf-order-get-one/api-cf-order-get-one";
import { useAppDispatch } from "../../../../app/hooks";
import { apiActions } from "../../../../slices/api-slice";
import { ApiMetaShopOrderGet, MetaShopOrderGetResponse } from "../../../../api/front/meta/api-meta-shop-order";
import { ResponseBase } from "../../../../api/response-base";
import { ApiGetCloudFolderOrderFile, CloudFolderFileInfo, CloudFolderGetFileList } from "../../../../api/front/cloud-folder/api-get-cloud-folder-order-file";
import { push } from "connected-react-router";

export const SELECT_ORDER_NAME = 'セレクトからおまかせ';

type Props = {
  kijshopCd: string,
  shopOrderId: string,
  redirectPath?: string,
  onGetFiles?: (files: CloudFolderGetFileList['fileList']) => void,
}

export const useCfData = (props: Props) => {
  const {
    kijshopCd,
    shopOrderId,
    redirectPath,
    onGetFiles,
  } = props;
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [cfOrderData, setCfOrderData] = useState<null | CfOrderGetOneDataEntity>(null);
  const [images, setImages] = useState<CloudFolderFileInfo[]>([]);
  const [metaData, setMetaData] = useState<MetaShopOrderGetResponse | null>(null);
  const getCfDataOnly = useCallback(() => {
    return new Promise<CfOrderGetOneDataEntity>((resolve, reject) => {
      dispatch(apiActions.run(
        new ApiMetaShopOrderGet({
          kijshopCd,
          shopOrderId,
        },
      ), {
        onSuccess: (res: ResponseBase<MetaShopOrderGetResponse>) => {
          if (!res?.body?.data?.cfOrderId) {
            return;
          }
          if (res.body.data) {
            setMetaData(res.body.data);
          }
          dispatch(apiActions.run(
            new ApiCfOrderGetOne({
              kijshopCd,
              orderId: res?.body?.data?.cfOrderId,
            },
          ), {
            onSuccess: (res: ResponseBase<CfOrderGetOneDataEntity>) => {
              if (Number(res.error.errorCode) === 200 && !!res.body.data) {
                setCfOrderData(res.body.data);
                resolve(res.body.data);
              } else {
                reject();
              }
            },
            onError: () => reject(),
          }));
        }
      }));
    });
  }, [dispatch, kijshopCd, shopOrderId]);
  const getCfData = useCallback(() => {
    setIsLoading(true);
    dispatch(apiActions.run(
      new ApiMetaShopOrderGet({
        kijshopCd,
        shopOrderId,
      },
    ), {
      onSuccess: (res: ResponseBase<MetaShopOrderGetResponse>) => {
        if (!res?.body?.data?.cfOrderId) {
          setIsLoading(false);
          if (redirectPath) {
            dispatch(push(redirectPath));
          }
          return;
        }
        if (res.body.data) {
          setMetaData(res.body.data);
        }
        dispatch(apiActions.run(
          new ApiCfOrderGetOne({
            kijshopCd,
            orderId: res?.body?.data?.cfOrderId,
          },
        ), {
          onSuccess: (res: ResponseBase<CfOrderGetOneDataEntity>) => {
            if (Number(res.error.errorCode) === 200 && !!res.body.data) {
              setCfOrderData(res.body.data);
              dispatch(apiActions.run(
                new ApiGetCloudFolderOrderFile({
                  kind: 1,
                  kijshopCd,
                  orderId: res?.body?.data?.id,
                  thumbOnly: 1,
                }), {
                  onSuccess: (imageData: ResponseBase<CloudFolderGetFileList>) => {
                    setIsLoading(false);
                    setImages(imageData.body.data?.fileList ?? []);
                    onGetFiles?.(imageData.body.data?.fileList ?? []);
                  },
                  onError: () => setIsLoading(false),
                })
              );
            } else {
              setIsLoading(false);
            }
          },
          onError: () => setIsLoading(false),
        }));    
      },
      onError: () => setIsLoading(false),
    }));
  }, [
    redirectPath,
    kijshopCd,
    shopOrderId,
    dispatch,
    onGetFiles,
  ]);
  useEffect(() => {
    getCfData();
  }, [kijshopCd, shopOrderId]);

  return {
    cfFiles: images,
    cfOrderData,
    cfIsLoading: isLoading,
    orderMetaData: metaData,
    getCfData: getCfDataOnly,
  }
}
import { Select } from '../../ui/select/select';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../app/hooks';
import * as lodash from 'lodash';
import { ordersActions, OrdersSliceState, PartialOrderSliceState } from '../../../slices/orders-slice';
import { dialogActions } from '../../dialog/slice/dialog-slice';
import { HowToOrder, ImgType } from '../../dialog/unique/how-to-order';
import { OrdersDataState } from '../../../slices/orders/orders-data-slice';
import { createDebugList } from '../../../slices/debug-slice';
import { useParams } from 'react-router-dom';
import { PathParams, RoutingPath } from '../../../routes/routing-path';
import { NumUpDown } from '../../ui/input/num-up-down';
import { LogDecorator } from '@tenryu/log-decorator';
import { Button } from '../../ui/button/button';
import iconHelp from '../../../assets/img/icon/help_icon.svg';
import { apiActions } from '../../../slices/api-slice';
import { ApiAllTemplate } from '../../../api/back/template/api-all-template';
import { SELECT_ORDER_NAME } from '../one-stop-order-preparation/hooks/use-cf-data';

const quantityCheck = (category: string) => {
  switch (category) {
    case 'jp0310':
    case 'jp0376':
    case 'jp0411':
    case 'jp0425':
    case 'jp0426':
    case 'jp0427':
      return false;
    default:
      return true;
  }
};

// const checkQuantity = (order: OrdersSliceState, _quantity: string | number) => {
//   const quantity = Number(_quantity);
//   if (Number(quantity) < 1 || !Number.isInteger(quantity)) {
//     return false;
//   }
//   const category = order.itemInfo.category.value;
//   const item = order.itemDetail.itemSelect.value;
//   switch (category) {
//     case 'jp0310':
//     case 'jp0376':
//     case 'jp0411':
//     case 'jp0425':
//     case 'jp0426':
//     case 'jp0427':
//       return quantity <= 99;
//     default:
//   }
//   switch (item) {
//     case 'jptg301625':
//       return quantity <= 99;
//     case 'jptg301626':
//     case 'jptg301627':
//       return quantity <= 9;
//     default:
//       return quantity <= 9999;
//   }
// };

const checkQuantity = (v: string | number, max: number) => {
  return !(Number(v) < 1 || !Number.isInteger(Number(v))) && Number(v) <= max;
};

export const ItemDetailOneStopOrder = (props: { importFav: boolean }) => {
  const dispatch = useDispatch();
  const { kijshopCd, shopOrderId, orderId } = useParams<PathParams>();
  const {
    debug,
    coverSelect,
    coverColorSelect,
    itemSelect,
    boardSelect,
    orderMethod,
    designType1,
    designType2,
    quantity: _quantity,
    itemRes,
    coverRes,
    coverColorRes,
    boardRes,
    orderMethodRes,
    designType1Res,
    designType2Res,
    order,
    data,
    currentOrder,
    xml,
    visibleCondition,
  } = useAppSelector((state) => ({
    debug: state.debug.debug,
    ...state.order.itemDetail,
    itemRes: state.orderData.itemDetail.item.products,
    coverRes: state.orderData.itemDetail.cover.coverType,
    coverColorRes: state.orderData.itemDetail.coverColor.coverColor,
    boardRes: state.orderData.itemDetail.board.boardInfo,
    orderMethodRes: state.orderData.itemDetail.orderMethods.orderMethod,
    designType1Res: state.orderData.itemDetail.designType1.hpsTppath,
    designType2Res: state.orderData.itemDetail.designType2.hpsTppath,
    order: state.order,
    data: state.orderData,
    currentOrder: state.orderPreparation.currentOrder,
    xml: state.xml[shopOrderId],
    visibleCondition: state.createOrder.visibleCondition,
  }), lodash.isEqual);
  const { importFav } = props;
  // - State -
  // -- 数量 --
  const [quantity, setQuantity] = useState(String(_quantity));
  const [quantityFocus, setQuantityFocus] = useState(false);
  // - リストデータ -
  const itemList = [...itemRes].map((v) => ({ value: v.productNameId, label: v.productName }));
  const coverList = [...coverRes].map((v) => ({ value: v.coverTypeId, label: v.coverTypeName }));
  const coverColorList = [...coverColorRes].map((v) => ({ value: v.coverColorId, label: v.coverColorName }));
  const boardList = [...boardRes].map((v) => ({ value: v.boardInfoId, label: v.boardInfoName }));
  const methodList = [...orderMethodRes].map((v) => ({ value: v.orderMethodId, label: v.orderMethodName }));
  const viewMethodList = useMemo(() => {
    return methodList.map((v) => {
      if (v.value === '10') {
        return { ...v, label: SELECT_ORDER_NAME }
      }
      return v;
    })
  }, [methodList]);
  const designType1List = [...designType1Res].map((v) => ({ value: v.hpsTppathNamePath, label: v.hpsTppathName }));
  const designType2List = [...designType2Res].map((v) => ({
    value: v.dtemplId,
    label: v.hpsTpobjName,
    dtemplId: v.dtemplId,
  }));
  if (debug) {
    createDebugList(itemList);
    createDebugList(coverList);
    createDebugList(coverColorList);
    createDebugList(designType1List);
    createDebugList(designType2List);
  }

  // - Callback -
  // -- 表紙選択 --
  const handlerChangeSelectCover = useCallback((v, label) => {
    dispatch(ordersActions.itemDetail.setCoverSelect(v, label, Boolean(xml?.info.metaModel?.dpNumber)));
  }, []);
  // -- 表紙色選択 --
  const handlerChangeSelectCoverColor = useCallback((v, label) => {
    dispatch(ordersActions.itemDetail.setCoverColorSelect(v, label, Boolean(xml?.info.metaModel?.dpNumber)));
  }, []);
  // -- 商品選択 --
  const handlerChangeSelectItem = useCallback((v, label) => {
    dispatch(ordersActions.itemDetail.setItemSelect(v, label, kijshopCd, Boolean(xml?.info.metaModel?.dpNumber), visibleCondition));
    templateCheck(v);
  }, [orderMethod]);
  // -- 部材選択 --
  const handlerChangeSelectBoard = useCallback((v, label) => {
    dispatch(ordersActions.itemDetail.setBoardSelect(v, label, Boolean(xml?.info.metaModel?.dpNumber)));
  }, []);
  // -- 注文方法 --
  const handlerChangeWayToOrder = useCallback((v, label) => {
    const setLabel = methodList.find((v2) => v2.value === v)?.label ?? label;
    dispatch(ordersActions.itemDetail.setOrderMethod(v, setLabel, Boolean(xml?.info.metaModel?.dpNumber)));
  }, [methodList]);
  // -- デザインタイプ１ --
  const handlerChangeDesignType1 = useCallback((v, label) => {
    dispatch(ordersActions.itemDetail.setDesignType1(v, label));
  }, []);
  // -- デザインタイプ２ --
  const handlerChangeDesignType2 = useCallback((v, label) => {
    dispatch(ordersActions.itemDetail.setDesignType2(v, label, Boolean(xml?.info.metaModel?.dpNumber)));
  }, [designType2Res]);
  // -- 数量 --
  const handlerChangeNum = useCallback((v, max: number) => {
    if (!String(v)) {
      setQuantity(v);
    } else if (checkQuantity(v, max)) {
      setQuantity(v);
      if (!quantityFocus) {
        dispatch(ordersActions.itemDetail.setQuantity(Number(v)));
      }
    }
  }, [order, quantityFocus]);
  const handlerBlurNum = useCallback((v: string, max: number) => {
    if (Number(v) && checkQuantity(v, max)) {
      dispatch(ordersActions.itemDetail.setQuantity(Number(v)));
      setQuantity(v);
    } else {
      dispatch(ordersActions.itemDetail.setQuantity(1));
      setQuantity('1');
    }
    setQuantityFocus(false);
  }, [quantity]);
  // -- ヘルプボタン --
  const handlerClickHelp = useCallback((type: ImgType) => {
    dispatch(dialogActions.push({
      title: '説明',
      element: <HowToOrder imgType={type} />,
      closeBtn: true,
    }));
  }, []);
  // -- 商品選択時テンプレートの有無確認
  const [notTemplate, setNotTemplate] = useState(false);
  const templateCheck = useCallback((value: string) => {

    if (value && orderMethod.value) {
      dispatch(ordersActions.genuine.setAdditionalMulti(value));
      dispatch(apiActions.AllTemplate({
          productNameId: value,
          orderMethodId: orderMethod.value,
        },
        {
          onSuccess: (res) => {
            if (res.error.errorCode === '404' || !res.body.data) {
              setNotTemplate(true);
            } else {
              setNotTemplate(false);
            }
          },
        },
      ));
    }
  }, [orderMethod]);
  // -- デザインタイプ選択時、空の場合ダイアログ表示
  const templateMessage = useCallback(() => {
    if (notTemplate) {
      dispatch(dialogActions.pushMessage({
        title: '確認',
        message: ['該当商品のテンプレートは、明日から選択できるようになります。'],
        buttons: [{
          label: 'OK',
          callback: () => dispatch(dialogActions.pop()),
        }],
      }));
    }
  }, [notTemplate]);
  // - Effect -
  // -- リスト値精査 --
  useEffect(() => {
    if (itemSelect.value && itemRes.length && !orderId && !importFav) {
      if (itemRes.find((v) => v.productNameId === itemSelect.value)) {
        dispatch(ordersActions.itemDetail.setItemSelect(itemSelect.value, itemSelect.label, kijshopCd, Boolean(xml?.info.metaModel?.dpNumber), visibleCondition));
        templateCheck(itemSelect.value);
      } else {
        dispatch(ordersActions.itemDetail.setItemSelect('', '', kijshopCd, Boolean(xml?.info.metaModel?.dpNumber), visibleCondition));
      }
    }
  }, [itemRes, orderMethod]);
  useEffect(() => {
    if (coverSelect.value && coverRes.length && !orderId && !importFav) {
      if (coverRes.find((v) => v.coverTypeId === coverSelect.value)) {
        dispatch(ordersActions.itemDetail.setCoverSelect(coverSelect.value, coverSelect.label, Boolean(xml?.info.metaModel?.dpNumber)));
      } else {
        dispatch(ordersActions.itemDetail.setCoverSelect('', '', Boolean(xml?.info.metaModel?.dpNumber)));
      }
    }
  }, [coverRes]);
  useEffect(() => {
    if (coverColorSelect.value && coverColorRes.length && !orderId && !importFav) {
      if (coverColorRes.find((v) => v.coverColorId === coverColorSelect.value)) {
        dispatch(ordersActions.itemDetail.setCoverColorSelect(coverColorSelect.value, coverColorSelect.label, Boolean(xml?.info.metaModel?.dpNumber)));
      } else {
        dispatch(ordersActions.itemDetail.setCoverColorSelect('', '', Boolean(xml?.info.metaModel?.dpNumber)));
      }
    }
  }, [coverColorRes]);
  useEffect(() => {
    if (boardSelect.value && boardRes.length && !orderId && !importFav) {
      if (boardRes.find((v) => v.boardInfoId === boardSelect.value)) {
        dispatch(ordersActions.itemDetail.setBoardSelect(boardSelect.value, boardSelect.label, Boolean(xml?.info.metaModel?.dpNumber)));
      } else {
        dispatch(ordersActions.itemDetail.setBoardSelect('', '', Boolean(xml?.info.metaModel?.dpNumber)));
      }
    }
  }, [boardRes]);
  useEffect(() => {
    if (orderMethod.value && orderMethodRes.length && !orderId && !importFav) {
      if (orderMethodRes.find((v) => v.orderMethodId === orderMethod.value)) {
        dispatch(ordersActions.itemDetail.setOrderMethod(orderMethod.value, orderMethod.label, Boolean(xml?.info.metaModel?.dpNumber)));
      } else {
        dispatch(ordersActions.itemDetail.setOrderMethod('', '', Boolean(xml?.info.metaModel?.dpNumber)));
      }
    }
  }, [orderMethodRes]);
  useEffect(() => {
    if (designType1.value && designType1Res.length && !orderId && !importFav) {
      if (designType1Res.find((v) => v.hpsTppathNamePath === designType1.value)) {
        dispatch(ordersActions.itemDetail.setDesignType1(designType1.value, designType1.label));
      } else {
        dispatch(ordersActions.itemDetail.setDesignType1(''));
      }
    }
  }, [designType1Res]);
  useEffect(() => {
    if (designType2.value && designType2Res.length && !designType2Res.find((v) => v.dtemplId === designType2.value) && !orderId && !importFav) {
      dispatch(ordersActions.itemDetail.setDesignType2('', '', Boolean(xml?.info.metaModel?.dpNumber)));
    }
  }, [designType2Res]);
  useEffect(() => {
    if (quantity !== String(_quantity)) {
      setQuantity(String(_quantity));
    }
  }, [_quantity]);
  visibleCondition.checkOrderDifference(order, data);
  const display = visibleCondition.itemDetailOrderDisplay;
  useEffect(() => {
    if (!checkQuantity(quantity, display.quantity?.max || 0)) {
      dispatch(ordersActions.itemDetail.setQuantity(1));
      setQuantity('1');
    }
  }, [order.itemInfo.category.value, order.itemDetail.itemSelect.value]);
  useEffect(() => {
    const cover = coverList[0];
    if (!coverSelect?.value || cover && coverSelect?.value !== cover.value) {
      if (coverList.length === 1 && display.cover) {
        dispatch(ordersActions.itemDetail.setCoverSelect(cover.value, cover.label, Boolean(xml?.info.metaModel?.dpNumber)));
      }
    }
  }, [coverList]);
  useEffect(() => {
    const coverColor = coverColorList[0];
    if (!coverColorSelect?.value || coverColor && coverColorSelect?.value !== coverColor.value) {
      if (coverColorList.length === 1 && display.coverColor) {
        dispatch(ordersActions.itemDetail.setCoverColorSelect(coverColor.value, coverColor.label, Boolean(xml?.info.metaModel?.dpNumber)));
      }
    }
  }, [coverColorList]);
  useEffect(() => {
    const item = itemList[0];
    if (!itemSelect?.value || item && itemSelect?.value !== item.value) {
      if (itemList.length === 1 && display.item) {
        dispatch(ordersActions.itemDetail.setItemSelect(item.value, item.label, kijshopCd, Boolean(xml?.info.metaModel?.dpNumber), visibleCondition));
        templateCheck(item.value);
      }
    }
  }, [itemList, orderMethod]);
  useEffect(() => {
    const board = boardList[0];
    if (!boardSelect?.value || board && boardSelect?.value !== board.value) {
      if (boardList.length === 1 && display.board) {
        dispatch(ordersActions.itemDetail.setBoardSelect(board.value, board.label, Boolean(xml?.info.metaModel?.dpNumber)));
      }
    }
  }, [boardList]);
  useEffect(() => {
    const method = methodList[0];
    if (!orderMethod?.value || method && orderMethod?.value !== method.value) {
      if (methodList.length === 1) {
        dispatch(ordersActions.itemDetail.setOrderMethod(method.value, method.label, Boolean(xml?.info.metaModel?.dpNumber)));
      }
    }
  }, [methodList]);
  useEffect(() => {
    const type1 = designType1List[0];
    if (!designType1?.value || type1 && designType1?.value !== type1.value) {
      if (designType1List.length === 1 && display.designType1) {
        dispatch(ordersActions.itemDetail.setDesignType1(type1.value, type1.label));
      }
    }
  }, [designType1List]);

  useEffect(() => {
    const type2 = designType2List[0];
    if (!designType2?.value || type2 && designType2?.value !== type2.value) {
      if (designType2List.length === 1 && display.designType2) {
        dispatch(ordersActions.itemDetail.setDesignType2(type2.value, type2.label, Boolean(xml?.info.metaModel?.dpNumber)));
      }
    }
  }, [designType2List]);
  // お気に入り商品読み込み時の削除商品対応
  useEffect(() => {
    if (importFav && itemSelect.value && itemList.length && !itemRes.find((v) => v.productNameId === itemSelect.value)) {
      dispatch(ordersActions.itemDetail.setItemSelect(itemRes[0].productNameId, itemRes[0].productName, kijshopCd, Boolean(xml?.info.metaModel?.dpNumber), visibleCondition));
      // templateCheck(itemRes[0].productNameId);
    }
  }, [importFav, itemSelect.value]);
  /* 部材名称をオプションに引き継ぐため value がある時は label を設定する */
  useEffect(() => {
    if (boardSelect.value && !boardSelect.label && boardRes.length) {
      const data = boardRes.find((v) => v.boardInfoId === boardSelect.value);
      if (data) {
        dispatch(ordersActions.itemDetail.setBoardSelect(data.boardInfoId, data.boardInfoName, Boolean(xml?.info.metaModel?.dpNumber)));
      }
    }
  }, [boardSelect, boardRes]);
  // - 表示条件 -
  // const display = displayItems(order, data);
  // const display = visibleCondition.getItemDetailDisplay(order, data);
  if (debug) {
    // console.group('商品詳細');
    LogDecorator.group(`<magenta>商品詳細</magenta>`);
    console.group('表紙選択');
    console.log('表示: ', display.cover);
    console.log('データ', coverRes);
    console.groupEnd();
    console.group('表紙色選択');
    console.log('表示: ', display.coverColor);
    console.log('データ', coverColorRes);
    console.groupEnd();
    console.group('商品選択');
    console.log('表示: ', display.item);
    console.log('データ', itemRes);
    console.groupEnd();
    console.group('部材選択');
    console.log('表示: ', display.board);
    console.log('データ', boardRes);
    console.groupEnd();
    console.group('注文方法');
    console.log('表示: ', display.orderMethod);
    console.log('データ', orderMethodRes);
    console.groupEnd();
    console.group('デザインタイプ1');
    console.log('表示: ', display.designType1);
    console.log('データ', designType1Res);
    console.groupEnd();
    console.group('デザインタイプ2');
    console.log('表示: ', display.designType2);
    console.log('データ', designType2Res);
    console.groupEnd();
    switch (order.itemInfo.category.value) {
      case 'jp0310':
      case 'jp0376':
      case 'jp0411':
      case 'jp0425':
      case 'jp0426':
      case 'jp0427':
        console.group('数量');
        console.log('~99');
        console.groupEnd();
        break;
      default:
    }
    switch (order.itemDetail.itemSelect.value) {
      case 'jptg301625':
        console.group('数量');
        console.log('~99');
        console.groupEnd();
        break;
      case 'jptg301626':
      case 'jptg301627':
        console.group('数量');
        console.log('~9');
        console.groupEnd();
        break;
      default:
        console.group('数量');
        console.log('~9999');
        console.groupEnd();
    }
    console.groupEnd();
  }
  return (
    <div className="order_category">
      <div className="bottom_border_header order_category__header">
        商品詳細
        <Button
          className="order_help order_category__header__tooltip"
          icon={<img src={iconHelp} alt='' />}
          onClick={() => handlerClickHelp('title-item')}
        />
      </div>

      {display.cover ? (
        <div className="top_label_ui order_category__item">
          <div className="top_label_ui__label">
            表紙選択
          </div>
          <div className="top_label_ui__form">
            <Select
              value={coverSelect.value}
              list={coverList}
              onChange={(e, label) => handlerChangeSelectCover(e.target.value, label)}
              disabled={Boolean(currentOrder) || Boolean(orderId) || importFav}
            />
            <Button
              className="order_help"
              icon={<img src={iconHelp} alt='' />}
              onClick={() => handlerClickHelp('item-cover')}
            />
          </div>
        </div>
      ) : (<></>)}

      {display.coverColor ? (
        <div className="top_label_ui order_category__item">
          <div className="top_label_ui__label">
            表紙色選択
          </div>
          <div className="top_label_ui__form">
            <Select
              value={coverColorSelect.value}
              list={coverColorList}
              onChange={(e, label) => handlerChangeSelectCoverColor(e.target.value, label)}
              disabled={Boolean(currentOrder) || Boolean(orderId) || importFav}
            />
            <Button
              className="order_help"
              icon={<img src={iconHelp} alt='' />}
              onClick={() => handlerClickHelp('item-coverColor')}
            />
          </div>
        </div>
      ) : (<></>)}

      {display.item ? (
        <div className="top_label_ui order_category__item">
          <div className="top_label_ui__label">
            商品選択
          </div>
          <div className="top_label_ui__form">
            <Select
              key={`item-select-${itemSelect.value ? 'data' : 'empty'}`}
              value={itemSelect.value}
              list={itemList}
              onChange={(e, label) => handlerChangeSelectItem(e.target.value, label)}
              disabled={
                Boolean(currentOrder)
                || Boolean(orderId)
                || Boolean(!itemRes.length)
                || Boolean(display.cover && !order.itemDetail.coverSelect.value && data.itemDetail.cover.coverType.length)
                || Boolean(display.coverColor && !order.itemDetail.coverColorSelect.value && data.itemDetail.coverColor.coverColor.length)
                || importFav
              }
            />
            <Button
              className="order_help"
              icon={<img src={iconHelp} alt='' />}
              onClick={() => handlerClickHelp('item-item')}
            />
          </div>
        </div>
      ) : (<></>)}

      {display.board ? (
        <div className="top_label_ui order_category__item">
          <div className="top_label_ui__label">
            部材選択
          </div>
          <div className="top_label_ui__form">
            <Select
              value={boardSelect.value}
              list={boardList}
              onChange={(e, label) => handlerChangeSelectBoard(e.target.value, label)}
              disabled={Boolean(currentOrder) || Boolean(orderId) || importFav}
            />
            <Button
              className="order_help"
              icon={<img src={iconHelp} alt='' />}
              onClick={() => handlerClickHelp('item-board')}
            />
          </div>
        </div>
      ) : (<></>)}

      {display.orderMethod ? (
        <div className="top_label_ui order_category__item">
          <div className="top_label_ui__label">
            注文方法
          </div>
          <div className="top_label_ui__form">
            <Select
              value={orderMethod.value}
              list={viewMethodList}
              onChange={(e, label) => handlerChangeWayToOrder(e.target.value, label)}
              disabled={Boolean(currentOrder) || Boolean(orderId) || importFav}
            />
            <Button
              className="order_help"
              icon={<img src={iconHelp} alt='' />}
              onClick={() => handlerClickHelp('item-order')}
            />
          </div>
        </div>
      ) : (<></>)}

      {display.designType1 ? (
        <div className="top_label_ui order_category__item">
          <div className="top_label_ui__label">
            デザインタイプ1
          </div>
          <div className="top_label_ui__form">
            <Select
              value={designType1.value}
              list={designType1List}
              onClick={templateMessage}
              onChange={(e, label) => handlerChangeDesignType1(e.target.value, label)}
            />
            <Button
              className="order_help"
              icon={<img src={iconHelp} alt='' />}
              onClick={() => handlerClickHelp('item-design1')}
            />
          </div>
        </div>
      ) : (<></>)}

      {display.designType2 ? (
        <div className="top_label_ui order_category__item">
          <div className="top_label_ui__label">
            デザインタイプ2
          </div>
          <div className="top_label_ui__form">
            <Select
              value={designType2.value}
              list={designType2List}
              onClick={templateMessage}
              onChange={(e, label) => handlerChangeDesignType2(e.target.value, label)}
            />
            <Button
              className="order_help"
              icon={<img src={iconHelp} alt='' />}
              onClick={() => handlerClickHelp('item-design2')}
            />
          </div>
        </div>
      ) : (<></>)}

      {display.quantity?.max ? (
        <div className="top_label_ui order_category__item item_amount">
          <div className="top_label_ui__label">
            数量
            <span className="item_amount__alert">※注文する数を入力ください。ページ数の入力欄ではありません。</span>
          </div>
          <div className="top_label_ui__form">
            <NumUpDown
              value={quantity}
              min={1}
              // onChange={(e) => handlerChangeNum(e.target.value)}
              callback={(num) => handlerChangeNum(String(num), display.quantity?.max || 0)}
              onBlur={(e) => handlerBlurNum(e.target.value, display.quantity?.max || 0)}
              onFocus={() => setQuantityFocus(true)}
            />
            <Button
              className="order_help"
              icon={<img src={iconHelp} alt='' />}
              onClick={() => handlerClickHelp('item-quantity')}
            />
          </div>
        </div>
      ) : (<></>)}

    </div>
  );
};

import * as lodash from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../app/hooks';
import { PathParams } from '../../../routes/routing-path';
import { ordersActions, OrdersSliceState } from '../../../slices/orders-slice';
import { OrdersDataState } from '../../../slices/orders/orders-data-slice';
import { Checkbox } from '../../ui/input/checkbox';
import { NumUpDown } from '../../ui/input/num-up-down';
import { Select } from '../../ui/select/select';
import { LogDecorator } from '@tenryu/log-decorator';
import { Button } from '../../ui/button/button';
import iconHelp from '../../../assets/img/icon/help_icon.svg';
import { HowToOrder, ImgType } from '../../dialog/unique/how-to-order';
import { dialogActions } from '../../dialog/slice/dialog-slice';
import { createOrderActions } from '../../../slices/create-order-slice';
import { Input } from '../../ui/input/input';
import { OneStopLeafTextValidation } from './cover-order.one-stop';
import { checkOptional } from '../../../models/create-order/item-detail-item-data';

export const GenuineOrder = () => {
  const { kijshopCd, shopOrderId, orderId } = useParams<PathParams>();
  const dispatch = useDispatch();
  const {
    laminate,
    genuineProcessing,
    foilStampingRes,
    leafText1,
    leafText2,
    additional,
    chronologies,
    additionalMulti,
    page: _page,
    order,
    data,
    laminateRes,
    additionalRes,
    chronologiesRes,
    visibleCondition,
    debug,
    xml,
  } = useAppSelector((state) => ({
    ...state.order.genuine,
    laminateRes: state.orderData.genuine.laminate.laminate,
    foilStampingRes: state.orderData.genuine.foilStamping.leafColor,
    additionalRes: state.orderData.genuine.endpaper.additionalInfos,
    chronologiesRes: state.orderData.genuine.chronologies.chronologies,
    order: state.order,
    data: state.orderData,
    visibleCondition: state.createOrder.visibleCondition,
    debug: state.debug.debug,
    xml: state.xml[shopOrderId],
  }), lodash.isEqual);
  // - State -
  const [page, setPage] = useState(String(''));
  const [alertLeafText1, setAlertLeafText1] = useState(false);
  const [alertLeafText2, setAlertLeafText2] = useState(false);
  // - Ref -
  const refLeaf1 = useRef<HTMLInputElement>(null);
  const refLeaf2 = useRef<HTMLInputElement>(null);
  // - Callback -
  // -- ラミ選択 --
  const handlerChangeSelectRami = useCallback((v, label) => {
    dispatch(ordersActions.genuine.setLaminate(v, label));
  }, []);
  // -- 表紙加工 --
  const handlerChangeGenineRetouch = useCallback((v, label) => {
    dispatch(ordersActions.genuine.setGenineProcessing(v, label));
  }, []);
  // -- 箔押し文字1 --
  const handlerChangeLeafText1 = useCallback((v) => {
    dispatch(ordersActions.genuine.setLeafText1(v));
  }, []);
  // -- 箔押し文字2 --
  const handlerChangeLeafText2 = useCallback((v) => {
    dispatch(ordersActions.genuine.setLeafText2(v));
  }, []);
  // -- 見出し選択 --
  const handlerChangeSelectTitle = useCallback((v, label) => {
    dispatch(ordersActions.genuine.setAdditional(v, label));
  }, []);
  // -- 付加情報 --
  const handlerChangeAdditionalMulti = useCallback((v) => {
    // dispatch(ordersActions.genuine.setAdditionalMulti(additionalMulti === 'true' ? 'false' : 'true'));
    dispatch(ordersActions.genuine.setAdditionalMulti(additionalMulti === v ? '' : v));
  }, [additionalMulti]);
  // -- 年表選択 --
  const handlerChangeChronology = useCallback((v, label) => {
    dispatch(ordersActions.genuine.setChronology(v, label));
  }, []);
  // -- ページ数 --
  const btnHandlerChangePage = useCallback((_v: string, press: boolean) => {
    if (press) return;
    const up = Number(_v) > Number(page);
    const { maxPageNum, minPageNum, stepPageNum } = data.data.pageCount;
    if (maxPageNum && minPageNum && stepPageNum) {
      const max = Number(maxPageNum);
      const min = Number(minPageNum);
      if (up) {
        const p = Number(_v);
        if (p <= max) {
          setPage(String(p || ''));
          dispatch(ordersActions.genuine.setPage(p, Boolean(xml?.info.metaModel?.dpNumber)));
        }
      } else {
        const p = Number(_v);
        if (p >= min) {
          setPage(String(p || ''));
          dispatch(ordersActions.genuine.setPage(p, Boolean(xml?.info.metaModel?.dpNumber)));
        }
      }
    }
  }, [page]);
  const handlerChangePage = useCallback((_v: string) => {
    if (isNaN(Number(_v))) {
      if (_v === '') return;
      setPage(String(_page || ''));
      return;
    }
    if (Number(_v) < 0) {
      setPage(String(_page || ''));
      return;
    }
    setPage(_v || '');
  }, [page]);
  const handlerFocusPageCount = useCallback(() => {
    dispatch(createOrderActions.setIsFocusPageCount(true));
  }, []);
  const handlerBlurPage = useCallback((v: string) => {
    dispatch(createOrderActions.setIsFocusPageCount(false));
    if (isNaN(Number(v))) {
      if (v === '') {
        dispatch(ordersActions.genuine.setPage(_page, Boolean(xml?.info.metaModel?.dpNumber)));
      }
      setPage(String(_page || ''));
      return;
    }
    if (Number(v) < 0) {
      setPage(String(_page || ''));
      return;
    }
    let p = Number(v);
    const { maxPageNum, minPageNum, stepPageNum } = data.data.pageCount;
    if (maxPageNum && minPageNum && stepPageNum) {
      const max = Number(maxPageNum);
      const min = Number(minPageNum);
      const step = Number(stepPageNum);
      if (max < p) {
        p = max;
      } else if (min > p) {
        p = min;
      } else if (p % step !== 0) {
        p = p - (p % step);
      }
      setPage((prev) => {
        if (prev !== String(p) || _page !== p) {
          dispatch(ordersActions.genuine.setPage(p, Boolean(xml?.info.metaModel?.dpNumber)));
        }
        return String(p || '');
      });
    } else if (maxPageNum && minPageNum) {
      // dispatch(ordersActions.genuine.setPage(_page, Boolean(xml?.info.metaModel?.dpNumber)));
    }
  }, [page, _page]);
  // -- ヘルプボタン --
  const handlerClickHelp = useCallback((type: ImgType) => {
    dispatch(dialogActions.push({
      title: '説明',
      element: <HowToOrder imgType={type} />,
      closeBtn: true,
    }));
  }, []);
  // -- 箔押し文字精査 --
  const handlerBlurLeafText1 = useCallback(() => {
    const validation = OneStopLeafTextValidation(leafText1);
    if (!validation.length) {
      dispatch(dialogActions.pushMessage({
        title: '確認',
        message: ['箔押し文字の文字数は1行最大24文字です。'],
        buttons: [{
          label: 'OK',
          callback: () => {
            dispatch(dialogActions.pop());
            setTimeout(() => {
              refLeaf1?.current?.focus();
            });
          },
        }],
      }));
      setAlertLeafText1(true);
    } else if (validation.mismatch) {
      dispatch(dialogActions.pushMessage({
        title: '確認',
        message: [`一行目に使用不可能な文字『${validation.mismatch}』が存在します。`],
        buttons: [{
          label: 'OK',
          callback: () => {
            dispatch(dialogActions.pop());
            setTimeout(() => {
              refLeaf1?.current?.focus();
            });
          },
        }],
      }));
      setAlertLeafText1(true);
    } else {
      setAlertLeafText1(false);
    }
  }, [leafText1]);
  const handlerBlurLeafText2 = useCallback(() => {
    const validation = OneStopLeafTextValidation(leafText2);
    if (!validation.length) {
      dispatch(dialogActions.pushMessage({
        title: '確認',
        message: ['箔押し文字の文字数は1行最大24文字です。'],
        buttons: [{
          label: 'OK',
          callback: () => {
            dispatch(dialogActions.pop());
            setTimeout(() => {
              refLeaf2?.current?.focus();
            });
          },
        }],
      }));
      setAlertLeafText2(true);
    } else if (validation.mismatch) {
      dispatch(dialogActions.pushMessage({
        title: '確認',
        message: [`二行目に使用不可能な文字『${validation.mismatch}』が存在します。`],
        buttons: [{
          label: 'OK',
          callback: () => {
            dispatch(dialogActions.pop());
            setTimeout(() => {
              refLeaf2?.current?.focus();
            });
          },
        }],
      }));
      setAlertLeafText2(true);
    } else {
      setAlertLeafText2(false);
    }
  }, [leafText2]);
  // - Effect -
  // -- リスト値精査 --
  useEffect(() => {
    if (laminate.value && laminateRes.length && !laminateRes.find((v) => v.laminateId === laminate.value) && !orderId) {
      dispatch(ordersActions.genuine.setLaminate(''));
    }
  }, [laminateRes]);
  useEffect(() => {
    if (additional.value && additionalRes.length && !additionalRes.find((v) => v.additionalInfoId === additional.value) && !orderId) {
      dispatch(ordersActions.genuine.setAdditional(''));
    }
  }, [additionalRes]);
  useEffect(() => {
    if (!Number(page) && Number(data.data.pageCount.minPageNum) && !orderId) {
      dispatch(ordersActions.genuine.setPage(Number(data.data.pageCount.minPageNum), Boolean(xml?.info.metaModel?.dpNumber)));
    }
  }, [data]);
  useEffect(() => {
    if (page !== String(_page)) {
      setPage(String(_page || ''));
      dispatch(ordersActions.genuine.setPage(_page, Boolean(xml?.info.metaModel?.dpNumber)));
    }
  }, [_page]);
  // useEffect(() => {
  //   const { minPageNum, maxPageNum } = data.data.pageCount;
  //   if (Number(minPageNum) && Number(maxPageNum)) {
  //     if (Number(page) < Number(minPageNum) || Number(page) > Number(maxPageNum)) {
  //       dispatch(ordersActions.genuine.setPage(Number(minPageNum)));
  //     }
  //   }
  // }, [data.data.pageCount]);
  // - 表示内容 -
  // const display = displayItem(order, data);
  // const display = visibleCondition.getGenuineDisplay(order, data);
  visibleCondition.checkOrderDifference(order, data);
  const display = visibleCondition.genuineOrderDisplay;
  useEffect(() => {
    if (!display.page && page) {
      dispatch(ordersActions.genuine.setPage(0, Boolean(xml?.info.metaModel?.dpNumber)));
    }
  }, [display.page]);
  // - リストデータ -
  const laminateList = [...laminateRes].map((v) => ({ value: v.laminateId, label: v.laminateName }));
  const additionalList = [...additionalRes].map((v) => ({ value: v.additionalInfoId, label: v.additionalInfoName }));
  const chronologyList = [...chronologiesRes].map((v) => ({ value: v.chronologyId, label: v.chronologyName }));
  const foilList = [...foilStampingRes].map((v) => ({ value: v.leafColorId, label: v.leafColorName }));

  const select = useAppSelector((state) => {
    return state.order.genuine;
  }, lodash.isEqual);

  useEffect(() => {
      const laminate = laminateList[0];
      if (!select || laminate && select.laminate.value !== laminate.value) {
        if (laminateList.length === 1) {
          dispatch(ordersActions.genuine.setLaminate(laminate.value, laminate.label));
        }
      }
    },
    [laminateList],
  );
  useEffect(() => {
      const additional = additionalList[0];
      if (!select || additional && select.additional.value !== additional.value) {
        if (additionalList.length === 1) {
          dispatch(ordersActions.genuine.setAdditional(additional.value, additional.label));
        }
      }
    },
    [additionalList],
  );
  // - 箔押し文字バリデーション -
  const leafText1Validation = OneStopLeafTextValidation(leafText1);
  const leafText1ErrorMessage: string[] = leafText1Validation.length && !leafText1Validation.mismatch
    ? []
    : (
      !leafText1Validation.length ? [
        '箔押しの文字数は一行最大24文字です。',
      ] : [
        `一行目に使用不可能な文字『${leafText1Validation.mismatch}』が存在します。`,
      ]
    );
  const leafText2Validation = OneStopLeafTextValidation(leafText2);
  const leafText2ErrorMessage: string[] = leafText2Validation.length && !leafText2Validation.mismatch
    ? []
    : (
      !leafText2Validation.length ? [
        '箔押しの文字数は一行最大24文字です。',
      ] : [
        `二行目に使用不可能な文字『${leafText2Validation.mismatch}』が存在します。`,
      ]
    );
  if (debug) {
    // console.group('本身');
    LogDecorator.group(`<magenta>本身</magenta>`);
    console.group('ラミ選択');
    console.log('表示: ', display.laminate);
    console.log('データ: ', laminateRes);
    console.groupEnd();
    console.group('見返し選択');
    console.log('表示: ', display.additional);
    console.log('データ: ', additionalRes);
    console.groupEnd();
    console.group('台紙貼り(見返し選択と同データ)');
    console.log('表示: ', display.additional2);
    console.groupEnd();
    console.group('テープ選択(見返し選択と同データ)');
    console.log('表示: ', display.additional3);
    console.groupEnd();
    console.group('付加情報');
    console.log('表示: ', display.additionalMulti);
    console.groupEnd();
    console.group('年表選択');
    console.log('表示: ', display.chronology);
    console.log('データ: ', chronologiesRes);
    console.groupEnd();
    console.group('商品種');
    console.log('商品種: ', data.data.productDetail.productTypeId);
    console.log('データ: ', data.data.productDetail);
    console.groupEnd();
    console.groupEnd();
  }
  return (
    <div className="order_category">
      <div className="bottom_border_header order_category__header">
        本身
        <Button
          className="order_help order_category__header__tooltip"
          icon={<img src={iconHelp} alt="" />}
          onClick={() => handlerClickHelp('title-genuine')}
        />
      </div>

      {display.laminate ? (
        <div className="top_label_ui order_category__item">
          <div className="top_label_ui__label">
            ラミ選択
          </div>
          <div className="top_label_ui__form">
            <Select
              value={laminate.value}
              list={laminateList}
              onChange={(e, label) => handlerChangeSelectRami(e.target.value, label)}
            />
            <Button
              className="order_help"
              icon={<img src={iconHelp} alt="" />}
              onClick={() => handlerClickHelp('genuine-laminate')}
            />
          </div>
        </div>
      ) : (<></>)}

      {display.leafColor ? (
        <div className="top_label_ui order_category__item">
          <div className="top_label_ui__label">
            加工情報
          </div>
          <div className="top_label_ui__form">
            <Select
              value={genuineProcessing.value}
              list={foilList}
              onChange={(e, label) => handlerChangeGenineRetouch(e.target.value, label)}
              // addEmpty={isOptionalLeafColor(order.itemDetail.coverSelect.value)}
              addEmpty={checkOptional(display.leafColor)}
            />
            <Button
              className="order_help"
              icon={<img src={iconHelp} alt="" />}
              onClick={() => handlerClickHelp('cover-process')}
            />
          </div>
        </div>
      ) : (<></>)}

      {display.leafText1 ? (
        <div className="top_label_ui order_category__item">
          <div className="top_label_ui__label">
            箔押し文字1
          </div>
          <div className="top_label_ui__form">
            <Input
              value={leafText1}
              onChange={(e) => handlerChangeLeafText1(e.target.value)}
              error={Boolean(leafText1ErrorMessage.length) && alertLeafText1}
              tooltip={(leafText1ErrorMessage.length && alertLeafText1) ? {
                messages: leafText1ErrorMessage,
              } : undefined}
              onBlur={handlerBlurLeafText1}
              ref={refLeaf1}
            />
            <Button
              className="order_help"
              icon={<img src={iconHelp} alt="" />}
              onClick={() => handlerClickHelp('cover-leafText1')}
            />
          </div>
        </div>
      ) : (<></>)}

      {display.leafText2 ? (
        <div className="top_label_ui order_category__item">
          <div className="top_label_ui__label">
            箔押し文字2
          </div>
          <div className="top_label_ui__form">
            <Input
              value={leafText2}
              onChange={(e) => handlerChangeLeafText2(e.target.value)}
              error={Boolean(leafText2ErrorMessage.length) && alertLeafText2}
              tooltip={(leafText2ErrorMessage.length && alertLeafText2) ? {
                messages: leafText2ErrorMessage,
              } : undefined}
              onBlur={handlerBlurLeafText2}
              ref={refLeaf2}
            />
            <Button
              className="order_help"
              icon={<img src={iconHelp} alt="" />}
              onClick={() => handlerClickHelp('cover-leafText2')}
            />
          </div>
        </div>
      ) : (<></>)}

      {display.additional ? (
        <div className="top_label_ui order_category__item">
          <div className="top_label_ui__label">
            見返し選択
          </div>
          <div className="top_label_ui__form">
            <Select
              value={additional.value}
              list={additionalList}
              onChange={(e, label) => handlerChangeSelectTitle(e.target.value, label)}
            />
            <Button
              className="order_help"
              icon={<img src={iconHelp} alt="" />}
              onClick={() => handlerClickHelp('genuine-additional')}
            />
          </div>
        </div>
      ) : (<></>)}

      {display.additional2 ? (
        <div className="top_label_ui order_category__item">
          <div className="top_label_ui__label">
            台紙貼り
          </div>
          <div className="top_label_ui__form">
            <Select
              value={additional.value}
              list={additionalList}
              onChange={(e, label) => handlerChangeSelectTitle(e.target.value, label)}
            />
            <Button
              className="order_help"
              icon={<img src={iconHelp} alt="" />}
              onClick={() => handlerClickHelp('genuine-additional2')}
            />
          </div>
        </div>
      ) : (<></>)}

      {display.additional3 ? (
        <div className="top_label_ui order_category__item">
          <div className="top_label_ui__label">
            テープ選択
          </div>
          <div className="top_label_ui__form">
            <Select
              value={additional.value}
              list={additionalList}
              onChange={(e, label) => handlerChangeSelectTitle(e.target.value, label)}
            />
            <Button
              className="order_help"
              icon={<img src={iconHelp} alt="" />}
              onClick={() => handlerClickHelp('genuine-tape')}
            />
          </div>
        </div>
      ) : (<></>)}

      {display.additionalMulti ? (
        <div className="top_label_ui order_category__item">
          <div className="top_label_ui__label">
            付加情報
          </div>
          {additionalList.map((v, i) => (
            <div
              key={`additional-multi-check_${i}`}
              className="top_label_ui__form"
            >
              <Checkbox
                checked={additionalMulti === v.value}
                onClick={() => handlerChangeAdditionalMulti(v.value)}
                styleType="1"
                label={v.label}
              />
            </div>
          ))}
        </div>

      ) : (<></>)}

      {display.chronology ? (
        <div className="top_label_ui order_category__item">
          <div className="top_label_ui__label">
            年表選択
          </div>
          <div className="top_label_ui__form">
            <Select
              value={chronologies.value}
              list={chronologyList}
              onChange={(e, label) => handlerChangeChronology(e.target.value, label)}
              addEmpty={true}
            />
            <Button
              className="order_help"
              icon={<img src={iconHelp} alt="" />}
              onClick={() => handlerClickHelp('genuine-chronology')}
            />
          </div>
        </div>
      ) : (<></>)}

      {display.page ? (
        <div className="top_label_ui order_category__item">
          <div className="top_label_ui__label">
            ページ数
            {debug ? <>（{data.data.pageCount.minPageNum} ~ {data.data.pageCount.stepPageNum || 0} ~ {data.data.pageCount.maxPageNum}）</> : <></>}
          </div>
          <div className="top_label_ui__form">
            <NumUpDown
              value={page}
              max={data.data.pageCount.maxPageNum}
              min={data.data.pageCount.minPageNum}
              step={data.data.pageCount.stepPageNum}
              callback={(num, press) => btnHandlerChangePage(String(num), press!)}
              onBlur={(e) => handlerBlurPage(e.target.value)}
              onFocus={handlerFocusPageCount}
              disabled={Boolean(orderId)}
            />

            <Button
              className="order_help"
              icon={<img src={iconHelp} alt="" />}
              onClick={() => handlerClickHelp('genuine-page')}
            />
          </div>
        </div>
      ) : (<></>)}
    </div>
  );
};

import { XmlClass } from './xml-class';
import { IInfo } from '../interface/i-info';
import { XmlClassName } from '../model/xml-class-name';
import { TInfoMetaModel, TInfoViewModel } from '../model/info/xml-info-model';

type TView = Partial<TInfoViewModel>;
type TMeta = Partial<TInfoMetaModel>;

export class InfoXml extends XmlClass<IInfo, TView, TMeta> {

  name: XmlClassName = 'order';

  viewModel: TView = {};
  metaModel: TMeta = {};

  protected init(): void {
    this.xmlUniqueName = this.xmlUniqueName || `PPM_${this.shopOrderId}_11_11.xml`;
    this.xmlParentName = this.xmlParentName || '';
    this.docking({}, {});
  }

  protected docking(v: TView, m: TMeta): void {
    this.xmlModel = {
      lnwOrder: {
        $: {
          ...this.rootTagModel,
        },
        order: [
          {
            $: {
              // 注文作成日
              createDate: m.createDate,
              // 納品日時(引き渡しまでは行わないので基本は空固定のはず)
              deliveredDate: m.deliveredDate,
              // DP袋番号(代理発注番号)
              dpNumber: m.dpNumber,
              // カートモード(true固定)
              isCartOrder: 'true',
              // 変更区分(1固定)
              modifyType: '1',
              // 発注日時
              orderDate: m.orderDate,
              // GUID
              orderGUID: m.orderGUID,
              // 注文番号
              orderID: m.orderID,
              // 発注方法
              orderMethod: v.orderMethod,
              // 発注区分(1固定)
              orderType: '1',
              // シリアルID
              receptionSerialID: m.receptionSerialID,
            },
            customer: m.path?.customer ? [
              {
                $: {
                  path: m.path?.customer,
                  // path: `PPM_${this.shopOrderId}_11_11_customer.xml`,
                },
              },
            ] : undefined,
            status: m.path?.status ? [
              {
                $: {
                  path: m.path?.status,
                  // path: `PPM_${this.shopOrderId}_11_11_status.xml`,
                },
              },
            ] : undefined,
            shop: m.path?.shop ? [
              {
                $: {
                  path: m.path?.shop,
                  // path: `PPM_${this.shopOrderId}_11_11_shop.xml`,
                },
              },
            ] : undefined,
            delivery: m.path?.delivery ? [
              {
                $: {
                  path: m.path?.delivery,
                  // path: `PPM_${this.shopOrderId}_11_11_delivery.xml`,
                },
              },
            ] : undefined,
            summary: m.path?.summary ? [
              {
                $: {
                  path: m.path?.summary,
                  // path: `PPM_${this.shopOrderId}_11_11_summary.xml`,
                },
              },
            ] : undefined,
            orderInfo: m.path?.orderInfo ? [
              {
                $: {
                  path: m.path?.orderInfo,
                  // path: `PPM_${this.shopOrderId}_11_11_orderInfo.xml`,
                },
              },
            ] : undefined,
            orderSelect: m.path?.orderSelect ? [
              {
                $: {
                  path: m.path?.orderSelect,
                  // path: `PPM_${this.shopOrderId}_11_11_orderSelect.xml`,
                },
              },
            ] : undefined,
            primarySelect: m.path?.primarySelect ? [
              {
                $: {
                  path: m.path?.primarySelect,
                  // path: `PPM_${this.shopOrderId}_11_11_orderSelect.xml`,
                },
              },
            ] : undefined,
          },
        ],
      },
    };
  }

  protected split(x: IInfo): void {
    if (!x?.lnwOrder?.order || !x.lnwOrder.$) {
      this.viewModel = {};
      this.metaModel = {};
      return;
    }
    this.viewModel = {
      orderMethod: x.lnwOrder.order?.[0].$.orderMethod,
    };
    this.metaModel = {
      createDate: x.lnwOrder.order?.[0].$.createDate,
      deliveredDate: x.lnwOrder.order?.[0].$.deliveredDate,
      dpNumber: x.lnwOrder.order?.[0].$.dpNumber,
      orderDate: x.lnwOrder.order?.[0].$.orderDate,
      orderGUID: x.lnwOrder.order?.[0].$.orderGUID,
      orderID: x.lnwOrder.order?.[0].$.orderID,
      receptionSerialID: x.lnwOrder.order?.[0].$.receptionSerialID,
      path: {
        customer: x.lnwOrder.order?.[0].customer?.[0].$.path,
        status: x.lnwOrder.order?.[0].status?.[0].$.path,
        shop: x.lnwOrder.order?.[0].shop?.[0].$.path,
        delivery: x.lnwOrder.order?.[0].delivery?.[0].$.path,
        summary: x.lnwOrder.order?.[0].summary?.[0].$.path,
        orderInfo: x.lnwOrder.order?.[0].orderInfo?.[0].$.path,
        orderSelect: x.lnwOrder.order?.[0].orderSelect?.[0].$.path,
        primarySelect: x.lnwOrder.order?.[0].primarySelect?.[0].$.path,
      },
    };
    this.rootTagModel = Object.assign(this.rootTagModel, x.lnwOrder.$);
  }
}

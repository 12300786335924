import { ApiBaseFront } from '../api-base-front';

type ParamType = {
  kijshopCd: string;
  orderId: string,
  name: string,
}

export class ApiUpdateCloudFolderName extends ApiBaseFront {
  constructor(_param: ParamType) {
    const { kijshopCd, orderId, ...param } = _param;
    super(
      'POST',
      `/api/v1/c-folder/shop/${kijshopCd}/order/${orderId}/name`,
      'JSON',
      param,
    );
  }
}

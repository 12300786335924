import { useEffect, useRef, useState } from "react";
import { CloudFolderFileInfo } from "../../../../api/front/cloud-folder/api-get-cloud-folder-order-file";
import { useAppDispatch } from "../../../../app/hooks";
import { apiActions } from "../../../../slices/api-slice";
import { ApiGetCloudFolderFileDownload } from "../../../../api/cf/cloud-folder/api-get-cloud-folder-file-download";
import React from "react";
import { isEqual } from "lodash";
import './one-stop-image.scss';
import '../../../ui/loading/loading-popup.scss';

type Props = {
  imgData: CloudFolderFileInfo,
  kijshopCd: string,
  orderId: string,
}

export const OneStopImage = React.memo((props: Props) => {
  const {
    imgData,
    kijshopCd,
    orderId,
  } = props;
  const dispatch = useAppDispatch();
  const [src, setSrc] = useState('');
  const init = useRef<boolean>(false);
  const cnt = useRef<HTMLDivElement>(null);
  
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((v) => {
        if (v.isIntersecting && !init.current) {
          init.current = true;
          dispatch(apiActions.run(new ApiGetCloudFolderFileDownload({
            kijshopCd,
            orderId,
            path: `${imgData.pathThumb}/${imgData.orgFilename}`,
          }), {
            onSuccess: (res: Blob) => {
              const url = URL.createObjectURL(res);
              setSrc(url);
            },
          }));
        }
      });
    });
    if (cnt.current) {
      observer.observe(cnt.current);
    }

    return () => {
      observer.disconnect();
      init.current = false;
      setSrc('');
      if (src) {
        URL.revokeObjectURL(src);
      }
    };

  }, [props]);
  
  return (
    <div ref={cnt}>
      <div className="one-stop-image" style={{ height: src ? undefined : '150px' }}>
        { src ? (
          <img src={src} alt="" style={{ width: '100%' }} />
        ) : (
          <div
            className="loading_popup__dots"
            style={{ fontSize: '0.5rem', animationDuration: `${1}s`, WebkitAnimationDuration: `${1}s` }}
          />
        ) }
      </div>
      <div>
        {imgData.orgFilename}
      </div>
    </div>
  )
}, (props, nextProps) => {
  return isEqual(props, nextProps) && isEqual(props.imgData, nextProps.imgData);
});
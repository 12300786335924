import React, { useCallback, useEffect, useState } from 'react';
import { Button } from '../../ui/button/button';
import { Checkbox } from '../../ui/input/checkbox';
import { Table } from '../../ui/table/table';
import { useDispatch } from 'react-redux';
import { PathParams, RoutingPath } from '../../../routes/routing-path';
import { push } from 'connected-react-router';
import { DisplayElement } from '../../../models/display-element';
import './order-table.scss';
import iconDelete from '../../../assets/img/icon/delete.svg';
import iconInfo from '../../../assets/img/icon/info_black_24dp.svg';
import { dialogActions } from '../../dialog/slice/dialog-slice';
import { DetailSearch } from '../../dialog/unique/detail-search';
import { LabOrder } from '../../dialog/unique/lab-order';
import { apiActions } from '../../../slices/api-slice';
import { ResponseBase } from '../../../api/response-base';
import { OrderGetResponse } from '../../../models/api/front/order';
import { commonActions } from '../../../slices/common-slice';
import { orderPreparationActions } from '../../../slices/order-preparation-slice';
import { ApiNumberingOrder } from '../../../api/back/numbering-order/api-numbering-order';
import { NumberingOrderResponse } from '../../../models/api/back/numbering-order';
import { useParams } from 'react-router-dom';
import { ApiOrders } from '../../../api/back/orders/api-orders';
import { OrdersResponse } from '../../../models/api/back/orders';
import { useAppSelector } from '../../../app/hooks';
import lodash from 'lodash';
import { newsListActions } from '../../../slices/news-list-slice';
import { ApiOrderCopyPost } from '../../../api/front/order/api-order-copy';
import { LoadingBar } from '../../ui/loading/loading-bar';
import { Input } from '../../ui/input/input';
import { xmlActions } from '../../../xml/slice/xml-slice';
import { orderLockActions } from '../../../slices/lock-order-slice';
import padlockOnIcon from '../../../assets/img/icon/padlock_on_icon.svg';
import {
  ApiShopOrderListGet,
  ApiShopOrderStatusListGet,
  ShopOrderListGetResponseStatus,
} from '../../../api/front/shop-order/api-shop-order-list';
import {
  ApiMetaShopOrderDelete,
  ApiMetaShopOrderGet,
  ApiMetaShopOrderPost,
  MetaShopOrderGetResponse,
} from '../../../api/front/meta/api-meta-shop-order';
import { DateFormatter } from '../../../utilities/date-formatter';
import { LoadingPopup } from '../../ui/loading/loading-popup';
import { ApiAllGetXml, GetXmlResponse } from '../../../api/front/xml/api-all-get-xml';
import JSZip from 'jszip';
import { ApiOrderLockCheck, OrderLockCheckResponse } from '../../../api/front/lock-order/api-lock-orders';
import { ApiAllDeleteXml } from '../../../api/front/xml/api-all-delete-xml';
import { localStorageActions } from '../../../slices/local-storage-slice';
import { ColorIcon } from '../../ui/color-icon/color-icon';
import warningIcon from '../../../assets/img/icon/warning_icon.svg';
import { LaboOrderCompChecker } from '../../../utilities/labo-order-comp-checker';
import { ApiGetStaffNameList } from '../../../api/front/staff/api-staff-name';
import { GetStaffNameResponse, StaffNameEntity } from '../../../models/api/front/staff-management';
import { ApiGetCloudFolderOrder } from '../../../api/front/cloud-folder/api-get-cloud-folder-order';
import { ApiCfOrderGetList, CfOrderGetOneDataEntity } from '../../../api/cf/api-cf-order-get-list/api-cf-order-get-list';
import { CustomError } from '../../../models/custom-error';

// - 検索条件 -
// -- 検索条件種類 --
type SearchType
  = 'all'
  | 'creating'
  | 'created'
  | 'layout'
  | 'enabledOrder'
  | 'ordering'
  | 'ordered'
  | 'othersOrdered';
// -- 注文ステータスコード --
type OrderStatusCode = '0' | '1' | '2' | '3' | '4' | '5' | '6';
// -- 検索条件ボタンデータ --
const searchButtonList: {
  type: SearchType,
  label: string,
  value: string,
  code: OrderStatusCode,
}[] = [
  {
    type: 'all',
    label: 'すべて',
    value: 'すべて',
    code: '0',
  },
  {
    type: 'creating',
    label: '注文作成中',
    value: '注文作成中',
    code: '1',
  },
  {
    type: 'created',
    label: '注文作成済み',
    value: '注文作成済',
    code: '2',
  },
  {
    type: 'layout',
    label: 'レイアウト中',
    value: 'レイアウト中',
    code: '3',
  },
  {
    type: 'enabledOrder',
    label: '発注可',
    value: 'ラボ発注可',
    code: '4',
  },
  {
    type: 'ordering',
    label: '発注中',
    value: 'ラボ発注中',
    code: '5',
  },
  {
    type: 'ordered',
    label: '発注完了',
    value: 'ラボ発注完了',
    code: '6',
  },
  {
    type: 'othersOrdered',
    label: '発注済み以外',
    value: '発注済み以外',
    code: '0',
  },
];

type SelectButtonProps = {
  // - ボタン種類 -
  type: SearchType,
  // - 選択中のボタンの種類 -
  currentType: SearchType,
  // - 表示する文言 -
  label: string,
  // - ボタン押下時のハンドラ -
  callback: (v: SearchType) => void,
};

type TableData = {
  createDate: string,
  lastName: string | null,
  lastUpdateDate: string,
  lockUser: string | null,
  note: string | null,
  orderDate: string | null,
  shopOrderId: string,
  status: string,
  cfOrderId?: string,
  isOneStop?: boolean,
  oneStopOrderDate: string | null,
  oneStopOrderUser: string | null,
  isOneStopCancel?: boolean,
};

type StatusData = {
  shopOrderId: string,
  orderStatusName: string,
  orderStatus: string,
  ppmOrderStatus: string,
  cfOrderId?: string,
  isOneStop?: boolean,
  isOneStopCancel?: boolean,
};

export type SearchParam = {
  // 作成日
  // 更新日
  date: { from: string, to: string },
  // 注文番号
  orderId: string,
  // お客様名
  customerName: string,
  // 作成日/更新日
  dateType: 'create' | 'update',
};

type CfDataList = { [key in string]: CfOrderGetOneDataEntity };

// - 検索条件選択ボタン用コンポネント -
const SelectButton = (props: SelectButtonProps) => {
  const { type, currentType, label, callback } = props;
  return (
    <div
      onClick={() => callback(type)}
      className={`type_select_button ${type === currentType ? 'current' : ''}`}
    >
      {label}
    </div>
  );
};

// 遷移先指定
const moveOrderDetailProp = (isOneStop?: boolean) => {
  return isOneStop 
    ? RoutingPath.cnv.oneStopOrdersPreparation
    : RoutingPath.cnv.ordersPreparation;
}

// ステータス絞り込み
const orderedStatusFilter = (
  res: TableData[],
  orderedList: StatusData[],
  searchType: SearchType,
  searchParam: SearchParam,
  cfDataList: CfDataList,
) => {
  const key = () => {
    switch (searchType) {
      case 'all':
        return 'すべて';
      case 'creating':
        return '注文作成中';
      case 'created':
        return '注文作成済';
      case 'enabledOrder':
        return 'ラボ発注可';
      case 'layout':
        return 'レイアウト中';
      case 'ordering':
        return 'ラボ発注中';
      case 'ordered':
        return 'ラボ発注完了';
      default:
        return '';
    }
  };
  // ワンストップ 一次セレクトあり ラボ処理中時の特殊制御
  // ラボ発注完了扱いとする
  const checkCfStatus = (data: TableData) => {
    const target = cfDataList[data.shopOrderId];
    if (!target) return false;
    return data.orderDate && target&& target.isSelectedDownload && data.status === 'ラボ発注中';
  }
  if (searchType === 'ordered') {
    return orderSearchFilter(res.filter((v) => (v.status !== '注文作成中' && v.status !== '注文作成済' && v.status !== 'レイアウト中' && v.status !== 'ラボ発注可' && v.status !== 'ラボ発注中') || orderedList.find((o) => o.shopOrderId === v.shopOrderId) || checkCfStatus(v)), searchParam);
  } else if (searchType !== 'all' && searchType !== 'othersOrdered') {
    return orderSearchFilter(res.filter((v) => v.status === key()), searchParam);
  } else if (searchType === 'othersOrdered') {
    return orderSearchFilter(res.filter((v) => !checkCfStatus(v) && !(v.status !== '注文作成中' && v.status !== '注文作成済' && v.status !== 'レイアウト中' && v.status !== 'ラボ発注可' && v.status !== 'ラボ発注中') && !orderedList.find((o) => o.shopOrderId === v.shopOrderId)), searchParam);
  } else {
    return orderSearchFilter(res, searchParam);
  }
};
// 詳細検索絞り込み
const orderSearchFilter = (
  res: TableData[],
  search: SearchParam,
) => {
  const fromDate = (search.date.from !== '') ? (res.filter((v) =>
    (search.dateType === 'create') ? Date.parse(v.createDate) >= Date.parse(search.date.from) - 32400000 : Date.parse(v.lastUpdateDate) >= Date.parse(search.date.from) - 32400000)) : [...res];
  const toDate = (search.date.to !== '') ? (fromDate.filter((v) =>
    (search.dateType === 'create') ? Date.parse(v.createDate) < Date.parse(search.date.to) + 54000000 : Date.parse(v.lastUpdateDate) < Date.parse(search.date.to) + 54000000)) : fromDate;
  const name = (search.customerName !== '') ? (toDate.filter((v) => v.lastName?.match(new RegExp(search.customerName.replaceAll(/[.*+?^${}()|[\]\\]/g, '\\$&'))))) : toDate;
  return (search.orderId !== '') ? (name.filter((v) => v.shopOrderId.includes(search.orderId))) : name;
};
const responseToTableData = (
  kijshopCd: string,
  res: TableData[],
  checkIdArr: string[],
  machineId: string,
  orderedList: StatusData[],
  searchType: SearchType,
  options: {
    callbackClickCheck?: (id: string) => void,
    callbackClickButton?: (shopId: string, isOrdered: boolean, isOneStop: boolean) => void,
    // 絞り込み
    search: SearchParam,
    check?: boolean,
    cfOrderList: CfDataList,
  },
) => orderedStatusFilter(
  res,
  orderedList,
  searchType,
  options.search,
  options.cfOrderList,
).filter((v) => !(!!v.cfOrderId && !v.cfOrderId)).map((v, i) => {
  const isLaboOrderRetry = (v.status === 'ラボ発注中' && LaboOrderCompChecker.checkTimeOut(v))
  const style = v.status === 'キャンセル' ?
    { opacity: '0.3' } :
    isLaboOrderRetry ?
      { color: '#d70b24' }:
      {};
  const status = (() => {
    if (isLaboOrderRetry) return '通信エラー';
    if (v.isOneStopCancel) return 'キャンセル';
    const cfData = options.cfOrderList[v.shopOrderId];
    if (v.orderDate && cfData && cfData?.isSelectedDownload && v.status === 'ラボ発注中') {
      return 'ラボ処理中';
    }
    return v.status;
  })();
  return [...[
    <Checkbox
      key={`order-list-table-check_${v.shopOrderId}_${checkIdArr.find((id) => id === v.shopOrderId)}`}
      onClick={options?.callbackClickCheck && (() => options.callbackClickCheck?.(v.shopOrderId))}
      checked={Boolean(checkIdArr.find((id) => id === v.shopOrderId))}
      styleType="1"
    />,
    // v.status || '',
    <div
      key={`order-list-table-status_${v.shopOrderId}_${v.status}`}
      className="order_status"
      data-status={v.isOneStopCancel ? 'キャンセル' : v.status}
      data-one-stop={!!v.cfOrderId}
    >
      <div className="order_status__inner">
        { isLaboOrderRetry ?
          <img src={warningIcon} /> :
          <ColorIcon color={status} />
        }
        <div style={style}>
          {status}
        </div>
      </div>
    </div>,
    <div
      style={style}
      data-status={v.lastName || ''}
    >{v.lastName || ''}</div>,
    <div
      style={style}
      data-status={v.createDate || ''}
    >{v.createDate || ''}</div>,
    <div
      style={style}
      data-status={v.lastUpdateDate || ''}
    >{v.lastUpdateDate || ''}</div>,
    <div
      style={style}
      data-status={v.shopOrderId || ''}
    >{v.shopOrderId || ''}</div>,
    // v.lastName || '',
    // v.createDate || '',
    // v.lastUpdateDate || '',
    // v.shopOrderId || '',
  ],
    <div>
      {(v.lockUser) ?
        <img
          src={padlockOnIcon}
          alt=""
        /> : <></>}
      <span
        title={v.lockUser? v.lockUser : ''}
      >{v.lockUser}</span>
    </div>,
    <Button
      label="詳細"
      icon={<img
        src={iconInfo}
        alt=""
      />}
      onClick={options?.callbackClickButton && (() => options.callbackClickButton?.(v.shopOrderId, v.status === 'ラボ発注中', !!v.cfOrderId))}
      size="sm"
      color="light"
    />,
  ];
});

type TableHeadKey = '' | 'check' | 'status' | 'lastName' | 'createDate' | 'updateDate' | 'shopOrderId';
type TableHeadData = {
  key: TableHeadKey,
  label: string,
};
const tableHeadData: TableHeadData[] = [
  {
    key: 'check',
    label: '',
  },
  {
    key: 'status',
    label: 'ステータス',
  },
  {
    key: 'lastName',
    label: 'お客様名',
  },
  {
    key: 'createDate',
    label: '作成日時',
  },
  {
    key: 'updateDate',
    label: '更新日時',
  },
  {
    key: 'shopOrderId',
    label: '注文番号',
  },
  {
    key: '',
    label: '操作状況',
  },
  {
    key: '',
    label: '',
  },
];
const TableHead = (props: { label: string, sort?: 'asc' | 'desc' }) => {
  const { label, sort } = props;
  return (
    <div>
      {label}
      {sort ? (
        <>
          {sort === 'asc' ? (' ▲') : ('')}
          {sort === 'desc' ? (' ▼') : ('')}
        </>
      ) : (<></>)}
    </div>
  );
};

export const OrderTable = () => {
  const { kijshopCd, shopOrderId } = useParams<PathParams>();
  // - Hooks -
  const dispatch = useDispatch();
  const { isSamePath, machineId, isProxyMode, lockId, storage } = useAppSelector((state) => ({
    isSamePath: state.newsList.isSamePath,
    machineId: state.storage.machineId,
    isProxyMode: state.systemConfig.metaData.surrogateOrder,
    lockId: state.orderLock.lockOrderId,
    storage: state.storage,
  }), lodash.isEqual);

  // - State -
  // -- 検索条件選択用 --
  const [searchType, setSearchType] = useState<SearchType>('othersOrdered');
  // -- テーブル一覧データ --
  const [tableData, setTableData] = useState<DisplayElement[][]>([]);
  const [tableViewData, setTableViewData] = useState<DisplayElement[][]>([]);
  // -- ソート --
  const [sort, setSort] = useState<'asc' | 'desc'>('desc');
  // -- ソート対象 --
  const [sortTarget, setSortTarget] = useState<TableHeadKey>('updateDate');
  // -- 注文一覧apiデータ --
  const [orderList, setOrderList] = useState<TableData[]>([]);
  const [orderedList, setOrderedList] = useState<StatusData[]>();
  // -- チェック状態管理用配列 --
  const [checkIdArr, setCheckIdArr] = useState<string[]>([]);
  // 検索用パラメータ
  const [searchParam, setSearchParam] = useState<SearchParam>({
    date: { from: '', to: '' },
    orderId: '',
    customerName: '',
    dateType: 'create',
  });
  const [checkAll, setCheckAll] = useState(false);
  // 代理発注モード　注文番号
  const [optionalNumber, setOptionalNumber] = useState('');
  const changeOptionalNumberHandler = (v: string) => {
    setOptionalNumber(v);
  };
  const findOneStopId = useCallback(() => {

  }, [orderedList]);

  // コピーして新規注文ボタン　disable状態管理
  const copyBtn = useCallback((): boolean => {
    const targetList = checkIdArr.map((v) => orderList.find((v2) => v2.shopOrderId === v)).filter((v) => v);
    // ワンストップ商品はコピ新を制御
    const filterList = targetList.filter((v) => !v?.cfOrderId);
    const num = filterList.length;
    return num !== 1;
  }, [checkIdArr]);
  // -- ローディングバーパーセンテージ --
  const [progressNum, setProgressNum] = useState(0);
  // -- 通信中フラグ --
  const [connectingMessage, setConnectingMessage] = useState('');
  // CloudFolderList
  const [cfList, setCfList] = useState<CfDataList>({});
  // - Callback -
  // -- 検索条件変更 --
  const handlerClickSearchType = useCallback((v: SearchType, code: OrderStatusCode) => {
    setSearchType(v);
    setCheckAll(false);
    setCheckIdArr([]);
  }, [orderList, checkIdArr, searchParam]);
  // -- 注文詳細遷移 --
  const handlerClickOrderDetail = useCallback((shopId: string, isOrdering, isOneStop: boolean) => {
    dispatch(commonActions.setOrderingShopId(isOrdering ? shopId : ''));
    dispatch(orderLockActions.check(kijshopCd, shopId, {
      yes: () => {
        dispatch(xmlActions.getXml(kijshopCd, shopId));
        dispatch(push(moveOrderDetailProp(isOneStop)({ kijshopCd: kijshopCd, shopOrderId: shopId })));
      },
      no: () => {
        getData(false);
      },
    }));
  }, [kijshopCd, shopOrderId]);
  // -- 注文詳細遷移(ダブルクリック) --
  const handlerDoubleClickItem = useCallback((i, v) => {
    // -- ソートで不整合が起きそうなのでテーブルのデータみちゃう --
    if ((v[5] as JSX.Element).props?.['data-status'] || (v[5] && typeof v[5] === 'string')) {
      handlerClickOrderDetail((v[5] as JSX.Element).props?.['data-status'] || v[5], false, (v[5] as JSX.Element).props?.['data-one-stop']);
    }
  }, []);
  // -- チェックボックス --
  const handlerClickCheckbox = useCallback((id: string) => {
    const statusCode = searchButtonList.find((v) => v.type === searchType)?.code;
    setCheckIdArr((prev) => {
      const arr = [...prev];
      const index = arr.findIndex((v) => v === id);
      if (index !== -1) {
        arr.splice(index, 1);
      } else {
        arr.push(id);
      }
      setTableData(responseToTableData(
        kijshopCd,
        orderList,
        arr,
        machineId,
        orderedList || [],
        searchType,
        {
          callbackClickCheck: handlerClickCheckbox,
          callbackClickButton: handlerClickOrderDetail,
          search: searchParam,
          cfOrderList: cfList,
        },
      ));
      return arr;
    });
  }, [orderList, checkIdArr, searchType, searchParam, cfList]);
  // -- 詳細検索 --
  const handlerClickDetailSearch = useCallback(() => {
    const statusCode = searchButtonList.find((v) => v.type === searchType)?.code;
    dispatch(dialogActions.push({
      title: '詳細検索',
      element:
        <DetailSearch
          handlerClickSearch={(value) => {
            setSearchParam(value);
            setCheckAll(false);
            setCheckIdArr([]);
            dispatch(dialogActions.pop());
          }}
          handlerClickClose={() => dispatch(dialogActions.pop())}
          searchParam={searchParam}
        />,
    }));
  }, [searchParam, checkIdArr, handlerClickCheckbox, handlerClickOrderDetail]);
  // -- 新規注文作成 --
  const handlerClickCreateOrder = useCallback(() => {
    if (isProxyMode) {
      if (optionalNumber.length !== 13) {
        dispatch(dialogActions.pushMessage({
          title: '確認',
          message: ['発注番号は、13桁の番号を入力してください。'],
          buttons: [{
            label: 'OK',
            callback: () => dispatch(dialogActions.pop()),
          }],
        }));
        return;
      }
      const check = optionalNumber.match(/[^0-9]+/);
      if (check?.length) {
        dispatch(dialogActions.pushMessage({
          title: '確認',
          message: ['発注番号に、不正な文字が入力されています。', '', optionalNumber],
          buttons: [{
            label: 'OK',
            callback: () => {
              dispatch(dialogActions.pop());
              setOptionalNumber(optionalNumber.replace(check[0], ''));
            },
          }],
        }));
        return;
      }
      dispatch(dialogActions.pushMessage({
        title: '確認',
        message: ['下記の注文番号で新規作成してよろしいですか？', '', optionalNumber],
        buttons: [
          {
            label: 'キャンセル',
            callback: () => {
              dispatch(dialogActions.pop());
              return;
            },
          },
          {
            label: 'OK',
            callback: () => {
              dispatch(dialogActions.pop());
              setConnectingMessage('発注番号を確認中です...');
              dispatch(apiActions.run(
                new ApiShopOrderListGet(
                  kijshopCd,
                ),
                {
                  onSuccess: (data: ResponseBase<ShopOrderListGetResponseStatus>) => {
                    if (data.body.data) {
                      const check = Boolean(data.body.data.statusList.find((v) => v.shopOrderId === optionalNumber));
                      setConnectingMessage('');
                      if (check) {
                        dispatch(dialogActions.pushMessage({
                          title: '確認',
                          message: [
                            'すでに同じ番号が存在しているため,',
                            'この番号は使用できません。',
                          ],
                          buttons: [{
                            label: 'OK',
                            callback: () => dispatch(dialogActions.pop()),
                          }],
                        }));
                        return;
                      }
                      createOrder();
                    }
                  },
                  onError: () => setConnectingMessage(''),
                },
              ));
            },
          },
        ],
      }));
    } else {
      createOrder();
    }
  }, [optionalNumber]);

  const createOrder = useCallback(() => {
    const showErrorMsg = () => {
      setConnectingMessage('')
      dispatch(dialogActions.pushMessage({
        title: 'エラー',
        message: ['注文の作成に失敗しました'],
        buttons: [{
          label: 'OK',
          callback: () => {
            dispatch(dialogActions.pop());
          },
        }],
      }));
    }
    setConnectingMessage('データを作成中です...');
    dispatch(apiActions.run(
      new ApiNumberingOrder({}),
      {
        onSuccess: (res: ResponseBase<NumberingOrderResponse>) => {
          const orderNo = (isProxyMode) ? optionalNumber : res.body.data?.orderNo;
          if (orderNo) {
            dispatch(xmlActions.shopOrder({ kijshopCd, shopOrderId: orderNo }).create({
              success: () => {
                dispatch(apiActions.run(new ApiMetaShopOrderPost(
                    {
                      kijshopCd,
                      shopOrderId: orderNo,
                      data: {
                        shopOrderId: orderNo,
                        lastUpdateDate: DateFormatter.date2str(new Date(), 'YYYYMMDD_HHmmSS', '/'),
                        createDate: DateFormatter.date2str(new Date(), 'YYYYMMDD_HHmmSS', '/'),
                        lastName: '',
                        note: '',
                        status: '注文作成中',
                        oneStopOrderDate: null,
                        oneStopOrderUser: null,
                      },
                    },
                  ),
                  {
                    onSuccess: () => {
                      dispatch(push(RoutingPath.cnv.ordersPreparation({ kijshopCd: kijshopCd, shopOrderId: orderNo })));
                      setConnectingMessage('');
                    },
                    onError: () => showErrorMsg(),
                  },
                  { ignoreSystemError: true },
                ));
              },
              error: () => showErrorMsg(),
            }));
          } else {
            showErrorMsg()
          }
        },
        onError: () => showErrorMsg(),
      },
    ));
  }, [optionalNumber]);
  // -- コピーして注文作成 --
  const handlerClickCopyOrder = useCallback(() => {
    const copyOrderNumList = checkIdArr[0];
    const message = [
      '下記の注文番号の注文データをコピーします。',
      'よろしいですか？',
      '',
      copyOrderNumList,
    ];
    dispatch(dialogActions.pushMessage({
      title: '確認',
      message,
      buttons: [
        {
          label: 'いいえ',
          callback: () => {
            dispatch(dialogActions.pop());
          },
        },
        {
          label: 'はい',
          callback: () => {
            dispatch(dialogActions.pop());
            setConnectingMessage('データをコピー中です...');
            const copyId = checkIdArr[0];
            let newId = '';
            let isOneStop = false;
            const copy = new Promise<void>((resolve, reject) => {
              if (checkIdArr.length >= 2 || !checkIdArr) reject();
              dispatch(apiActions.run(
                new ApiMetaShopOrderGet({
                  kijshopCd: kijshopCd,
                  shopOrderId: copyId,
                }),
                {
                  onSuccess: (res: ResponseBase<MetaShopOrderGetResponse>) => {
                    dispatch(apiActions.run(
                      new ApiNumberingOrder({}),
                      {
                        onSuccess: (resId: ResponseBase<NumberingOrderResponse>) => {
                          const orderNo = resId.body.data?.orderNo;
                          if (orderNo && res.body.data) {
                            newId = orderNo;
                            isOneStop = !!res.body.data?.cfOrderId;
                            const data = {
                              shopOrderId: orderNo,
                              lastUpdateDate: DateFormatter.date2str(new Date(Date.now()), 'YYYYMMDD_HHmmSS'),
                              createDate: DateFormatter.date2str(new Date(Date.now()), 'YYYYMMDD_HHmmSS'),
                              lastName: res.body.data.lastName,
                              note: res.body.data.note,
                              status: (!['注文作成中', '注文作成済', 'レイアウト中'].includes(res.body.data.status)) ? 'ラボ発注可' : res.body.data.status,
                              lockUser: '',
                              oneStopOrderDate: null,
                              oneStopOrderUser: null,
                            };
                            dispatch(apiActions.run(
                              new ApiOrderCopyPost(
                                {
                                  kijshopCd: kijshopCd,
                                  shopOrderId: copyId,
                                  copyShopOrderId: orderNo,
                                  data: data,
                                },
                              ),
                              {
                                onSuccess: () => resolve(),
                                onError: () => reject(),
                              },
                              { ignoreSystemError: true },
                            ));
                          } else {
                            reject();
                          }
                        },
                        onError: () => reject(),
                      },
                      { ignoreSystemError: true },
                    ));
                  },
                  onError: () => reject(),
                },
                { ignoreSystemError: true },
              ));
            });
            copy.then(() => {
              setConnectingMessage('');
              dispatch(dialogActions.pushMessage({
                title: '確認',
                message: ['下記の注文番号でコピーが作成されました。', '', newId],
                buttons: [
                  {
                    label: 'OK',
                    callback: () => {
                      dispatch(dialogActions.pop());
                      handlerClickOrderDetail(newId, false, isOneStop);
                    },
                  },
                ],
              }));
            }).catch(() => {
              setConnectingMessage('');
              dispatch(dialogActions.pushMessage({
                title: 'エラー',
                message: ['注文の作成に失敗しました'],
                buttons: [{
                  label: 'OK',
                  callback: () => {
                    dispatch(dialogActions.pop());
                  },
                }],
              }));
            });
          },
        },
      ],
    }));
  }, [checkIdArr, orderList, orderedList]);
  // -- まとめてラボ発注 --
  const handlerClickOrderSome = useCallback(() => {
    dispatch(dialogActions.push({
      title: 'ラボ発注',
      element: <LabOrder
        kijshopCd={kijshopCd}
        // orderList={(orderList.map((v) => checkIdArr.find((id) => id === v.shopOrderId)))}
        orderList={[...checkIdArr].map((id) => {
          const data = orderList.find((v) => v.shopOrderId === id);
          return {
            createDate: data?.createDate || '',
            lastName: data?.lastName || '',
            shopOrderId: data?.shopOrderId || id,
            status: data?.status || '',
            cfOrderId: data?.cfOrderId,
          };
        })}
        callbackEndOrder={() => getData(false)}
      />,
    }));
  }, [checkIdArr, orderList, kijshopCd]);
  // -- 一覧データ取得 --
  const getData = useCallback((init: boolean) => {
    setConnectingMessage('データを読み込み中です...');
    setCheckIdArr([]);
    const getCfList = () =>  {
      const fetcher = new ApiCfOrderGetList({ kijshopCd, kind: 1 });
      return fetcher.do().then((res) => {
        if (res instanceof CustomError) {
          return [] as CfOrderGetOneDataEntity[];
        } else {
          return res.body.data ?? [];
        }
      }).catch((e) => [] as CfOrderGetOneDataEntity[]);
    }

    const createCfList = async() => {
      const list = await getCfList();
      const obj: CfDataList = {};
      for (const info of list) {
        if (info.shopOrderId) {
          obj[info.shopOrderId] = info;
        }
      }
      setCfList(obj);
    }
    const getList = (staffList: StaffNameEntity[]) => {
      dispatch(apiActions.run(
        new ApiShopOrderStatusListGet(kijshopCd),
        {
          onSuccess: (res: ResponseBase<ShopOrderListGetResponseStatus>) => {
            
            dispatch(apiActions.run(
              new ApiOrders({}),
              {
                onSuccess: async(orderedRes: ResponseBase<OrdersResponse>) => {
                  await createCfList();
                  const list = orderedRes.body.data?.order || [];
                  if (list.length) {
                    dispatch(commonActions.setOrderedList({ order: [...list] }));
                  }
                  setOrderedList(list);
                  const arr: TableData[] = [...res.body.data?.statusList || []].map((v) => {
                    let staff
                    if(staffList) {
                      staff = staffList.find((data) => data.staffId === v.lockUser);
                    }else {
                      staff = null
                    }
                    const lockUser = staff ? staff.name : v.lockUser;
                    if (v.lockUser === machineId) {
                      dispatch(orderLockActions.unlock(kijshopCd, v.shopOrderId));
                    }
                    const status = list.find((data) => data.shopOrderId === v.shopOrderId)?.orderStatusName;
                    return ({
                      createDate: v.createDate,
                      lastName: v.lastName,
                      lastUpdateDate: v.lastUpdateDate,
                      lockUser: (v.lockUser === machineId) ? '' : lockUser,
                      note: v.note,
                      orderDate: v.orderDate,
                      shopOrderId: v.shopOrderId,
                      status: (status) ? status : v.status,
                      cfOrderId: v.cfOrderId ?? undefined,
                      isOneStop: !!v.isOneStop,
                      oneStopOrderDate: v.oneStopOrderDate,
                      oneStopOrderUser: v.oneStopOrderUser,
                      isOneStopCancel: !!v.isOneStopCancel,
                    });
                  });
                  setOrderList(arr);
                  setConnectingMessage('');
                },
                onError: () => {
                  const arr: TableData[] = [...res.body.data?.statusList || []].map((v) => ({
                    createDate: v.createDate,
                    lastName: v.lastName,
                    lastUpdateDate: v.lastUpdateDate,
                    lockUser: v.lockUser,
                    note: v.note,
                    orderDate: v.orderDate,
                    shopOrderId: v.shopOrderId,
                    status: v.status,
                    cfOrderId: v.cfOrderId,
                    isOneStop: !!v.isOneStop,
                    oneStopOrderDate: v.oneStopOrderDate,
                    oneStopOrderUser: v.oneStopOrderUser,
                    isOneStopCancel: !!v.isOneStopCancel,
                  }));
                  setOrderList(arr);
                  setConnectingMessage('');
                },
              },
              { ignoreSystemError: true },
            ));
          },
          onError: () => {
            setConnectingMessage('');
          },
        },
        { ignoreSystemError: true },
      ));
    }
      dispatch(apiActions.run(
        new ApiGetStaffNameList({ kijshopCd }), {
          onSuccess: (res: ResponseBase<GetStaffNameResponse>) => {
            if (res.body.data) {
              getList(res.body.data.staffNameList);
            } else {
              getList([]);
            }
          },
          onError: () => {
            getList([]);
          }
        }
      ));
  }, [kijshopCd, machineId]);
  // -- 削除 --
  const handlerClickDelete = useCallback(() => {
    dispatch(dialogActions.pushMessage({
      title: '確認',
      message: ['選択した注文情報を削除します。よろしいですか？'],
      buttons: [
        {
          label: 'いいえ',
          callback: () => {
            dispatch(dialogActions.pop());
          },
        },
        {
          label: 'はい',
          callback: () => {
            dispatch(dialogActions.pop());
            setConnectingMessage('注文を削除しています...');
            let lockList: { shopOrderId: string, lockUser: string }[] = [];
            let unlockList: string[] = [];
            Promise.all(checkIdArr.map((v) =>
              new Promise<void>((resolve) => {
                dispatch(apiActions.run(
                  new ApiOrderLockCheck({ kijshopCd, shopOrderId: v }),
                  {
                    onSuccess: (res: ResponseBase<OrderLockCheckResponse>) => {
                      const data = res.body.data;
                      if (data && data.name !== null) {
                        lockList.push({ shopOrderId: v, lockUser: data.name });
                      } else {
                        unlockList.push(v);
                      }
                      resolve();
                    },
                  },
                  { ignoreSystemError: true },
                ));
              }),
            )).then(() => {
              dispatch(apiActions.runAll(
                [...[...unlockList].map((id) => new ApiAllDeleteXml(kijshopCd, id))],
                {
                  onSuccess: () => {
                    setConnectingMessage('');
                    if (lockList.length) {
                      dispatch(dialogActions.pushMessage({
                        title: '確認',
                        message: [
                          '下記の注文は現在編集中のため、削除できませんでした。', '',
                          ...lockList.map((v) => `・ ${v.shopOrderId}（${v.lockUser}）`),
                        ],
                        buttons: [{
                          label: 'OK',
                          callback: () => {
                            dispatch(dialogActions.pop());
                            setCheckIdArr([]);
                            getData(false);
                          },
                        }],
                      }));
                    } else {
                      setCheckIdArr([]);
                      getData(false);
                    }
                  },
                  onError: () => {
                    setConnectingMessage('');
                    dispatch(dialogActions.pop());
                    dispatch(dialogActions.pushMessage({
                      title: 'エラー',
                      message: ['注文の削除に失敗しました'],
                      buttons: [{
                        label: 'OK',
                        callback: () => {
                          dispatch(dialogActions.pop());
                        },
                      }],
                    }));
                  },
                },
                true,
              ));
            }).catch(() => {
            });
          },
        },
      ],
    }));
  }, [checkIdArr, orderList, kijshopCd, orderedList, getData]);

  const handlerClickTableColumn = useCallback((index) => {
    const data = tableHeadData[index];
    if (data?.key && data.key !== 'check') {
      if (data.key === sortTarget) {
        setSort(sort === 'asc' ? 'desc' : 'asc');
      } else {
        setSort('desc');
        setSortTarget(data.key);
      }
    }
  }, [tableHeadData, sort, sortTarget]);
  // -- 全チェック --
  const handlerClickCheckAll = useCallback((flag) => {
    // setCheckIdArr(v ? [...orderList].map((order) => order.shopOrderId) : []);
    setCheckAll(flag);
    if (!flag) {
      setCheckIdArr([]);
      return;
    }
    const arr: string[] = [];
    tableViewData.forEach((v) => {
      const id = (v[5] as JSX.Element).props?.['data-status'] || v[5];
      if (id && typeof id === 'string') {
        arr.push(id);
      }
    });
    setCheckIdArr(arr);
    // setCheckIdArr(flag ? [...tableViewData].map((order) => (order[5] as JSX.Element)?.props?.['data-status'] || (v[5] && typeof v[5] === 'string')) : []);
  }, [tableViewData]);
  useEffect(() => {
    if (lockId !== '') {
      dispatch(orderLockActions.unlock(kijshopCd, lockId));
      dispatch(orderLockActions.setId(''));
    }
  }, [lockId]);
  // -- 一覧データ取得(旧) --
  useEffect(() => {
    // TODO 先の画面のデータリセット
    dispatch(orderPreparationActions.setOrderList([], kijshopCd));
    dispatch(commonActions.setCustomerName(''));
    dispatch(xmlActions.clearXmlData());
    if (isSamePath) {
      dispatch(newsListActions.setIsSamePath(false));
    }
  }, [kijshopCd, isSamePath]);
  // -- 画面表示更新 --
  useEffect(() => {
    const data = responseToTableData(
      kijshopCd,
      orderList,
      checkIdArr,
      machineId,
      orderedList || [],
      searchType,
      {
        callbackClickCheck: handlerClickCheckbox,
        callbackClickButton: handlerClickOrderDetail,
        search: searchParam,
        cfOrderList: cfList,
      },
    );
    setTableData(data);
    setTableViewData(data);
  }, [orderList, searchParam, orderedList, searchType, checkAll, cfList]);
  // -- ソート --
  useEffect(() => {
    const index = () => {
      switch (sortTarget) {
        case 'status':
          return 1;
        case 'lastName':
          return 2;
        case 'createDate':
          return 3;
        case 'updateDate':
          return 4;
        case 'shopOrderId':
          return 5;
        default:
          return 5;
      }
    };
    const data = [...tableData].sort((a, b) => {
      const int = index();
      // const v1 = int === 1 ? ((a[int] as JSX.Element).props?.['data-status'] || a[int]) : a[int];
      // const v2 = int === 1 ? ((b[int] as JSX.Element).props?.['data-status'] || b[int]) : b[int];
      const v1 = (a[int] as JSX.Element).props?.['data-status'];
      const v2 = (b[int] as JSX.Element).props?.['data-status'];
      if (sort === 'desc') {
        if (v1 < v2) {
          return 1;
        } else {
          return -1;
        }
      } else {
        if (v1 > v2) {
          return 1;
        } else {
          return -1;
        }
      }
    });
    setTableViewData(data);
  }, [tableData, sort, sortTarget]);
  useEffect(() => {
    getData(true);
    if (storage.kijshopCd !== kijshopCd || storage.kijshopCd === '') {
      dispatch(localStorageActions.getLocalData(kijshopCd));
    }
  }, []);

  const tableHead = [...tableHeadData].map((v) => (
    v.key === 'check' ? (
      <Checkbox
        checked={checkAll}
        onClick={() => handlerClickCheckAll(!checkAll)}
      />
    ) : (
      <TableHead
        label={v.label}
        sort={(v.key && sortTarget === v.key) ? sort : undefined}
      />
    )
  ));

  /* デバッグ用XML取得 TODO 消す */
  useEffect(() => {
    if (!(window as any).xml) {
      (window as any).xml = (id: string) => {
        new ApiAllGetXml(kijshopCd, id)
          .do()
          .then((res) => {
            const files = ((res as ResponseBase<GetXmlResponse>)?.body?.data as GetXmlResponse).fileinfo;
            if (!files?.length) {
              console.error('ファイルの取得に失敗しました');
              console.log(res.message);
              return;
            }
            const zip = new JSZip();
            files.forEach((v) => zip.file(v.filename, v.body));
            zip
              .generateAsync({ type: 'blob' })
              .then((content) => {
                const url = (window.URL || window.webkitURL).createObjectURL(content);
                const a = document.createElement('a');
                a.href = url;
                a.download = `${id}.zip`;
                a.click();
                (window.URL || window.webkitURL).revokeObjectURL(url);
              });
          })
          .catch((e) => {
            console.error('ファイルの取得に失敗しました');
            console.log(e);
          });
      };
    }
  }, []);

  return (
    <div className="order_table">
      <div className="order_table__type_select">
        <div>
          {searchButtonList.map((v, i) => (
            <SelectButton
              key={`order-list-search-type_${i}`}
              type={v.type}
              currentType={searchType}
              label={v.label}
              callback={() => handlerClickSearchType(v.type, v.code)}
            />
          ))}
          <Button
            label="詳細の検索"
            onClick={handlerClickDetailSearch}
            icon={<i className="fas fa-search" />}
            className="search_detail"
          />
        </div>
      </div>

      <div className="order_table__menu_btn">
        {isProxyMode ? (
          <div className="proxy_mode_form">
            <div className="proxy_mode_form__label">
              <div>代理発注モード</div>
              <div>発注番号を入力して新規作成</div>
            </div>
            <Input
              value={optionalNumber}
              onChange={(e) => changeOptionalNumberHandler(e.target.value)}
            />
          </div>
        ) : (<></>)}
        <Button
          label="新規作成"
          onClick={handlerClickCreateOrder}
          icon={<i className="fas fa-plus" />}
          color="warning"
        />
        {!isProxyMode ? (
          <Button
            label="コピーして新規注文"
            onClick={handlerClickCopyOrder}
            color="light"
            disabled={copyBtn()}
          />
        ) : (<></>)}
        <Button
          label="まとめてラボ発注"
          onClick={handlerClickOrderSome}
          color="light"
          disabled={!checkIdArr.length}
        />
        <Button
          label="削除"
          onClick={handlerClickDelete}
          icon={<img
            src={iconDelete}
            alt=""
          />}
          color="light"
          disabled={
            !checkIdArr.length
            || Boolean(checkIdArr.find((v) =>
              orderList.find((data) => data.shopOrderId === v)?.status === 'ラボ発注中'
              || orderedList?.find((data) => data.shopOrderId === v),
            ))
          }
        />
      </div>
      <div className="order_table__table">
        <Table
          head={tableHead}
          body={tableViewData}
          minimumNumOfRows={10}
          handlerClickColumn={handlerClickTableColumn}
          handlerDblClick={(index, v) => handlerDoubleClickItem(index, v)}
        />
      </div>
      <LoadingBar
        message="注文データをコピーしています"
        progressNum={progressNum}
      />
      {connectingMessage ? (<LoadingPopup label={connectingMessage} />) : (<></>)}
    </div>
  );
};

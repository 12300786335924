import { Header } from '../../ui/header/header';
import { Button } from '../../ui/button/button';
import { ItemInfoOrder } from './item-info-order.one-stop';
import { ItemDetailOneStopOrder } from './item-detail-order.one-stop';
import {
  DescriptionInfoOrder,
} from './description-info-order.one-stop';
import { CoverOneStopOrder } from './cover-order.one-stop';
import { OptionOrder } from './option-order.one-stop';
import { RemarksOrder } from './remarks-order.one-stop';
import { GenuineOrder } from './genuine-order.one-stop';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { PathParams, RoutingPath } from '../../../routes/routing-path';
import { LogDecorator } from '@tenryu/log-decorator';
import './create-order.one-stop.scss';
import iconReturn from '../../../assets/img/icon/return.svg';
import iconSave from '../../../assets/img/icon/save.svg';
import { dialogActions } from '../../dialog/slice/dialog-slice';
import { FavoriteBrandConfig } from '../../dialog/unique/favorite-brand-config';
import { useAppSelector } from '../../../app/hooks';
import * as lodash from 'lodash';
import { ordersActions, OrdersSliceState } from '../../../slices/orders-slice';
import { ordersDataActions, OrdersDataState } from '../../../slices/orders/orders-data-slice';
import { useParams } from 'react-router-dom';
import { debugActions } from '../../../slices/debug-slice';
import { itemDetailItemData } from '../../../models/create-order/item-detail-item-data';
import { coverItemData } from '../../../models/create-order/cover-item-data';
import { genuineItemData } from '../../../models/create-order/genuine-item-data';
import { descriptionInfoItemData } from '../../../models/create-order/description-info-item-data';
import { CreateOrderItemData } from '../../../models/create-order/create-order-item-data';
import { createOrderActions } from '../../../slices/create-order-slice';
import { xmlActions } from '../../../xml/slice/xml-slice';
import { commonActions } from '../../../slices/common-slice';
import { orderLockActions } from '../../../slices/lock-order-slice';
import { LoadingPopup } from '../../ui/loading/loading-popup';
import { localStorageActions } from '../../../slices/local-storage-slice';
import { apiActions } from '../../../slices/api-slice';
import {
  ApiFavoriteCopyPost,
  ApiFavoriteDataGet,
  FavoriteDataGetResponse,
} from '../../../api/front/favorite/api-favorite';
import { ResponseBase } from '../../../api/response-base';
import { OrderInfoDataXml } from '../../../xml/class/order/order-info-data-xml';
import { OrderPartsXml } from '../../../xml/class/order/order-parts-xml';
import { OrderPartsDataXml } from '../../../xml/class/order/order-parts-data-xml';
import { OrderPageXml } from '../../../xml/class/order/order-page-xml';
import { OrderPageDataXml } from '../../../xml/class/order/order-page-data-xml';
import { XmlFactory } from '../../../xml/factory/xml-factory';
import { XmlClass } from '../../../xml/class/xml-class';
import {
  XmlStructureOrderInfoData,
  XmlStructureOrderPage,
  XmlStructureOrderParts, XmlStructureOrderPartsData,
} from '../../../xml/model/xml-structure-model';
import { convertResponseToOrderData } from '../order-preparation/image-upload';
import iconHelp from '../../../assets/img/icon/help_icon.svg';
import { HowToOrder, ImgType } from '../../dialog/unique/how-to-order';
import { MailRemarksOrder } from './mail-remarks-order.one-stop';
import { DateFormatter } from '../../../utilities/date-formatter';
import { optionItemData } from '../../../models/create-order/opion-item-data';
import { store } from '../../../app/store';
import { useCfData } from '../one-stop-order-preparation/hooks/use-cf-data';

/*
 * 注文作成に必要なパラメータはコンポネント内では保持せず store の state を直接参照する
 * よってハンドラで行うのは項目変更時のアクション
 * 項目変更のアクションと state 更新のアクションは分離して変更時のアクションの中で state 更新のアクションを発火させる
 */
export const CreateOneStopOrder = () => {
  const { kijshopCd, shopOrderId, orderId } = useParams<PathParams>();
  const {
    category,
    order,
    data,
    selectOrder,
    connecting,
    xml,
    debug,
    master,
    loading,
    currentOrder,
    isFocusPageCount,
    visibleCondition,
  } = useAppSelector((state) => ({
    // shopOrderId: state.common.shopOrderId,
    // kijCd: state.common.kijshopCd,
    category: state.order.itemInfo.category.value,
    order: state.order,
    data: state.orderData,
    selectOrder: state.orderPreparation.currentOrder,
    connecting: state.api.connecting,
    xml: state.xml[shopOrderId],
    debug: state.debug.debug,
    master: state.common.data.masterShop,
    loading: state.createOrder.loading,
    currentOrder: state.orderPreparation.currentOrder,
    isFocusPageCount: state.createOrder.isFocusPageCount,
    visibleCondition: state.createOrder.visibleCondition,
  }), lodash.isEqual);

  const {
    cfOrderData,
  } = useCfData({
      kijshopCd,
      shopOrderId,
      redirectPath: orderId
        ? RoutingPath.cnv.ordersEdit({
          kijshopCd,
          shopOrderId,
          orderId,
        })
        : RoutingPath.cnv.ordersAdd({
          kijshopCd,
          shopOrderId,
        })
  });

  // - Effect -
  // -- ログまとめ --
  useEffect(() => {
    LogDecorator.group('<green>注文作成</green>');
    return () => LogDecorator.groupEnd();
  }, []);
  // - Hooks -
  const dispatch = useDispatch();
  const createDate = useMemo(() => DateFormatter.date2str(new Date(), 'YYYYMMDD_HHmmSS', '/'), []);
  // - State -
  // -- オプション数 --
  // const [optionNum, setOptionNum] = useState(1);
  const [saving, setSaving] = useState(false);
  // -- お気に入り商品読み込みフラグ --
  const [importFav, setImportFav] = useState(false);
  const [init, setInit] = useState(true);
  // - Callback -
  // -- オプション追加 --
  const handlerClickAddOption = useCallback(() => {
    // setOptionNum(optionNum + 1);
    dispatch(ordersActions.updateOption({ index: order.option.length, data: {} }));
  }, [order]);
  // -- 保存しないで終了 --
  const handlerClickFinish = useCallback(() => {
    console.log('test')
    dispatch(dialogActions.pushMessage({
      title: '確認',
      message: ['保存していない内容は失われます。終了してもよろしいですか？'],
      buttons: [
        {
          label: 'いいえ',
          callback: () => {
            dispatch(dialogActions.pop());
          },
        },
        {
          label: 'はい',
          callback: () => {
            dispatch(dialogActions.pop());
            dispatch(push(RoutingPath.cnv.oneStopOrdersPreparation({ kijshopCd, shopOrderId })));
          },
        },
      ],
    }));
  }, []);
  // -- 保存して終了 --
  const handlerClickSaveToFinish = useCallback(() => {
    // if (connecting) {
    //   return;
    // }
    // lockCheck!
    dispatch(orderLockActions.check(
      kijshopCd, shopOrderId,
      {
        yes: () => {
          // 必須項目バリデーション
          const showDialog = (v: string) => {
            dispatch(dialogActions.pushMessage({
              title: '確認',
              message: [`${v}は入力必須です`],
              buttons: [
                {
                  label: 'OK',
                  callback: () => {
                    dispatch(dialogActions.pop());
                  },
                },
              ],
            }));
          };
          if (data.option.optionProductGroupRules?.optionGroupRule?.length) {
            let flag: boolean = false;
            const arr: string[] = [];
            data.option.optionProductGroupRules.optionGroupRule.forEach((v) => {
              if (v.optionProductGroupName === 'setAny') {
                v.optionProductName.forEach((item) => {
                  if (!flag) {
                    if (order.option.find((op) => op?.item?.value === item.optionProductNameId)) {
                      flag = true;
                    } else {
                      console.log(store.getState().orderData.option.optionItem.optionProduct);
                      console.log(data.option.optionProductList.optionProduct);
                      console.log(item.optionProductNameId);
                      arr.push(data.option.optionItem.optionProduct.find((p) => p.optionProductNameId === item.optionProductNameId)?.optionProductName || '');
                    }
                  }
                });
              }
            });
            if (!flag && arr.filter((v) => v).length) {
              dispatch(dialogActions.pushMessage({
                title: '確認',
                message: [
                  '以下のオプションの内、いずれかを選択する必要があります。',
                  '',
                  ...[...arr.filter((v) => v)].map((v) => `・${v}`),
                ],
                buttons: [{
                  label: 'OK',
                  callback: () => {
                    dispatch(dialogActions.pop());
                  },
                }],
              }));
              return;
            }
          }
          // const display = displayItem(order, data, kijshopCd);
          const display = visibleCondition.getCreateOrderDisplay(order, data);
          if (display.descriptionInfo) {
            const description = order.descriptionInfo;
            if (
              (
                (description.name)
                || (description.date?.value)
                || (description.photographLocation)
              ) && !description.descriptionLocations?.length
            ) {
              dispatch(dialogActions.pushMessage({
                title: '確認',
                message: [
                  '商品記載情報の名前、日付、場所のいずれかが入力されている場合、',
                  '記載箇所のチェックが必要です。',
                  '',
                  '記載が必要ない場合',
                  '名前、日付、場所の入力を削除してください。',
                ],
                buttons: [{
                  label: 'OK',
                  callback: () => {
                    dispatch(dialogActions.pop());
                  },
                }],
              }));
              return;
            }
          }
          const optionCheck = order.option.find((v) => v.tag?.value && !v.item?.value);
          if (optionCheck) {
            dispatch(dialogActions.pushMessage({
              title: '確認',
              message: [
                '商品情報ー商品詳細ーオプションー商品タグが選択されている場合、',
                '商品情報ー商品詳細ーオプションー商品選択は入力必須です。',
              ],
              buttons: [{
                label: 'OK',
                callback: () => {
                  dispatch(dialogActions.pop());
                },
              }],
            }));
            return;
          }
          const errorMessage: string[] = [];
          const arr: CreateOrderItemData[] = [];
          if (order.itemInfo.category) {
            arr.push(...itemDetailItemData(order, data, visibleCondition));
          }
          if (display.cover) {
            arr.push(...coverItemData(order, data, visibleCondition));
          }
          if (display.genuine) {
            arr.push(...genuineItemData(order, data, visibleCondition));
          }
          if (display.descriptionInfo) {
            arr.push(...descriptionInfoItemData(order, data, visibleCondition));
          }
          if (display.option) {
            arr.push(...optionItemData(order, data, visibleCondition));
          }
          let alert = false;
          arr.forEach((v) => {
            if (v.validation && !v.validation?.(v.value)) {
              alert = true;
              dispatch(dialogActions.pushMessage({
                title: '確認',
                message: [
                  v.categorize,
                  '上記項目にエラーがあります。',
                  'ご確認ください。',
                ],
                buttons: [{
                  label: 'はい',
                  callback: () => {
                    dispatch(dialogActions.pop());
                  },
                }],
              }));
              return;
            }
            if (v.display && !v.optional && !v.value) {
              errorMessage.push(v.categorize);
            }
          });
          if (alert) {
            return;
          }
          // const itemDetailDisplay = itemDetailDisplayItem(order, data);
          const itemDetailDisplay = visibleCondition.itemDetailOrderDisplay
          if (!order.itemInfo.category.value) {
            showDialog('商品情報-カテゴリ');
            return;
          }
          if (itemDetailDisplay.cover && !order.itemDetail.coverSelect.value) {
            showDialog('商品情報-商品詳細-表紙選択');
            return;
          }
          if (itemDetailDisplay.coverColor && !order.itemDetail.coverColorSelect.value) {
            showDialog('商品情報-商品詳細-表紙色選択');
            return;
          }
          if (itemDetailDisplay.item && !order.itemDetail.itemSelect.value) {
            showDialog('商品情報-商品詳細-商品選択');
            return;
          }
          if (itemDetailDisplay.board && !order.itemDetail.boardSelect.value) {
            showDialog('商品情報-商品詳細-部材選択');
            return;
          }
          if (itemDetailDisplay.orderMethod && !order.itemDetail.orderMethod.value) {
            showDialog('商品情報-商品詳細-注文方法選択');
            return;
          }
          const save = (isComp: boolean) => {
            setSaving(true);
            const callback = {
              success: () => {
                setSaving(false);
                dispatch(localStorageActions.setData(kijshopCd, 'category', order.itemInfo.category.value));
                dispatch(dialogActions.pushMessage({
                  title: '確認',
                  message: ['注文を保存しました'],
                  buttons: [
                    {
                      label: 'OK',
                      callback: () => {
                        dispatch(dialogActions.pop());
                        // dispatch(push(RoutingPath.cnv.oneStopOrdersPreparation({ kijshopCd, shopOrderId })));
                        dispatch(push({
                          pathname: RoutingPath.cnv.oneStopOrdersPreparation({ kijshopCd, shopOrderId }),
                          search: '?osci',
                        }));
                      },
                    },
                  ],
                }));
              },
              error: () => {
                setSaving(false);
                dispatch(dialogActions.pushMessage({
                  title: 'エラー',
                  message: ['注文の作成に失敗しました'],
                  buttons: [
                    {
                      label: 'OK',
                      callback: () => {
                        dispatch(dialogActions.pop());
                      },
                    },
                  ],
                }));
              },
            };
            if (orderId) {
              const isNeedAgreement = xml?.orderInfo?.xml.orderInfoData(orderId)?.metaModel.isNeedAgreement === 'true';
              dispatch(xmlActions.order({
                shopOrderId,
                kijshopCd,
                callback,
              }).edit(order, data, visibleCondition, orderId, isComp, isNeedAgreement, cfOrderData ?? undefined));
            } else {
              dispatch(xmlActions.order({ shopOrderId, kijshopCd, callback }).create(order, data, visibleCondition, isComp, createDate, cfOrderData ?? undefined));
            }
          };
          if (errorMessage.length) {
            dispatch(dialogActions.pushMessage({
              title: '確認',
              message: [
                ...errorMessage,
                'は入力必須です。',
                '',
                '上記項目の入力が不十分です。',
                '作業内容を保存して前のページに戻ります。',
                'よろしいですか？',
              ],
              buttons: [
                {
                  label: 'いいえ',
                  callback: () => {
                    dispatch(dialogActions.pop());
                  },
                },
                {
                  label: 'はい',
                  callback: () => {
                    dispatch(dialogActions.pop());
                    save(false);
                  },
                },
              ],
            }));
          } else {
            save(true);
          }
        },
        no: () => {
          dispatch(orderLockActions.setId(''));
          dispatch(push(RoutingPath.cnv.orders({ kijshopCd })));
        },
      },
    ));
  }, [kijshopCd, shopOrderId, order, selectOrder, connecting, orderId, data, xml, visibleCondition, cfOrderData ]);
  useEffect(() => {
    // dispatch(xmlActions.shopOrder({ kijshopCd, shopOrderId }).create());
    dispatch(commonActions.getData(kijshopCd));
    // dispatch(xmlActions.getXml(kijshopCd, shopOrderId));
  }, []);
  // -- お気に入り商品読み込み --
  const handlerClickFavoriteBrandLoad = useCallback(() => {
    const failToLoad = () => {
      dispatch(createOrderActions.setLoading(false));
      dispatch(dialogActions.pushMessage({
        title: '確認',
        message: ['データの読み込みに失敗しました'],
        buttons: [{
          label: 'OK',
          callback: () => {
            dispatch(dialogActions.pop());
          },
        }],
      }));
    };
    const failToOrderMethodType = () => {
      dispatch(createOrderActions.setLoading(false));
      dispatch(dialogActions.pushMessage({
        title: '確認',
        message: [
          '選択されたお気に入り商品は「セレクト + 商品作成」の目的の注文では利用できません。',
          '別のお気に入り商品を選択するか、新規で商品を作成してください。',
        ],
        buttons: [{
          label: 'OK',
          callback: () => {
            dispatch(dialogActions.pop());
          },
        }],
      }));
    }

    dispatch(dialogActions.push({
      title: 'お気に入り商品設定',
      element: <FavoriteBrandConfig
        kijshopCd={kijshopCd}
        okButtonCallback={(id) => {
          dispatch(createOrderActions.setLoading(true));
          dispatch(ordersActions.setParam({}));
          dispatch(apiActions.run(
            new ApiFavoriteDataGet({ kijshopCd, favProductId: id }),
            {
              onSuccess: async (res: ResponseBase<FavoriteDataGetResponse>) => {
                if (!res?.body?.data?.xmlList?.length) {
                  failToLoad();
                  return;
                }
                // XMLの文字列から structure を生成
                /* レスポンスをXMLの種類毎に分割 */
                const resArr = [...res.body.data.xmlList];
                resArr.forEach((v) => {
                  v.filename = v.filename.replace('.xml', '');
                });
                const orderInfoDataResArr: { filename: string, xml: string }[] = [];
                const orderPartsResArr: { filename: string, xml: string }[] = [];
                const orderPartsDataResArr: { filename: string, xml: string }[] = [];
                const orderPageResArr: { filename: string, xml: string }[] = [];
                const orderPageDataResArr: { filename: string, xml: string }[] = [];
                resArr.forEach((v) => {
                  if (v.filename.includes('_orderInfoData_')) {
                    orderInfoDataResArr.push(v);
                  }
                  if (v.filename.includes('_orderParts_')) {
                    orderPartsResArr.push(v);
                  }
                  if (v.filename.includes('_orderPartsData_')) {
                    orderPartsDataResArr.push(v);
                  }
                  if (v.filename.includes('_orderPage_')) {
                    orderPageResArr.push(v);
                  }
                  if (v.filename.includes('_orderPageData_')) {
                    orderPageDataResArr.push(v);
                  }
                });
                /* 各XMLから index を取得 */
                const orderInfoDataMaterialArr: { xml: string, indexes: [string] }[] = [];
                const orderPartsMaterialArr: { xml: string, indexes: [string] }[] = [];
                const orderPartsDataMaterialArr: { xml: string, indexes: [string, string] }[] = [];
                const orderPageMaterialArr: { xml: string, indexes: [string, string] }[] = [];
                const orderPageDataMaterialArr: { xml: string, indexes: [string, string, string] }[] = [];
                let favShopOrderId = '';
                const errorCode = 'error';
                orderInfoDataResArr.forEach((v) => {
                  const arr = v.filename.split('_');
                  const len = arr.length;
                  orderInfoDataMaterialArr.push({ xml: v.xml, indexes: [arr[len - 1]] });
                  if (!favShopOrderId) {
                    favShopOrderId = arr[1];
                  } else if (favShopOrderId !== arr[1]) {
                    favShopOrderId = errorCode;
                  }
                });
                orderPartsResArr.forEach((v) => {
                  const arr = v.filename.split('_');
                  const len = arr.length;
                  orderPartsMaterialArr.push({ xml: v.xml, indexes: [arr[len - 1]] });
                  if (favShopOrderId !== arr[1]) {
                    favShopOrderId = errorCode;
                  }
                });
                orderPartsDataResArr.forEach((v) => {
                  const arr = v.filename.split('_');
                  const len = arr.length;
                  orderPartsDataMaterialArr.push({ xml: v.xml, indexes: [arr[len - 2], arr[len - 1]] });
                  if (favShopOrderId !== arr[1]) {
                    favShopOrderId = errorCode;
                  }
                });
                orderPageResArr.forEach((v) => {
                  const arr = v.filename.split('_');
                  const len = arr.length;
                  orderPageMaterialArr.push({ xml: v.xml, indexes: [arr[len - 2], arr[len - 1]] });
                  if (favShopOrderId !== arr[1]) {
                    favShopOrderId = errorCode;
                  }
                });
                orderPageDataResArr.forEach((v) => {
                  const arr = v.filename.split('_');
                  const len = arr.length;
                  orderPageDataMaterialArr.push({ xml: v.xml, indexes: [arr[len - 3], arr[len - 2], arr[len - 1]] });
                  if (favShopOrderId !== arr[1]) {
                    favShopOrderId = errorCode;
                  }
                });
                if (favShopOrderId === errorCode) {
                  failToLoad();
                  return;
                }
                /* 各XMLクラスを生成 */
                const orderInfoDataClassArr: OrderInfoDataXml[] = [];
                const orderPartsClassArr: OrderPartsXml[] = [];
                const orderPartsDataClassArr: OrderPartsDataXml[] = [];
                const orderPageClassArr: OrderPageXml[] = [];
                const orderPageDataClassArr: OrderPageDataXml[] = [];
                orderInfoDataMaterialArr.forEach((v) => {
                  const xml = XmlFactory.createFromIndex({
                    shopOrderId: favShopOrderId,
                    data: { name: 'order-info-data', indexes: v.indexes },
                  });
                  xml.xml = v.xml;
                  orderInfoDataClassArr.push(xml);
                });
                orderPartsMaterialArr.forEach((v) => {
                  const xml = XmlFactory.createFromIndex({
                    shopOrderId: favShopOrderId,
                    data: { name: 'order-parts', indexes: v.indexes },
                  });
                  xml.xml = v.xml;
                  orderPartsClassArr.push(xml);
                });
                orderPartsDataMaterialArr.forEach((v) => {
                  const xml = XmlFactory.createFromIndex({
                    shopOrderId: favShopOrderId,
                    data: { name: 'order-parts-data', indexes: v.indexes },
                  });
                  xml.xml = v.xml;
                  orderPartsDataClassArr.push(xml);
                });
                orderPageMaterialArr.forEach((v) => {
                  const xml = XmlFactory.createFromIndex({
                    shopOrderId: favShopOrderId,
                    data: { name: 'order-page', indexes: v.indexes },
                  });
                  xml.xml = v.xml;
                  orderPageClassArr.push(xml);
                });
                orderPageDataMaterialArr.forEach((v) => {
                  const xml = XmlFactory.createFromIndex({
                    shopOrderId: favShopOrderId,
                    data: { name: 'order-page-data', indexes: v.indexes },
                  });
                  xml.xml = v.xml;
                  orderPageDataClassArr.push(xml);
                });
                const parseFunc = (v: XmlClass<any>) => {
                  return new Promise<void>((resolve) => {
                    v.parse().then(resolve);
                  });
                };
                const parseAll = async (arr: XmlClass<any>[]) => {
                  for (let i = 0; i < arr.length; i++) {
                    await parseFunc(arr[i]);
                  }
                };
                await parseAll(orderInfoDataClassArr);
                await parseAll(orderPartsClassArr);
                await parseAll(orderPartsDataClassArr);
                await parseAll(orderPageClassArr);
                await parseAll(orderPageDataClassArr);
                // orderInfoDataClassArr.forEach((v) => v.build());
                // orderPartsClassArr.forEach((v) => v.build());
                // orderPartsDataClassArr.forEach((v) => v.build());
                // orderPageClassArr.forEach((v) => v.build());
                // orderPageDataClassArr.forEach((v) => v.build());
                /* 親商品を探査 */
                orderInfoDataClassArr.sort((a, b) => Number(a.indexes[0]) - Number(b.indexes[0]));
                const orderInfoData = orderInfoDataClassArr[0];
                const optionOrderInfoDataArr: OrderInfoDataXml[] = [];
                for (let i = 1; i < orderInfoDataClassArr.length; i++) {
                  optionOrderInfoDataArr.push(orderInfoDataClassArr[i]);
                }
                /* structureModel を生成 */
                const createOrderInfoDataStructure = (orderInfoData: OrderInfoDataXml) => {
                  const orderInfoDataStructureModel: XmlStructureOrderInfoData = {
                    xml: orderInfoData,
                  };
                  const partsPath = orderInfoData.xmlModel.lnwOrderInfoData.orderParts?.[0]?.$.path;
                  const orderParts = orderPartsClassArr.find((v) => v.xmlUniqueName === partsPath);
                  if (orderParts) {
                    const orderPartsStructureModel: XmlStructureOrderParts = {
                      xml: orderParts,
                      partsData: [],
                    };
                    orderInfoDataStructureModel.parts = orderPartsStructureModel;
                    orderParts.xmlModel.lnwOrderParts?.orderParts?.[0]?.data?.forEach((partsDataPath) => {
                      const orderPartsData = orderPartsDataClassArr.find((v) => v.xmlUniqueName === partsDataPath?.$.path);
                      if (orderPartsData) {
                        const orderPartsDataStructureModel: XmlStructureOrderPartsData = {
                          xml: orderPartsData,
                        };
                        const orderPagePath = orderPartsData.xmlModel.lnwOrderPartsData?.orderPage?.[0]?.$?.path;
                        const orderPage = orderPageClassArr.find((v) => v.xmlUniqueName === orderPagePath);
                        if (orderPage) {
                          const orderPageStructureModel: XmlStructureOrderPage = {
                            xml: orderPage,
                          };
                          orderPage.xmlModel.lnwOrderPage?.orderPage?.[0]?.data?.forEach((pageDataPath) => {
                            const orderPageData = orderPageDataClassArr.find((v) => v.xmlUniqueName === pageDataPath?.$.path);
                            if (orderPageData) {
                              if (orderPageStructureModel.pageData) {
                                orderPageStructureModel.pageData.push(orderPageData);
                              } else {
                                orderPageStructureModel.pageData = [orderPageData];
                              }
                            }
                          });
                          orderPartsDataStructureModel.page = orderPageStructureModel;
                        }
                        if (orderPartsStructureModel.partsData) {
                          orderPartsStructureModel.partsData.push(orderPartsDataStructureModel);
                        } else {
                          orderPartsStructureModel.partsData = [orderPartsDataStructureModel];
                        }
                      }
                    });
                  }
                  return orderInfoDataStructureModel;
                };
                const parentOrderInfoStructure = createOrderInfoDataStructure(orderInfoData);
                const optionOrderInfoStructures: XmlStructureOrderInfoData[] = [];
                orderInfoDataClassArr.forEach((v) => {
                  if (v.xmlUniqueName !== orderInfoData.xmlUniqueName) {
                    optionOrderInfoStructures.push(createOrderInfoDataStructure(v));
                  }
                });
                  /* ワンストップでは完成画像を扱えないため、読み込んだ商品が完成画像の場合は処理の中断 */
                  const isKansei = orderInfoData?.viewModel?.orderMethod?.id === '40';
                  if (isKansei) {
                    failToOrderMethodType();
                    return;
                  }

                /* 画像情報を残すために order-page-data を保持 */
                const pageDataList: OrderPageDataXml[] = [];
                parentOrderInfoStructure.parts?.partsData?.forEach((partsData) => partsData.page?.pageData?.forEach((v) => pageDataList.push(v)));
                dispatch(createOrderActions.setFavData({
                  parent: { id, pageDataList },
                  option: [...optionOrderInfoDataArr].map((v) => createOrderInfoDataStructure(v)),
                }));
                if (parentOrderInfoStructure.xml.viewModel.descriptionInfo?.pageType) {
                  parentOrderInfoStructure.xml.viewModel.descriptionInfo.pageType = undefined;
                }
                // runAllApi に投げる
                const data = convertResponseToOrderData(parentOrderInfoStructure, optionOrderInfoStructures, visibleCondition);
                dispatch(dialogActions.pop());
                dispatch(createOrderActions.setLoading(false));
                dispatch(ordersActions.runAllApi({ orders: data }, kijshopCd, Boolean(xml?.info.metaModel.dpNumber), true));
                setImportFav(true);
              },
              onError: () => {
                dispatch(createOrderActions.setLoading(false));
                failToLoad();
              },
            },
          ));
        }}
      />,
      className: 'favorite_brand_config__ancestor',
    }));
  }, []);

  const handlerClickHelp = useCallback((type: ImgType) => {
    dispatch(dialogActions.push({
      title: '説明',
      element: <HowToOrder imgType={type} />,
      closeBtn: true,
    }));
  }, []);

  // - Effect -
  useEffect(() => {
    dispatch(debugActions.addEvent());
    dispatch(createOrderActions.changePageStatus('in'));
    if (!xml) {
      dispatch(xmlActions.getXml(kijshopCd, shopOrderId));
    }
    return () => {
      dispatch(ordersDataActions.resetData());
      dispatch(ordersActions.setParam({}));
      dispatch(createOrderActions.changePageStatus('out'));
      dispatch(createOrderActions.setFavData(null));
      dispatch(createOrderActions.setIsFocusPageCount(false));
    };
  }, []);
  // - 項目表示 -
  // const display = displayItem(order, data, kijshopCd);
  const display = visibleCondition.getCreateOrderDisplay(order, data);
  if (debug) {
    // console.group('注文作成-親項目');
    LogDecorator.group(`<magenta>注文作成-親項目</magenta>`);
    console.log('商品記載情報: ', display.descriptionInfo);
    console.log('表紙: ', display.cover);
    console.log('本身: ', display.genuine);
    console.log('オプション: ', display.option);
    console.log('備考: ', display.remarks);
    console.groupEnd();
  }
  useEffect(() => {
    dispatch(orderLockActions.check(kijshopCd, shopOrderId, {
      yes: () => {
        dispatch(orderLockActions.setId(shopOrderId));
      },
      no: () => {
        dispatch(orderLockActions.setId(''));
        dispatch(push(RoutingPath.cnv.orders({ kijshopCd })));
      },
    }));
    if (!master) {
      dispatch(commonActions.getData(kijshopCd));
    }
  }, []);
  /* 編集での読み込み完了時更新用 */
  const [, setForceUpdate] = useState(false);
  useEffect(() => {
    if (!loading) {
      setTimeout(() => {
        setForceUpdate((prev) => !prev);
      });
    }
  }, [loading]);
  return (
    <div className="column_direction_wrap">
      <Header />

      <div className="create_order">
        <div className="create_order__menu">
          <div>
            <Button
              label="保存しないで終了"
              onClick={handlerClickFinish}
              icon={<img
                src={iconReturn}
                alt=""
              />}
              color="light"
            />
          </div>
          <div>
            <div className="helped_btn">
              <Button
                label="お気に入り商品読み込み"
                onClick={handlerClickFavoriteBrandLoad}
                disabled={Boolean(currentOrder) || Boolean(orderId)}
              />
              <Button
                className="order_help"
                icon={<img src={iconHelp} alt='' />}
                onClick={() => handlerClickHelp('favorite')}
              />
            </div>
            <Button
              label="保存して終了"
              onClick={handlerClickSaveToFinish}
              color="warning"
              icon={<img
                src={iconSave}
                alt=""
              />}
              disabled={connecting || isFocusPageCount}
            />
          </div>
        </div>

        <div className="create_order__category">

          {/* 常時表示 */}
          <ItemInfoOrder
            importFav={importFav}
          />

          {category ? (
            <>
              {/* 商品情報のカテゴリに依存 */}
              <ItemDetailOneStopOrder
                importFav={importFav}
              />

              {
                /*
                 * 【表示条件】
                 * 商品IDが存在すること
                 *  AND
                 *     商品IDが jptg301626, jptg301627, jptg302732 or jptg302737
                 *     OR
                 *     商品ID が特定のものでない
                 *       AND
                 *         デザインタイプ2 が選択されている
                 *         OR
                 *         注文方法結果が20(簡易レイアウトしておまかせ)
                 *          L 注文方法が選択された場合に取得
                 * */
              }
              {display.descriptionInfo ? (
                <DescriptionInfoOrder />
              ) : (<></>)}

              {
                /*
                 * 【表示条件】
                 * 商品IDが存在すること
                 *  AND
                 *     カバーIDが存在すること
                 *     AND
                 *       必須選択項目選択有無が有効なこと
                 *        L 注文方法が選ばれていて(
                 *           値が10(画像を選択しておまかせ)でなく(
                 *             非フロー変更版注文有無が有効もしくは部材選択が選択されているもしくは部材選択情報が存在しない場合
                 *           )
                 *           もしくはデザインタイプ2が選ばれている
                 *          )場合に必須項目選択有無を取得
                 * */
              }
              {display.cover ? (
                <CoverOneStopOrder />
              ) : (<></>)}

              {
                /*
                 * 【表示条件】
                 * 商品が選ばれていること
                 *  AND
                 *     必須選択項目選択有無が存在すること
                 * */
              }
              {display.genuine ? (
                <GenuineOrder />
              ) : (<></>)}

              {
                /*
                 * =========================== 廃止 ===========================
                 * ※内部的に op-1_selectImageFolder と op01_selectPsdFolder に分岐
                 * 【表示条件】
                 * Image: 注文方法選択結果が40(完成画像を入稿)であり商品選択が選択されていること
                 * Psd  : 注文方法選択結果が50(？？？)であり商品選択が選択されていること
                 * */
              }
              {/*{display.useImage ? (*/}
              {/*  <SelectUseImageOrder />*/}
              {/*) : (<></>)}*/}

              {
                /*
                 * 【表示条件】
                 * 商品選択が選択されていること
                 *  AND
                 *     パーツ数が2以上であること
                 *      L カテゴリと商品選択が選択された際にパーツ数を取得？（SQLの条件句にop01o_categoryとop01o_item）
                 * */
              }
              {display.option ? (
                <>
                  {order.option ? [...new Array(order.option.length || 1)].map((_, i) => (
                    <div
                      key={`create-order-option_${i}`}
                      className="order_category"
                    >
                      <OptionOrder index={i} importFav={importFav} />
                    </div>
                  )) : (<></>)}
                  <div className="helped_btn">
                    <Button
                      label="+ オプションを追加"
                      onClick={handlerClickAddOption}
                      className="add_option_btn"
                    />
                    <Button
                      className="order_help"
                      icon={<img src={iconHelp} alt='' />}
                      onClick={() => handlerClickHelp('option-add')}
                    />
                  </div>
                </>
              ) : (<></>)}

              {
                /*
                 * 【表示条件】
                 * 注文方法選択結果が10(画像を選択しておまかせ)の場合に表示
                 * */
              }
              {display.remarks ? (
                <RemarksOrder />
              ) : (<></>)}

              {
                /*
                 * メール記載情報っていうのがあるらしい
                 * たぶん実機確認は不可そもそもいるのかも不明
                 * 【表示条件】
                 * 店舗コードが 0005265, 0618984 or 0619486
                 * */
              }
              {display.mailRemarks ? (<MailRemarksOrder />) : (<></>)}

            </>
          ) : (<></>)}
        </div>
      </div>
      {loading ? (<LoadingPopup label={'データを読み込み中です...'} />) : (<></>)}
      {saving ? (<LoadingPopup label={'データを保存中です...'} />) : (<></>)}
    </div>
  );
};

import { ApiBaseFront } from '../api-base-front';

type ParamType = {
  kijshopCd: string;
}

export class ApiGetCloudFolderPurposeList extends ApiBaseFront {
  constructor(_param: ParamType) {
    const { kijshopCd, ...param } = _param;
    super(
      'GET',
      `/api/v1/c-folder/shop/${kijshopCd}/purpose`,
      'JSON',
      param,
    );
  }
}

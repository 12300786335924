import { XmlClass } from './xml-class';
import { IStatus } from '../interface/i-status';
import { XmlClassName } from '../model/xml-class-name';
import { TStatusMetaModel } from '../model/status/xml-status-model';
import { processId } from '../model/process-id';
import { XmlStructureModel } from '../model/xml-structure-model';

type TMeta = Partial<TStatusMetaModel>;
type StatusType = {
  type: 'order' | 'layout' | 'labOrder',
  status: '1' | '2',
};

export class StatusXml extends XmlClass<IStatus, {}, TMeta> {

  name: XmlClassName = 'status';

  viewModel: {} = {};
  metaModel: TMeta = {};

  protected init(): void {
    this.xmlUniqueName = this.xmlUniqueName || `PPM_${this.shopOrderId}_11_11_status.xml`;
    this.xmlParentName = this.xmlParentName || `PPM_${this.shopOrderId}_11_11.xml`;
    this.rootTagModel = {
      ...this.rootTagModel,
      parentXPath: '/lnwOrder/order/status',
      parentPath: this.xmlParentName,
    };
    this.docking({}, {});
  }

  isMixOrderMethod(structure: XmlStructureModel): boolean {
    return Boolean(structure.orderInfo?.infoData?.find((v) => !v.xml.metaModel.parentId && v.xml.viewModel.orderMethod?.id === '40') && structure.orderInfo?.infoData?.find((v) => !v.xml.metaModel.parentId && v.xml.viewModel.orderMethod?.id !== '40'));
  }
  // ワンストップ商品の時は、完成画像を含む場合、ラボ発注できない
  isSelectOrderCheck(structure: XmlStructureModel) {
    return Boolean(structure.orderInfo?.infoData?.find((v) => !v.xml.metaModel.parentId && v.xml.viewModel.orderMethod?.id === '40'));
  }
  getShopOrderStatus(structure: XmlStructureModel, isOneStop?: boolean) {
    // 商品情報がない場合
    if (!structure.status.xmlModel.lnwStatus.order?.[0].orderInfo?.[0].orderInfoData?.[0].data?.length) {
      return '注文作成中';
    }
    // 完成画像とそれ以外の注文方法が混在する場合
    if (this.isMixOrderMethod(structure)) {
      return '注文作成中';
    }
    if (isOneStop && this.isSelectOrderCheck(structure)) {
      return '注文作成中';
    }
    const processData = {
      process: 'labOrder',
      status: '1',
    };
    structure.status.xmlModel.lnwStatus.order[0].orderInfo[0].orderInfoData[0].data.forEach((v) => {
      if (!(processData.process === 'layout' && processData.status === '2') && structure.orderInfo?.xml.metaModel.parentSequence?.find((parent) => parent.id === v.$.id)) {
        const data = this.checkOrderStatus(v.$.id || '', structure);
        if (data.type === 'order') {
          if (processData.process !== 'order' || data.status === '2') {
            processData.status = data.status;
          }
          processData.process = data.type;
        }
        if (data.type === 'layout') {
          processData.process = 'layout';
          processData.status = data.status;
        }
      }
    });
    switch (processData.process) {
      case 'order':
        return processData.status === '1' ? '注文作成済' : '注文作成中';
      case 'layout':
        return processData.status === '1' ? 'ラボ発注可' : 'レイアウト中';
      case 'labOrder':
        return processData.status === '1' ? 'ラボ発注可' : 'ラボ発注中';
    }
    return '';
  }

  checkOrderStatus(orderId: string, structure: XmlStructureModel): StatusType {
    // 親商品でのみ呼ばれる想定なので parentSequence でのチェックは行わない
    const data = structure.status.xmlModel.lnwStatus.order?.[0]?.orderInfo?.[0]?.orderInfoData?.[0]?.data?.find((v) => v.$.id === orderId);
    if (!data) {
      return { type: 'order', status: '2' };
    }
    /* 注文作成中 */
    if (data.status?.[0]?.data?.find((v) => v.$.processID === processId.order && v.$.statusCode !== '1')) {
      return { type: 'order', status: '2' };
    }
    const orderInfoData = structure.orderInfo?.xml.orderInfoData(orderId);
    if (!orderInfoData) {
      return { type: 'order', status: '2' };
    }
    /* 画像を選択しておまかせ */
    if (orderInfoData.viewModel.orderMethod?.id === '10') {
      if (Number(orderInfoData.viewModel.orderExtensionInfo?.orderMethodKbn)) {
        return { type: 'labOrder', status: '1' };
      }
      const pageBreak = orderInfoData.orderPageBreak;
      if (!pageBreak) {
        return { type: 'order', status: '1' };
      }
      const orderPartsData = structure.orderInfo?.xml.orderInfoData(orderId)?.orderParts?.orderPartsDataMain();
      if (!orderPartsData) {
        return { type: 'order', status: '2' };
      }
      const { minImageCount, maxImageCount } = orderInfoData.imageCount;
      const image = Number(pageBreak?.viewModel.data?.image?.length || 0);
      if (image >= minImageCount && image <= maxImageCount) {
        return { type: 'labOrder', status: '1' };
      }
      return { type: 'order', status: '2' };
    }
    /* 完成画像を入稿 */
    if (orderInfoData.viewModel.orderMethod?.id === '40') {
      let flag!: boolean;
      orderInfoData.orderParts?.orderPartsDataArr.forEach((orderPartsData) => {
        orderPartsData.orderPage?.orderPageDataArr.forEach((orderPageData) => {
          if (flag === undefined) {
            flag = true;
          }
          if (flag && !orderPageData.viewModel.compositeFileName?.realPath) {
            flag = false;
          }
        });
      });
      if (flag) {
        return { type: 'labOrder', status: '1' };
      }
      return { type: 'order', status: '1' };
    }
    /* 簡易レイアウトしておまかせ、完全レイアウトして入稿 */
    if (!data.status?.[0].data?.find((v) => v.$.statusCode !== '1') && structure.orderInfo?.xml.orderInfoData(orderId)?.metaModel.layoutFinish === '1') {
      return { type: 'labOrder', status: '1' };
    }
    if (data.orderParts) {
      if (data.orderParts?.[0]?.orderPartsData?.[0]?.data?.[0]?.orderPage?.[0]?.orderPageData?.[0]?.data?.length) {
        if (data.orderParts?.[0]?.orderPartsData?.[0]?.data?.[0]?.orderPage?.[0]?.orderPageData?.[0]?.data?.[0].status?.[0].data?.find((v) => v.$.processID === processId.layout && (v.$.statusCode === '1'))) {
          if (structure.orderInfo?.xml.orderInfoData(orderId)?.metaModel.layoutFinish === '1') {
            return { type: 'layout', status: '1' };
          } else {
            return { type: 'layout', status: '2' };
          }
        } else if (data.orderParts?.[0].orderPartsData?.[0].data?.[0].orderPage?.[0].orderPageData?.[0].data?.[0].status?.[0].data?.find((v) => v.$.processID === processId.layout && (v.$.statusCode === '2'))) {
          return { type: 'layout', status: '2' };
        }
      }
    }
    if (data.status?.[0]?.data?.find((v) => v.$.processID === processId.order && v.$.statusCode === '1')) {
      return { type: 'order', status: '1' };
    }
    return { type: 'order', status: '2' };
  }

  getOrderStatus(orderId: string, structure: XmlStructureModel) {
    const data = this.checkOrderStatus(orderId, structure);
    switch (data.type) {
      case 'order':
        return data.status === '1' ? '注文作成済' : '注文作成中';
      case 'layout':
        return data.status === '1' ? 'ラボ発注可' : 'レイアウト中';
      case 'labOrder':
        return data.status === '1' ? 'ラボ発注可' : 'ラボ発注中';
    }
    return '';
  }

  protected docking(v: {}, m: TMeta): void {
    this.xmlModel = {
      lnwStatus: {
        $: { ...this.rootTagModel },
        order: [
          {
            status: [
              {
                $: {
                  count: `${m.data?.length || 0}`,
                },
                data: m.data?.map((data) => ({
                  $: {
                    // 工程通過コメント(空文字固定)
                    comment: '',
                    // エラーコード(0固定)
                    errorCode: '0',
                    // 作業完了日時
                    finishDate: data.finishDate,
                    // マシンID
                    machineID: data.machineID,
                    // 工程ID(reserve or retouch)
                    processID: data.processID,
                    // 作業開始日時
                    startDate: data.startDate,
                    // ステータスコード(0 or null or 1 or 2 or 3)
                    statusCode: data.statusCode,
                    // 作業者ID(空文字固定)
                    userID: '',
                    // 作業者名(空文字固定)
                    userName: '',
                  },
                })),
              },
            ],
            orderInfo: m.orderInfoData?.length ? [
              {
                // ステータス(空タグ固定)
                status: [
                  '',
                ],
                orderInfoData: [
                  {
                    $: {
                      count: `${m.orderInfoData?.length || 0}`,
                    },
                    data: m.orderInfoData?.map((orderInfoData, i) => (
                      {
                        $: {
                          // index
                          id: orderInfoData.id,
                        },
                        status: [
                          {
                            $: {
                              count: '1',
                            },
                            data: orderInfoData.data.map((data) => ({
                              $: {
                                // コメント(空文字固定)
                                comment: '',
                                // エラーコード(0固定)
                                errorCode: '0',
                                // 作業完了日時
                                finishDate: data.finishDate,
                                // マシンID
                                machineID: data.machineID,
                                // 工程ID
                                processID: data.processID,
                                // 作業開始日時
                                startDate: data.startDate,
                                // ステータスコード(0 or null or 1 or 2 or 3)
                                statusCode: data.statusCode,
                                // 作業者ID(空文字固定)
                                userID: '',
                                // 作業者名(空文字固定)
                                userName: '',
                              },
                            })),
                          },
                        ],
                        orderParts: orderInfoData.orderPartsData?.length ? [
                          {
                            // 空タグ固定
                            status: [
                              '',
                            ],
                            orderPartsData: [
                              {
                                $: {
                                  count: `${orderInfoData.orderPartsData?.length || 0}`,
                                },
                                data: orderInfoData.orderPartsData?.map((orderPartsData, partsDataIndex) => ({
                                  $: {
                                    // index
                                    id: `${orderPartsData.id || partsDataIndex + 1}`,
                                  },
                                  status: [
                                    '',
                                  ],
                                  orderPage: [
                                    {
                                      status: [
                                        '',
                                      ],
                                      orderPageData: [
                                        {
                                          $: {
                                            count: `${orderPartsData.orderPageData.length}`,
                                          },
                                          data: orderPartsData.orderPageData.map((orderPageData, index) => ({
                                            $: {
                                              // index
                                              id: `${orderPageData.id || index + 1}`,
                                            },
                                            status: [
                                              {
                                                $: {
                                                  count: `${orderPageData.data.length}`,
                                                },
                                                data: orderPageData.data.map((data) => ({
                                                  $: {
                                                    // コメント(空文字固定)
                                                    comment: data.comment ?? '',
                                                    // エラーコード(0固定)
                                                    errorCode: '0',
                                                    // 作業完了日時
                                                    finishDate: data.finishDate,
                                                    // マシンID
                                                    machineID: data.machineID,
                                                    // 工程ID(レイアウトでのみ生成、jptg380040固定)
                                                    processID: 'jptg380040',
                                                    // 作業開始日時
                                                    startDate: data.startDate,
                                                    // ステータスコード(0 or null or 1 or 2 or 3)
                                                    statusCode: data.statusCode,
                                                    // 作業者ID(空文字固定)
                                                    userID: '',
                                                    // 作業者名(空文字固定)
                                                    userName: '',
                                                  },
                                                })),
                                              },
                                            ],
                                          })),
                                        },
                                      ],
                                    },
                                  ],
                                })),
                              },
                            ],
                          },
                        ] : undefined,
                      }
                    )),
                  },
                ],
              },
            ] : undefined,
          },
        ],
      },
    };
  }

  protected split(x: IStatus): void {
    if (!x?.lnwStatus?.order || !x.lnwStatus.$) {
      this.metaModel = {};
      return;
    }
    this.viewModel = {};
    this.metaModel = {
      data: (x.lnwStatus.order?.[0].status?.[0].data) ?
        x.lnwStatus.order[0].status[0].data.map((data) => ({
          finishDate: data.$.finishDate!,
          machineID: data.$.machineID!,
          processID: data.$.processID!,
          startDate: data.$.startDate!,
          statusCode: data.$.statusCode!,
        })) : [],
      orderInfoData: (x.lnwStatus.order?.[0].orderInfo?.[0].orderInfoData?.[0].data) ?
        x.lnwStatus.order[0].orderInfo[0].orderInfoData[0].data.map((data, infoDataIndex) => ({
          id: data.$.id || `${infoDataIndex + 1}`,
          data: data.status![0].data!.map((v) => ({
            finishDate: v.$.finishDate!,
            machineID: v.$.machineID!,
            processID: v.$.processID!,
            startDate: v.$.startDate!,
            statusCode: v.$.statusCode!,
          })),
          orderPartsData: data.orderParts?.[0]?.orderPartsData?.[0]?.data?.map((orderPartsData, partsDataIndex) => ({
            id: orderPartsData.$.id || `${partsDataIndex + 1}`,
            orderPageData: orderPartsData.orderPage?.[0].orderPageData?.[0]?.data?.map((pageData, pageDataIndex) => ({
              id: pageData.$.id || `${pageDataIndex + 1}`,
              data: pageData.status?.[0]?.data?.map((v) => ({
                finishDate: v.$.finishDate!,
                machineID: v.$.machineID!,
                processID: v.$.processID!,
                startDate: v.$.startDate!,
                statusCode: v.$.statusCode!,
                comment: v.$.comment ?? '',
              })) || [],
            })) || [],
          })) || [],
        })) : [],
    };
    this.rootTagModel = Object.assign(this.rootTagModel, x.lnwStatus.$);
  }
}

import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  AlertPathData,
  layoutActions,
  LayoutItemData,
  LayoutTemplateFolderData,
  TemplatesInfo,
} from '../../../../slices/layout-slice';
import { useAppSelector } from '../../../../app/hooks';
import { SwitchIcon } from '../../../ui/switch-icon/switch-icon';
import { useDispatch } from 'react-redux';
import { apiActions } from '../../../../slices/api-slice';
import folderOpenOn from '../../../../assets/img/icon/folder-open-on.svg';
import folderCloseOn from '../../../../assets/img/icon/folder-close-on.svg';
import * as lodash from 'lodash';
import { templateActions } from '../../../../slices/api/template-actions';
import { XmlParser } from '../../../../manager/xml-parser';
import { Env } from '../../../../models/env';
import { TemplateLoaderManager } from '../../../../layout-editor/manager/template-loader/template-loader.manager';
import { favoriteTemplateActions } from '../../../../slices/favorite-template-slice';
import { dialogActions } from '../../../dialog/slice/dialog-slice';
import { cloneDeep } from 'lodash';
import { useDidMount } from '../../../../hooks/life-cycle';
import { useParams } from 'react-router-dom';
import { PathParams, RoutingPath } from '../../../../routes/routing-path';
import { push } from 'connected-react-router';
import { LayoutContext } from '../layout';


type TemplateFolderProps = {
  folderData: LayoutTemplateFolderData,
  // - 下位層のインデント -
  layerIndent?: number,
  // - 下位層なしアイテム用インデント(ツリーアイコン幅) -
  noChildIndent?: number,
  // - ツリーアイコン幅設定用コールバック(値の共有用) -
  setTreeIconWidth?: (width: number) => void,
  // - 選択中のフォルダ情報 -
  selectFolder: LayoutTemplateFolderData,
  pageCount: string,
  templatesXmlArr: any[],
};

export const getTargetPathUrl = (path: string): string => {
  const urlBase = `${Env.api.back.protocol}://${Env.api.back.host}/files/template`;
  const url = `${urlBase}/${path.split('/').filter(v => v).join('/')}`;
  return url;
};

const TemplateFolder = (props: TemplateFolderProps) => {
  const { folderData, layerIndent, setTreeIconWidth, noChildIndent, selectFolder, pageCount, templatesXmlArr } = props;
  const dispatch = useDispatch();
  const treeIconWidth = noChildIndent;
  const layoutList = useAppSelector((v) => v.layout.layoutList, lodash.isEqual);
  const initOpenType = useAppSelector((v) => v.layout.openTemplateType, lodash.isEqual);
  // - State -
  const [isOpen, setIsOpen] = useState(false);
  const [hover, setHover] = useState(false);
  // -- 選択中フォルダとこのフォルダが一致するか --
  const [isSelect, setIsSelect] = useState(false);
  // - Ref -
  // -- ツリー構造用アイコンEle --
  const treeIconEle = useRef<HTMLDivElement>(null);
  // -- フォルダーアイコンEle --
  const folderIconEle = useRef<HTMLDivElement>(null);
  // - Effect -
  // -- ツリー(三角)アイコン幅 --
  useEffect(() => {
    const tiw = treeIconEle.current?.getBoundingClientRect().width || 0;
    if (tiw && tiw !== treeIconWidth) {
      setTreeIconWidth && setTreeIconWidth(tiw);
    }
  }, []);
  // -- ホバー時の画像差し替え --
  useEffect(() => {
    folderIconEle.current?.setAttribute('src', isOpen ? folderOpenOn : folderCloseOn);
  }, [hover]);
  // -- 選択中のフォルダと当フォルダが一致するかチェック --
  useEffect(() => {
    if (selectFolder.id === folderData.id) {
      setIsSelect(true);
    } else {
      setIsSelect(false);
    }
  }, [selectFolder]);
  // - Callback -
  // -- フォルダ押下 --
  const handlerClickFolder = useCallback(async (e) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(layoutActions.setTemplateList([]));
    if (!folderData.children.length) return;
    if (!isOpen || folderData.children.find((v) => v.type === 'directory')) {
      setIsOpen(!isOpen);
    }
    if (folderData.children[0].type === 'template') {
      dispatch(layoutActions.setOpenTemplateType({
        type: 'all',
        favoriteFolderID: '',
      }));
      window.setTimeout(() => {
        dispatch(layoutActions.setTemplateList([]));
        const arr: LayoutItemData[] = [];
        const parentTarget = templatesXmlArr.find((v) => v.$.path === folderData.id);
        let _pageCount = pageCount;
        if (!pageCount) {
          _pageCount = '6';
        }
        for (const v of folderData.children) {
          if (!parentTarget) continue;
          const target = parentTarget.templateGroup[0].templateData.find((vv: any) => vv.$.path === v.id);
          // 表紙の場合にページ数が範囲内かチェックする
          if (
            target &&
            parentTarget.$.name.indexOf('cover') !== -1 &&
            target.cover &&
            (Number(target.cover[0].$.minPageCount) > Number(_pageCount) || Number(target.cover[0].$.maxPageCount) < Number(_pageCount))
          ) {
            continue;
          }
          const thumbnail = getTargetPathUrl(v.id + '/thumbnail.jpg');
          const templateXmlUrl = getTargetPathUrl(v.id);
          const templateName = v.label.indexOf('___') !== -1 ? v.label.substring(0, v.label.indexOf('___')) : v.label;
          dispatch(layoutActions.setThumbnailPath(thumbnail));
          const templateItem: LayoutItemData = {
            id: folderData.id,
            templateName,
            thumbnail,
            templateXmlUrl,
            targetPath: v.id,
          };
          arr.push(templateItem);
        }
        dispatch(layoutActions.setTemplateList(arr));
      }, 0);
    }
    dispatch(layoutActions.setSelectFolder(folderData));
  }, [isOpen, folderData, pageCount, templatesXmlArr]);

  const searchTemplate = useCallback((v: typeof folderData) => {
    if (v.children.find((v) => v.type === 'template')) {
      layoutList.forEach((v2) => {
        if (v.id === v2.id) setIsOpen(true);
      });
    } else if (v.children.length) {
      v.children.forEach((v2) => {
        searchTemplate(v2);
      });
    }
  }, [layoutList, folderData]);

  useDidMount(() => {
    if (initOpenType.type !== 'all') return;
    searchTemplate(folderData);
  });

  return (
    <div
      className="folders_tree"
      style={{ paddingLeft: layerIndent }}
      onClick={(e) => handlerClickFolder(e)}
    >
      <div
        className="folders_tree__item"
        onMouseOver={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setHover(true);
        }}
        onMouseLeave={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setHover(false);
        }}
      >
        <div
          className="folders_tree__item__icon"
          style={folderData.children.length && folderData.children[0].type === 'directory' ? {} : { paddingLeft: noChildIndent }}
        >
          {folderData.children.length && folderData.children[0].type === 'directory' ? (
            <SwitchIcon
              isOn={isOpen}
              tmplMode="tree"
              ref={treeIconEle}
            />
          ) : (<></>)}
          <SwitchIcon
            isOn={folderData.children[0]?.type !== 'directory' ? isSelect : isOpen}
            tmplMode="folder"
            ref={folderIconEle}
            switchEle={hover ? {
              on: <img
                src={folderOpenOn}
                alt=""
              />,
              off: <img
                src={folderCloseOn}
                alt=""
              />,
            } : undefined}
          />
        </div>
        <span className={`folders_tree__item__label ${folderData.children.length ? '' : 'lonely'}`}>
          {folderData.label}
        </span>
      </div>
      {isOpen ? (
        folderData.children.map((v, i) => v.type === 'directory' ? (
          <TemplateFolder
            key={`template-folder_${v.id}_${i}`}
            folderData={v}
            layerIndent={treeIconWidth}
            noChildIndent={noChildIndent}
            selectFolder={selectFolder}
            pageCount={pageCount}
            templatesXmlArr={templatesXmlArr}
          />
        ) : (
          <React.Fragment key={`template-folder_${v.id}_${i}`}></React.Fragment>
        ))
      ) : (<></>)}
    </div>
  );
};

const createFolderList = (temp: any, path: string): LayoutTemplateFolderData => {
  const arr = temp.templateGroupDetailData
    ? temp.templateGroupDetailData
    : temp.templateGroupDetailDataPath
      ? temp.templateGroupDetailDataPath
      : [];
  const tempPath = temp.path ? temp.path : temp;
  const tempName = temp.name ? temp.name : temp.path ? temp.path.replace(`${path}/`, '') : temp.replace(`${path}/`, '');
  let children = [];
  if (arr.length) {
    children = arr.map((v: any) => createFolderList(v, tempPath));
  }
  const data: LayoutTemplateFolderData = {
    id: tempPath,
    label: tempName,
    type: typeof (temp) === 'string' ? 'template' : 'directory',
    children,
  };
  return data;
};

const createTopFolder = (
  arr: string[],
  index: number,
  children: LayoutTemplateFolderData[],
): LayoutTemplateFolderData => {
  let child: LayoutTemplateFolderData[] = [];
  if (index === arr.length - 1) {
    child = children;
  } else if (index < arr.length) {
    child = [createTopFolder(arr, index + 1, children)];
  }
  const data: LayoutTemplateFolderData = {
    id: arr[index],
    label: arr[index],
    type: 'directory',
    children: child,
  };
  return data;
};

export const LayoutALlTemplate = (props: { pageTypeIdArr: any[], onLoad: () => void }) => {
  const { kijshopCd, shopOrderId, orderId } = useParams<PathParams>();
  const {
    preparationPath,
  } = useContext(LayoutContext);
  const {
    folderList,
    selectFolder,
    albumSettingData,
    layoutQuery,
    templatesXmlArr,
    orderInfoData,
    albumPages,
  } = useAppSelector((state) => ({
    folderList: state.layout.folderList,
    selectFolder: state.layout.selectFolder,
    albumSettingData: state.layout.albumSettingData,
    layoutQuery: state.layout.queryParams,
    templatesXmlArr: state.layout.templatesXmlList,
    orderInfoData: state.xml[shopOrderId]?.orderInfo?.infoData,
    albumPages: state.layout.albumPages,
  }), lodash.isEqual);
  const dispatch = useDispatch();
  const { pageTypeIdArr, onLoad } = props;
  const [templates, setTemplates] = useState(templatesXmlArr);
  const [count, setCount] = useState(0);

  useEffect(() => {
    let typeArr = pageTypeIdArr;
    if (!typeArr.length) {
      return;
      // typeArr = ['jptg380012', 'jptg380013', 'jptg380014', 'jptg380015'];
    }
    if (!folderList.length && layoutQuery && !templatesXmlArr.length && count < 1) {
      setCount(prev => prev + 1);
      const orderMethodId = orderInfoData?.find((v) => v.xml.metaModel.id === orderId)?.xml.viewModel.surrogateProcess?.id
      // orderMethodIdが取得できなかったら商品は破損していると認定する
      if (!orderMethodId) {
       dispatch(dialogActions.pushMessage({
         title: 'エラー',
         message: [
           '注文情報の取得に失敗しました',
           '再度「レイアウト」を実行してください',
           '改善されない場合、注文を作り直して頂く必要があります'
         ],
         buttons: [{
           label: 'OK',
           callback: () => {
             dispatch(dialogActions.pop())
             dispatch(push(preparationPath));
           }
         }]
       }))
      }
      dispatch(layoutActions.setFolderList([]));
      const request = {
        productNameId: layoutQuery.productNameId,
        // - 部材情報はあれば渡す -
        boardInfoId: orderInfoData?.find((v) => v.xml.metaModel.id === orderId)?.xml.viewModel.boardInfo,
        // - 注文方法を渡す -
        orderMethodId: orderMethodId!,
      };
      dispatch(templateActions.AllTemplate(request, {
        onSuccess: async (res) => {
          if (res.error.errorCode === 404 || !res.body.data) {
            onLoad();
            dispatch(dialogActions.pushMessage({
              title: '確認',
              message: ['該当商品のテンプレートは、明日から選択できるようになります。'],
              buttons: [{
                label: 'OK',
                callback: () => dispatch(dialogActions.pop()),
              }],
            }));
            return;
          }
          const alertResultPath: AlertPathData[] = [];
          const result: LayoutTemplateFolderData[] = [];
          const mostTopList: string[] = [];
          const templatesArr: any[] = [];
          // 表示する一番上の階層のループ
          for (const v of res.body.data.templateGroupData) {
            const topName = v.hpsTppath.split('/');
            const topIndex = topName.indexOf(v.dtemplName);
            topIndex && topName.splice(topIndex, 1, v.dtemplDisplayName);
            const mostTop = topName.shift();
            mostTopList.push(mostTop);
            if (!topName.length) continue;
            // この階層にあるtemplates.xmlを取得
            const arr = await TemplateLoaderManager.ins.onGetTargetFile(v.hpsTppath, 'templates', '');
            // テンプレートが入るフォルダの作成
            const children: LayoutTemplateFolderData[] = (v.templateGroupDetailData || []).map((vv: any) => {
              // このフォルダの親で取得したtemplates.xmlから該当する部分を探す
              const targetTemplateGroupData = arr.find((vvv: any) => vvv.$.path === vv.path);
              if (targetTemplateGroupData) {
                // 表示するページ種のみフォルダを作るようにする
                // 変更確認用Path配列にpush
                targetTemplateGroupData.templateGroup?.forEach((info: any) => {
                  info.templateData?.forEach((detail: any) => {
                    if (typeArr.includes(detail.$.type)) {
                      albumPages.option.forEach(v => {
                        if (v.xml.viewModel.pageType === detail.$.type) {
                          dispatch(layoutActions.setOptionName({optionType: 'option', name: targetTemplateGroupData.$.name, pageType: detail.$.type}))
                        }
                      })
                      albumPages.opPrint.forEach(v => {
                        if (v.xml.viewModel.pageType === detail.$.type) {
                          dispatch(layoutActions.setOptionName({optionType: 'opPrint', name: targetTemplateGroupData.$.name, pageType: detail.$.type}))
                        }
                      })
                      if (alertResultPath.length) {
                        const checkList = alertResultPath.find(v => v.pageType === detail.$.type);
                        if (checkList) {
                          checkList.pathData.push(detail.$.path);
                        } else {
                          alertResultPath.push({ pageType: detail.$.type, pathData: [detail.$.path] });
                        }
                      } else {
                        alertResultPath.push({ pageType: detail.$.type, pathData: [detail.$.path] });
                      }
                    }
                  });
                });
                const targetPageType = typeArr.find((v) => v === targetTemplateGroupData.templateGroup[0].templateData[0].$.type);
                if (targetTemplateGroupData && targetPageType) {
                  const data = createFolderList(vv, v.hpsTppath);
                  return Object.assign(data, { pageType: targetPageType });
                }
              }
            })
              // cover/top/page/endなどの表示順の調整
              .filter((v: LayoutTemplateFolderData) => v)
              .sort((a: LayoutTemplateFolderData, b: LayoutTemplateFolderData) => typeArr.indexOf(a.pageType) - typeArr.indexOf(b.pageType));

            // このフォルダのtemplates.xmlを保持しておく
            templatesArr.push(...arr);
            // トップ階層からのフォルダの作成
            const top = result.find((v) => v.id === topName[0]);
            if (!topName.length) return;
            if (top) {
              const data = createTopFolder(topName, 1, children);
              top.children.push(data);
            } else {
              const data = createTopFolder(topName, 0, children);
              result.push(data);
            }
          }
          onLoad();
          dispatch(layoutActions.setTemplatesXmlList(templatesArr));
          setTemplates(templatesArr);
          dispatch(layoutActions.setFolderList(result));
          dispatch(layoutActions.setTemplateGroupName(mostTopList[0]));
          dispatch(layoutActions.setAlertPathList(alertResultPath));
        },
      }));
    }
  }, [folderList, layoutQuery, templatesXmlArr, count, pageTypeIdArr, onLoad, albumPages]);

  // - State -
  const [treeIconWidth, setTreeIconWidth] = useState(0);

  return (
    <div className="layout_all_template layout_category">
      <div className="layout_category__header">全テンプレート</div>
      <div className="layout_category__body tree_view">
        {folderList.map((v, i) => v.type === 'directory' ? (
          <TemplateFolder
            key={`all-template-folder_${v.id}_${i}`}
            folderData={v}
            setTreeIconWidth={setTreeIconWidth}
            noChildIndent={treeIconWidth}
            selectFolder={selectFolder}
            pageCount={albumSettingData.pageCount}
            templatesXmlArr={templates}
          />) : (
          <React.Fragment key={`template-folder_${v.id}_${i}`}></React.Fragment>
        ))}
      </div>
    </div>
  );
};

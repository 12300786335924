import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { RoutingPath } from './routing-path';
import { Login } from '../components/pages/login/login';
import { CreateOrder } from '../components/pages/create-order/create-order';
import { Layout } from '../components/pages/layout/layout';
import { OrderList } from '../components/pages/order-list/order-list';
import { OrderPreparation } from '../components/pages/order-preparation/order-preparation';
import { PrintOrderImage } from '../components/pages/print-order-image/print-order-image';
import { Config } from '../components/pages/config/config';
import { DebugDialog } from '../components/debug/debug-dialog';
import { DebugPage } from '../components/debug/debug-page';
import { DebugUi } from '../components/debug/debug-ui';
import { Retouch } from '../components/pages/retouch/retouch';
import { UploadManager } from '../components/pages/upload-manager/upload-manager';
import { DebugXml } from '../components/debug/debug-xml';
import { StaffList } from '../components/pages/staff/staff-list';
import { StaffMngPasswordChangeWizard } from '../components/pages/wizard/staff-mng-password-change/staff-mng-password-change.wizard';
import { StaffMngMailChangeWizard } from '../components/pages/wizard/staff-mng-mail-change/staff-mng-mail-change.wizard';
import { StaffPasswordResetWizard } from '../components/pages/wizard/staff-password-reset/staff-password-reset.wizard';
import { AdminPasswordResetWizard } from '../components/pages/wizard/admin-password-reset/admin-password-reset.wizard';
import { StaffLogin } from '../components/pages/login/staff-login';
import { StaffMngEnableWizard } from '../components/pages/wizard/staff-mng-enable/staff-mng-enalbe.wizard';
import {
    StaffPasswordRegisterWizard
} from '../components/pages/wizard/staff-password-register/staff-password-register.wizard';
import { useEffect } from 'react';
import { ShopListPage } from '../components/pages/shop-list/shop-list';
import { CFolderOrderList } from '../components/pages/c-folder-order-list/c-folder-order-list';
import { CloudFolder } from '../components/pages/cloud-folder/cloud-folder';
import { OneStopOrderPreparation } from '../components/pages/one-stop-order-preparation/order-preparation.one-stop';
import { CreateOneStopOrder } from '../components/pages/one-stop-create-order/create-order.one-stop';
export const Routes = () =>  {
    const location = useLocation();
    useEffect(() => {
        switch(location.pathname) {
            case RoutingPath.login :
                document.title = 'PPM クラウド';
                break;
            case RoutingPath.staffLogin :
                document.title = 'PPM スタッフログイン';
                break;
            default:
                break;
        }
    }, [location]);
    return (
      <Switch>
          <Route exact path={RoutingPath.login} component={Login} />
          <Route exact path={RoutingPath.staffLogin} component={StaffLogin} />
          <Route exact path={RoutingPath.ordersAdd} component={CreateOrder} />
          <Route exact path={RoutingPath.ordersEdit} component={CreateOrder} />
          <Route exact path={RoutingPath.oneStopOrdersAdd} component={CreateOneStopOrder} />
          <Route exact path={RoutingPath.ordersEdit} component={CreateOrder} />
          <Route exact path={RoutingPath.oneStopOrdersEdit} component={CreateOneStopOrder} />
          <Route exact path={RoutingPath.ordersLayout} component={Layout} />
          <Route exact path={RoutingPath.ordersRetouch} component={Retouch} />
          <Route exact path={RoutingPath.orders} component={OrderList} />
          <Route exact path={RoutingPath.shop} component={ShopListPage} />
          <Route exact path={RoutingPath.cFolderOrder} component={CFolderOrderList} />
          <Route exact path={RoutingPath.ordersPreparation} component={OrderPreparation} />
          <Route exact path={RoutingPath.oneStopOrdersPreparation} component={OneStopOrderPreparation} />
          <Route exact path={RoutingPath.ordersPrint} component={PrintOrderImage} />
          <Route exact path={RoutingPath.uploadManager} component={UploadManager} />
          <Route exact path={RoutingPath.configSystem} component={() => <Config type="system" />} />
          <Route exact path={RoutingPath.debugDialog} component={DebugDialog} />
          <Route exact path={RoutingPath.debug} component={DebugPage} />
          <Route exact path={RoutingPath.debugUi} component={DebugUi} />
          <Route exact path={RoutingPath.debugXml} component={DebugXml} />
          <Route exact path={RoutingPath.staff} component={StaffList} />
          <Route exact path={RoutingPath.cloudFolder} component={CloudFolder} />
          <Route exact path={RoutingPath.adminPasswordReset} component={AdminPasswordResetWizard} />
          <Route exact path={RoutingPath.staffPasswordReset} component={StaffPasswordResetWizard} />
          <Route exact path={RoutingPath.staffMngEnable} component={StaffMngEnableWizard} />
          <Route exact path={RoutingPath.staffMngMailChange} component={StaffMngMailChangeWizard} />
          <Route exact path={RoutingPath.staffMngPasswordChange} component={StaffMngPasswordChangeWizard} />
          <Route exact path={RoutingPath.staffPasswordRegister} component={StaffPasswordRegisterWizard} />
          <Redirect to={RoutingPath.login} />
      </Switch>
    )
};

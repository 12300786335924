import { UuidGenerator } from '../../../utilities/uuid-generator';
import { CjBitmap } from '../../model/cj-factory';
import { Orientation } from 'get-orientation/browser';

export type Exif = {
  createDate: Date,
  colorSpace: string,
  make: string,
  model: string,
  orientation?: Orientation,
};

type Props = {
  name: string,
  kijshopCd: string,
  shopOrderId: string,
  orderId: string | null,
  selectId: string, // order-select の selectID
  original: File | null,
  originalBase64?: string,
  thumbnail: File,
  thumbnailBase64: string,
  kind: string, // 完成画像:1, レイアウトサムネイル:2, ロゴ・テキスト画像:4, PNG画像:5, アップロード画像:6
  editable: CjBitmap,
  exif: Exif,
  height: string,
  width: string,
  hash?: string,
  realWidth: string,
  realHeight: string,
  cfImageId?: string,
};

export class EditableImage {

  public id: string;
  public photoAreaId: string;
  public name: string;
  public kijshopCd: string;
  public shopOrderId: string;
  public orderId: string | null;
  public path: string;
  public kind: string;
  public selectId: string;
  // - data -
  public original: File | null = null;
  public originalBase64?: string;
  public thumbnail: File;
  public thumbnailBase64: string;
  public editable: CjBitmap;
  public exif: Exif;
  public virtualHeight: string;
  public virtualWidth: string;
  public realWidth: string;
  public realHeight: string;
  public readonly originalWidth: string;
  public readonly originalHeight: string;
  public hash?: string;
  public useCount: number = 0;
  public isDelete: boolean = false
  public cfImageId?: string = undefined;
  // - flag -
  public flags = {
    uploading: false,
    uploaded: false,
    used: false,
    retouchCcd: false,
    retouchCcdPack: false,
  };

  constructor(props: Props) {
    this.id = props.cfImageId ?? `${UuidGenerator.create()}`;
    this.photoAreaId = '';
    this.path = '';
    this.selectId = '';
    // - initialize -
    this.name = props.name;
    this.kijshopCd = props.kijshopCd;
    this.shopOrderId = props.shopOrderId;
    this.orderId = props.orderId;
    this.original = props.original;
    this.originalBase64 = props.originalBase64 ?? '';
    this.thumbnail = props.thumbnail;
    this.thumbnailBase64 = props.thumbnailBase64;
    this.kind = props.kind;
    this.editable = props.editable;
    this.exif = props.exif;
    this.virtualHeight = props.height;
    this.virtualWidth = props.width;
    this.hash = props.hash;
    this.realWidth = props.realWidth;
    this.realHeight = props.realHeight;
    this.originalWidth = props.realWidth;
    this.originalHeight = props.realHeight;
    this.cfImageId = props.cfImageId;
  }
}

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Thumbnail } from '../thumbnail/thumbnail';
import './file-drop-zone.scss';
import { UiManager } from '../../../layout-editor/manager/ui/ui.manager';
import { EditableImage, Exif } from '../../../layout-editor/manager/image-edit/editable-image';
import { Image } from '../image/image';
import { useParams } from 'react-router-dom';
import { PathParams } from '../../../routes/routing-path';
import { EditableImageManager } from '../../../layout-editor/manager/editable-image/editable-image.manager';
import {
  RestoreEditableImageManager,
} from '../../../layout-editor/manager/restore-editable-image/restore-editable-image.manager';
import { UuidGenerator } from '../../../utilities/uuid-generator';
import { apiActions } from '../../../slices/api-slice';
import { useDispatch } from 'react-redux';
import { dialogActions } from '../../dialog/slice/dialog-slice';
import { orderPreparationActions } from '../../../slices/order-preparation-slice';
import { useAppSelector } from '../../../app/hooks';
import lodash from 'lodash';
import { xmlActions } from '../../../xml/slice/xml-slice';
import { OrderPageBreakXml } from '../../../xml/class/order/order-page-break-xml';
import { OrderInfoDataXml } from '../../../xml/class/order/order-info-data-xml';
import { SortBoundary } from '../sort-boundary/sort-boundary';
import { ApiMetaOrderGet, ApiMetaOrderPost, MetaOrderGetResponse } from '../../../api/front/meta/api-meta-order';
import { ResponseBase } from '../../../api/response-base';
import { ImagesGetResponse } from '../../../api/front/images/api-images';
import { LoadingPopup } from '../loading/loading-popup';
import { OrderSelectXml } from '../../../xml/class/order/order-select-xml';
import { Orientation } from 'get-orientation/browser';
import { Button } from '../button/button';
import { CFolderFileSelectDialog } from '../../dialog/unique/c-folder-file-select-dialog';
import { FileDetail } from '../../../api/front/cloud-folder/api-get-cloud-folder-file-detail';
import { ApiGetCloudFolderOrderResult, OrderResult } from '../../../api/front/cloud-folder/api-get-cloud-folder-order-result';

type FileDropZoneProps = {
  onGetTotalNum: (num: number) => void,
  orderId: string | null;
  orderInfoData: OrderInfoDataXml,
  pageBreak?: OrderPageBreakXml,
  ordering: boolean,
  maxPageCount: number,
  uploadImageList: ImagesGetResponse[],
};

type ThumbProps = {
  id?: string,
  src: string,
  label: string,
  selectId: string,
  exif?: Exif,
  uploadStatus?: { uploaded: boolean, uploading: boolean },
  pageBreakId: string,
  path: string,
  orientation: number,
}

type SortType = 'name:up' | 'name:down' | 'date:up' | 'date:down' | 'manual';

const sortList = (list: ThumbProps[], type: SortType) => {
  const arr = [...list];
  if (type === 'name:up' || type === 'name:down') {
    const sort = (a: ThumbProps, b: ThumbProps) => type === 'name:up' ? a.label > b.label : a.label < b.label;
    arr.sort((a, b) => sort(a, b) ? 1 : -1);
  }
  if (type === 'date:up' || type === 'date:down') {
    const sort = (a: ThumbProps, b: ThumbProps) => type === 'date:up' ? (a.exif?.createDate || '') > (b.exif?.createDate || '') : (a.exif?.createDate || '') < (b.exif?.createDate || '');
    arr.sort((a, b) => sort(a, b) ? 1 : -1);
  }
  return arr;
};

const pageBreakSort = (list: ThumbProps[], orderPageBreak?: OrderPageBreakXml) => {
  if (!orderPageBreak?.viewModel.data?.image) {
    return list;
  }
  const sortList: ThumbProps[] = [];
  const arr = [...list];
  (orderPageBreak?.xmlModel.lnwOrderPageBreak?.orderPageBreak?.[0]?.data?.[0]?.image?.[0]?.data || []).forEach((data) => {
  // orderPageBreak.viewModel.data.image.forEach((data) => {
    const index = arr.findIndex((v) => v.selectId === data.$.selectID);
    if (index !== -1) {
      arr[index].pageBreakId = data.$.id ?? index.toString()
      sortList.push(arr[index]);
    }
  });
  return [...sortList];
};

export const FileDropZone = (props: FileDropZoneProps) => {
  const dispatch = useDispatch();
  const {
    onGetTotalNum,
    pageBreak: _pageBreak,
    orderInfoData,
    ordering,
    orderId,
    maxPageCount,
    uploadImageList,
  } = props;
  const { kijshopCd, shopOrderId } = useParams<PathParams>();
  const [thumbPropsList, setThumbPropsList] = useState<ThumbProps[]>([]);
  const thumbsGap = '1rem';
  // - Params -
  // - State -
  const {
    selectedOrderId,
    selectedImageList,
    orderSelect,
    dragItem,
    selectedOrderIdArr,
    orientationList,
  } = useAppSelector((state) => ({
    selectedOrderId: state.orderPreparation.selectedOrderId,
    selectedImageList: state.orderPreparation.selectedImageList,
    orderSelect: state.xml[shopOrderId]?.orderSelect,
    dragItem: state.orderPreparation.dragItem,
    selectedOrderIdArr: state.orderPreparation.selectedOrderIdArr,
    orientationList: state.orderPreparation.orientationList,
  }), lodash.isEqual);
  const [isDragAccept, setIsDragAccept] = useState(false);
  const [isDragReject, setIsDragReject] = useState(false);
  const [sortType, setSortType] = useState<SortType>('name:up');
  const [thumbHeight, setThumbHeight] = useState(0);
  const [thumbsWrapMaxHeight, setThumbsWrapMaxHeight] = useState('auto');
  const [note, setNote] = useState('');
  const [checkChangeTemplateList, setCheckChangeTemplateList] = useState('');
  const [editableImageList, setEditableImageList] = useState<EditableImage[]>([]);
  const [pageBreak, setPageBreak] = useState(_pageBreak);
  const [pictureData, setPictureData] = useState('');
  const [uploadData, setUploadData] = useState<{ orderId: string | null, files: File[] }>({ orderId: null, files: [] });
  const [alignmentData, setAlignmentData] = useState<{orderId: string | null, files: (FileDetail & { file: File | null, orderId: string })[], restoreIdList: string[]}>({ orderId: null, files: [], restoreIdList: [] });
  const [init, setInit] = useState(true);
  const [loadInit, setLoadInit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isViewCloudFolder, setIsViewCloudFolder] = useState(false);
  useEffect(() => {
    setPageBreak(_pageBreak);
  }, [_pageBreak]);
  // - Ref -
  const thumbEle = useRef<HTMLDivElement>(null);
  // - Callback -
  const handleDragOver = useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsDragAccept(true);
  }, []);
  const handleDragLeave = useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsDragReject(false);
    setIsDragAccept(false);
  }, []);
  // -- ダイアログによるファイルアップロード時の注意書き表示 --
  const showUploadMessage = useCallback(() => {
    dispatch(dialogActions.pushMessage({
      title: '確認',
      message: ['レイアウトの順番は、画面に表示されている順番を参考にして作成されます。'],
      buttons: [
        {
          label: 'OK',
          callback: () => dispatch(dialogActions.pop()),
        },
      ],
    }));
  }, []);
  const showLoadingInit = useCallback(() => {
    if (!pageBreak?.viewModel.data?.image.length) {
      return;
    }
    dispatch(dialogActions.pushMessage({
      title: '確認',
      message: [
        '画像を読み込み中です。',
        `読み込みが完了するまでお待ちください（${editableImageList.filter((v) => v.thumbnailBase64).length}/${pageBreak.viewModel.data.image.length}）`,
      ],
      buttons: [
        {
          label: 'OK',
          callback: () => dispatch(dialogActions.pop()),
        },
      ],
    }));
  }, [editableImageList, pageBreak]);
  /* D&D から画像をアップロード */
  const handleDrop = useCallback((e: React.DragEvent<HTMLDivElement>, orderId: string | null) => {
    e.stopPropagation();
    e.preventDefault();
    if (!loadInit) {
      showLoadingInit();
      return;
    }
    dispatch(apiActions.addConnect({ id: 'connecting-file-drop' }));
    const files = Array.from(e.dataTransfer.files).filter((v) => /\.(jpe?g)$/i.test(v.name));
    setUploadData({ orderId, files });
    setIsDragReject(false);
    setIsDragAccept(false);
  }, [loadInit, showLoadingInit]);
  /* 画像を選択から画像をアップロード */
  const handleSelectFile = useCallback((e: React.ChangeEvent<HTMLInputElement>, orderId: string | null) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(apiActions.addConnect({ id: 'connecting-file-drop' }));
    const files = (e.target.files || []) as File[];
    setUploadData({ orderId, files });
  }, []);
  /* pageBreak と editableImage を元にリストを生成 */
  const onChangeThumbPropsList = useCallback(() => {
    const arr = [
      ...thumbPropsList,
    ];
    editableImageList.forEach((e) => {
      const data = thumbPropsList.find((v) => v.id === e.id);
      if (data) {
        data.path = e.path || data.path;
        data.src = e.thumbnailBase64 || data.src;
        data.label = e.name || data.label;
        data.exif = e.exif || data.exif;
        data.selectId = e.selectId || data.selectId;
        data.orientation = e.exif.orientation || data.orientation;
      } else {
        arr.push({
          id: e.id,
          src: e.thumbnailBase64,
          label: e.name,
          exif: e.exif,
          uploadStatus: { uploaded: e.flags.uploaded, uploading: e.flags.uploading },
          selectId: e.selectId,
          path: e.path,
        } as typeof arr[0]);
      }
    });
    const pageBreakThumbArr = [...(pageBreak?.xmlModel.lnwOrderPageBreak?.orderPageBreak?.[0]?.data?.[0]?.image?.[0]?.data || [])].map((pageBreakData) => {
      const v = arr.find((data) => data.uploadStatus?.uploaded && data.selectId === pageBreakData.$.selectID) || arr.find((data) => data.selectId === pageBreakData.$.selectID);
      let id = v?.id || '';
      let src = v?.src || '';
      let path = v?.path || '';
      let uploadStatus = v?.uploadStatus || { uploaded: false, uploading: false };
      /* editableImageList の更新が前後するので src が見つからなかったのは直接 editableImage を探しに行く */
      if (pageBreakData.$.selectID && !v?.src) {
        const img = EditableImageManager.ins.list.find((v) => v.shopOrderId === shopOrderId && v.selectId === pageBreakData.$.selectID);
        if (img) {
          id = id || img.id;
          src = src || img.thumbnailBase64;
          path = path || img.path;
          uploadStatus.uploaded = v?.uploadStatus?.uploaded || img.flags.uploaded;
          uploadStatus.uploading = v?.uploadStatus?.uploading || img.flags.uploading;
        }
      }
      const orderSelectData = orderSelect?.metaModel.imageData?.find((imageData) => imageData.selectID === pageBreakData.$.selectID);
      const fileTime = orderSelectData?.originalFileName?.real?.originalFileTime;
      const date = fileTime && new Date(Number(fileTime.substr(0, 16)));
      const exif = v?.exif || {
        createDate: date,
      } as Exif;
      return ({
        id: v?.id || id,
        src: v?.src || src,
        label: v?.label || pageBreakData.fileName?.[0]?.$.path || orderSelectData?.originalFileName?.real?.path || '',
        exif,
        uploadStatus: {
          uploaded: v?.uploadStatus?.uploaded || uploadStatus.uploaded || false,
          uploading: v?.uploadStatus?.uploading || uploadStatus.uploading || false,
        },
        selectId: v?.selectId || pageBreakData.$.selectID || '',
        pageBreakId: pageBreakData.$.id || '',
        path: v?.path || path,
        orientation: v?.orientation || 0,
      });
    });
    editableImageList.forEach((v) => {
      if (v.flags.uploading || !v.selectId) {
        pageBreakThumbArr.push({
          id: v?.id || '',
          src: v?.thumbnailBase64 || '',
          label: v?.name || '',
          exif: v?.exif,
          uploadStatus: { uploaded: v?.flags?.uploaded || false, uploading: v?.flags?.uploading || false },
          selectId: v?.selectId || '',
          pageBreakId: '',
          path: v?.path || '',
          orientation: v?.exif.orientation || 0,
        });
      }
    });
    pageBreakThumbArr.forEach((v) => {
      const select = orderSelect?.metaModel.imageData?.find((image) => image.selectID === v.selectId);
      if (
        select?.originalFileName?.real?.path
        && (!select?.originalFileName?.real?.path.includes(v.selectId) && v.selectId.includes('-'))
      ) {
        v.label = select.originalFileName.real.path;
      }
    });
    setThumbPropsList(sortList(pageBreakThumbArr, sortType));
  }, [sortType, thumbPropsList, pageBreak, editableImageList, orderSelect]);
  // -- 並び順更新　--
  const handlerChangeSortType = useCallback((v) => {
    dispatch(orderPreparationActions.setDragItem({
      selectIndex: -1,
      targetIndex: -1,
    }));
    if (sortType === 'manual') {
      dispatch(dialogActions.pushMessage({
        title: '確認',
        message: ['手動で並び替えた情報はリセットされますが、よろしいですか？'],
        buttons: [
          {
            label: 'いいえ',
            callback: () => {
              dispatch(dialogActions.pop());
            },
          },
          {
            label: 'はい',
            callback: () => {
              dispatch(dialogActions.pop());
              setSortType(v);
              const list = sortList(thumbPropsList, v);
              setThumbPropsList(list);
              dispatch(xmlActions.uploadImage({
                kijshopCd,
                shopOrderId,
              }).select(orderId || '').changeListRank(list.map((data) => data.pageBreakId)));
              dispatch(apiActions.run(
                new ApiMetaOrderPost({
                  kijshopCd,
                  shopOrderId,
                  // orderId: order?.orderId,
                  // memo,
                  orderId: orderInfoData.indexes[0],
                  data: {
                    orderId: orderInfoData.indexes[0],
                    note,
                    checkChangeTemplateList,
                    imageSortType: v,
                    pictureData,
                  },
                }),
              ));
            },
          },
        ],
      }));
    } else {
      setSortType(v);
      if (v !== 'manual') {
        const list = sortList(thumbPropsList, v);
        setThumbPropsList(list);
        dispatch(xmlActions.uploadImage({
          kijshopCd,
          shopOrderId,
        }).select(orderId || '').changeListRank(list.map((data) => data.pageBreakId)));
      }
      dispatch(apiActions.run(
        new ApiMetaOrderPost({
          kijshopCd,
          shopOrderId,
          // orderId: order?.orderId,
          // memo,
          orderId: orderInfoData.indexes[0],
          data: {
            orderId: orderInfoData.indexes[0],
            note: note || '',
            checkChangeTemplateList,
            imageSortType: v,
            pictureData,
          },
        }),
      ));
    }
  }, [thumbPropsList, sortType, pictureData]);
  const deleteImage = useCallback((id: string) => {
    const idArr = selectedImageList.length ? selectedImageList : [id];
    dispatch(dialogActions.pushMessage({
      title: '確認',
      message: [
        '選択した画像を削除します。',
        'よろしいですか？',
      ],
      buttons: [
        {
          label: 'いいえ',
          callback: () => {
            dispatch(dialogActions.pop());
            dispatch(orderPreparationActions.setSelectedImageList({ orderId: orderId || '', selectID: idArr }));
          },
        },
        {
          label: 'はい',
          callback: () => {
            dispatch(dialogActions.pop());
            setIsDelete(true);
            dispatch(xmlActions.uploadImage({ kijshopCd, shopOrderId }).select(props.orderId || '').delete(
              idArr,
              {
                success: (_pageBreak) => {
                  const arr = [...thumbPropsList];
                  idArr.forEach((id) => {
                    const index = arr.findIndex((v) => v.pageBreakId === id);
                    if (index !== -1 && arr[index].id) {
                      EditableImageManager.ins.toUnUse(arr[index].id || '');
                      EditableImageManager.ins.delete('list', arr[index].id || '');
                      arr.splice(index, 1);
                    }
                  });
                  setThumbPropsList(pageBreakSort(arr, _pageBreak));
                  setIsDelete(false);
                },
                error: () => {
                  setIsDelete(false);
                  dispatch(dialogActions.pushMessage({
                    title: '確認',
                    message: ['写真の削除に失敗しました'],
                    buttons: [{
                      label: 'OK',
                      callback: () => {
                        dispatch(dialogActions.pop());
                      },
                    }],
                  }))
                },
              },
              orderInfoData.viewModel.goods?.productType === 'jptg300164' ? { list: thumbPropsList.map((v) => v.pageBreakId) } : undefined,
            ));
          },
        },
      ],
    }));
  }, [thumbPropsList, selectedImageList, pageBreak, orderId]);

  // 画像範囲選択
  const areaSelect = useCallback((id: string, index: number) => {
    if (ordering) {
      return;
    }
    const list = sortList(thumbPropsList, sortType);
    if (selectedImageList.length > 0) {
      const beforeSelectId = selectedImageList[0];
      const beforeObj = list.find((v) => v.pageBreakId === beforeSelectId);
      if (beforeObj) {
        const beforeIndex = list.indexOf(beforeObj);
        let arr: string[] = [];
        if (index < beforeIndex) {
          (list.slice(index, beforeIndex + 1)).forEach((v) => {
            if (v.pageBreakId && v.pageBreakId !== beforeSelectId) {
              arr.push(v.pageBreakId);
            }
          });
        } else {
          (list.slice(beforeIndex, index + 1)).forEach((v) => {
            if (v.pageBreakId && v.pageBreakId !== beforeSelectId) {
              arr.push(v.pageBreakId);
            }
          });
        }
        dispatch(orderPreparationActions.setSelectedImageList({
          orderId: orderId || '',
          selectID: [beforeSelectId, ...arr],
        }));
      }
    } else {
      let ary: string[] = [];
      (list.slice(0, index + 1)).forEach((v) => {
        if (v.pageBreakId) {
          ary.push(v.pageBreakId);
        }
      });
      dispatch(orderPreparationActions.setSelectedImageList({ orderId: orderId || '', selectID: ary }));
    }
  }, [ordering, selectedImageList, sortType, thumbPropsList, pageBreak, orderId]);
  const handlerUploaded = useCallback((editableImageList: EditableImage[]) => {
    setUploadData({ orderId: null, files: [] });
    setAlignmentData(({ orderId: null, files: [], restoreIdList: [] }));
    setEditableImageList((prev) => {
      const list = [...prev];
      editableImageList.forEach((v) => {
        const index = list.findIndex((e) => e.id === v.id);
        if (index === -1) {
          list.push(v);
        } else {
          const {
            thumbnailBase64,
            selectId,
            id,
          } = v;
          list[index].thumbnailBase64 = list[index].thumbnailBase64 || thumbnailBase64;
          list[index].selectId = list[index].selectId || selectId;
          list[index].id = list[index].id || id;
        }
      });
      return list;
    });
    if (!thumbPropsList.length && editableImageList.length) {
      // 初回ファイルアップロード時のみダイアログ表示
      showUploadMessage();
    }
  }, [thumbPropsList]);

  // - Effect -
  /* 画像枚数取得のコールバック */
  useEffect(() => {
    onGetTotalNum(thumbPropsList.length);
  }, [thumbPropsList]);
  /* editableImage 生成して使用してるものからリスト生成 */
  useEffect(() => {
    if (init) {
      return;
    }
    const orderId = props.orderId || '';
    const tmpHandler = (e: { list: EditableImage[] }) => {
      setEditableImageList((prev) => {
        const list = e.list.filter((v) => v.kijshopCd === kijshopCd && v.shopOrderId === shopOrderId && v.path && pageBreak?.viewModel.data?.image?.find((b) => b.selectID === v.selectId));
        if (pageBreak?.viewModel.data?.image.length === list.length) {
          UiManager.ins.off('l->r:change-editable-image-list', tmpHandler);
          setPageBreak((prev) => lodash.cloneDeep(prev));
          setLoadInit(true);
          return list;
        } else if (prev.length !== list.length) {
          setThumbPropsList((prev) => {
            const arr = [...prev];
            arr.forEach((v) => {
              if (!v.src) {
                const data = list.find((image) => image.selectId === v.selectId && image.thumbnailBase64);
                if (data) {
                  v.src = data.thumbnailBase64;
                  v.orientation = data.exif.orientation || 0;
                }
              }
            });
            return arr;
          });
          return list;
        }
        return prev;
      });
    };
    UiManager.ins.on('l->r:change-editable-image-list', tmpHandler);
    UiManager.ins.emit('l->r:post-editable-image-list', {
      callback: (e) => {
        // setEditableImageList(e.list.filter(EditableImageManager.filter(kijshopCd, shopOrderId, orderId)));
        // setPageBreak((prev) => lodash.cloneDeep(prev));
      },
    });
    if (orderId) {
      const id = UuidGenerator.create();
      dispatch(apiActions.addConnect({ id, name: 'RestoreEIM restoreAll()' }));
      if (orderSelect && pageBreak) {
        if (!pageBreak?.viewModel.data?.image?.length) {
          UiManager.ins.off('l->r:change-editable-image-list', tmpHandler);
          setLoadInit(true);
        }
        pageBreak?.viewModel.data?.image?.forEach((v) => {
          const data = orderSelect.metaModel.imageData?.find((image) => image.selectID === v.selectID);
          if (data?.selectFileName?.real?.path) {
            let path = '';
            uploadImageList.forEach((image) => {
              if (!path && image.selectId && image.selectId === data.selectID) {
                path = `${image.pathThumb}/${image.filename}`;
                dispatch(orderPreparationActions.setOrientationList({
                  selectId: v?.selectID || '',
                  orientation: image.exifOrientation,
                }));
              } else if (!path && `${image.path}/${image.filename}` === `${kijshopCd}/upload/${shopOrderId}/${data.selectFileName?.real.path}`) {
                path = `${image.pathThumb}/${image.filename}`;
                dispatch(orderPreparationActions.setOrientationList({
                  selectId: v?.selectID || '',
                  orientation: image.exifOrientation,
                }));
              }
            });
            path = path || (data.selectFileName.real.path.indexOf('Real/01/') === -1 ? `${kijshopCd}/upload/${shopOrderId}/${data.selectFileName.real.path}` : `${kijshopCd}/${shopOrderId}/${data.selectFileName.real.path}`);
            RestoreEditableImageManager.ins.restore({
              kijshopCd,
              shopOrderId,
              orderId,
              path,
              name: v.path,
              selectId: v.selectID,
            })
              .then(() => {
                dispatch(apiActions.removeConnect(id));
              });
          } else {
            dispatch(apiActions.removeConnect(id));
          }
        });
      } else {
        setLoadInit(true);
        UiManager.ins.off('l->r:change-editable-image-list', tmpHandler);
        dispatch(apiActions.removeConnect(id));
      }
    }
    return () => {
      UiManager.ins.off('l->r:change-editable-image-list', tmpHandler);
    };
  }, [kijshopCd, shopOrderId, props.orderId, init]);
  // -- サムネ群のラップ要素の最大高さ設定 --
  useEffect(() => {
    setThumbsWrapMaxHeight(`calc(${thumbHeight * 2}px + ${thumbsGap} + 5px)`);  // +5px スクロールしないところでバーが出る振る舞い阻止
  }, [thumbHeight]);
  const handlerChangeThumbHeight = useCallback((height, thumbHeight) => {
    if (height && height !== thumbHeight) {
      setThumbHeight(height);
    }
  }, []);

  // 画像複数選択 画像以外の部分をクリックで全ての選択を解除する処理
  const imgZoneRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    let listener: ((e: MouseEvent) => void) | null = null;
    if (imgZoneRef.current) {
      listener = (e: MouseEvent) => {
        if (imgZoneRef.current && !imgZoneRef.current.contains(e.target as HTMLElement)) {
          dispatch(orderPreparationActions.setSelectedImageList({ orderId: orderId || '', selectID: [] }));
        }
      };
      document.body.addEventListener('mousedown', listener);
    }
    return () => {
      if (listener) {
        document.removeEventListener('mousedown', listener);
      }
    };
  }, [imgZoneRef.current]);

  // 並び順変更
  const handlerChangeListIndex = useCallback((select: number, target: number) => {
    if (ordering || sortType !== 'manual' || !selectedImageList.length || selectedImageList.find((id) => thumbPropsList[target]?.pageBreakId === id) || orderId !== selectedOrderId) {
      return;
    }
    const arr = [...thumbPropsList];
    const targetData = arr[target];
    const selectDataArr: { index: number, data: ThumbProps }[] = [];
    selectedImageList.forEach((id) => {
      const index = arr.findIndex((v) => v.pageBreakId === id);
      if (index !== -1) {
        selectDataArr.push({ index, data: arr[index] });
        arr.splice(index, 1);
      }
    });
    const targetIndex = arr.findIndex((v) => v.pageBreakId === targetData.pageBreakId);
    const selectIdArr = [...selectDataArr].map((v) => v.data);
    if (select < target) {
      arr.splice(targetIndex + 1, 0, ...selectIdArr);
    } else {
      arr.splice(target, 0, ...selectIdArr);
    }
    setThumbPropsList(arr);
    dispatch(orderPreparationActions.setDragItem({
      selectIndex: -1,
      targetIndex: -1,
    }));
    const selectArr: string[] = [];
    arr.forEach((v, i) => {
      if (selectedImageList.find((id) => id === v.pageBreakId)) {
        selectArr.push(String(i + 1));
      }
    });
    dispatch(xmlActions.uploadImage({
      kijshopCd,
      shopOrderId,
    }).select(orderId || '').changeListRank(arr.map((data) => data.pageBreakId)));
    dispatch(orderPreparationActions.setSelectedImageList({ orderId: orderId || '', selectID: selectArr }));
  }, [ordering, thumbPropsList, selectedImageList, pageBreak, orderId]);


  const alignmentCloudFolder = useCallback((files: (FileDetail & { file: File | null, orderId: string })[], restoreIdList: string[]) => {
    dispatch(apiActions.addConnect({ id: 'connecting-file-drop' }));
    setAlignmentData({ files, orderId, restoreIdList });
  }, [kijshopCd, shopOrderId, orderId, dispatch]);

  const handlerClickCloudFolder = useCallback(() => {
    dispatch(dialogActions.push({
      title: 'クラウドフォルダ',
      element: <CFolderFileSelectDialog
        kijshopCd={kijshopCd}
        shopOrderId={shopOrderId || ''}
        orderId={orderId ?? ''}
        callback={(p) => alignmentCloudFolder(p.data, p.restoreData.map((v) => v.id))}
      />,
    }));
  }, [orderId, alignmentCloudFolder]);

  /* メタデータ取得 */
  useEffect(() => {
    if (orderId && !init) {
      dispatch(apiActions.run(
        new ApiMetaOrderGet({
          kijshopCd,
          shopOrderId,
          orderId: Number(orderId) < 10 ? `00${Number(orderId)}` : Number(orderId) < 100 ? `0${Number(orderId)}` : orderId,
        }),
        {
          onSuccess: (res: ResponseBase<MetaOrderGetResponse>) => {
            if (res?.body?.data) {
              if (res.body.data.imageSortType) {
                setSortType(res.body.data.imageSortType as SortType);
              }
              if (res.body.data.pictureData) {
                setPictureData(res.body.data.pictureData);
              }
              setNote(res.body.data.note || '');
              setCheckChangeTemplateList(res.body.data.checkChangeTemplateList);
            }
          },
        },
        {
          ignoreSystemError: true,
        },
      ));
    }
  }, [init]);

  /* pageBreak 更新時のリスト生成 */
  useEffect(() => {
    if (init) {
      return;
    }
    onChangeThumbPropsList();
  }, [pageBreak, init]);

  const [move, setMove] = useState(0);
  const [moveIntervalId, setMoveIntervalId] = useState<NodeJS.Timeout>();
  /* 自動スクロールの移動制御 */
  useEffect(() => {
    if (moveIntervalId!) clearInterval(moveIntervalId);
    if (move !== 0 && mouseDown) {
      const interval = setInterval(() => {
        imgZoneRef.current!.scrollTop = (imgZoneRef.current!.scrollTop + move * 3);
        setMoveIntervalId(interval);
      }, 1);
    }
  }, [move]);
  /* 自動スクロールのリスナー設定 */
  useEffect(() => {
    if (init) {
      return;
    }
    if (!imgZoneRef || !imgZoneRef.current) return;
    const listener = (e: MouseEvent) => {
      if (!imgZoneRef.current) {
        return;
      }
      const position = imgZoneRef.current.getBoundingClientRect();
      if ((e.y - position.y) > 0 && (e.y - position.y) < 30 && e.x > position.x && e.x < position.x + position.width) {
        setMove(-2);
      } else if ((e.y - position.y) >= 30 && (e.y - position.y) < 100 && e.x > position.x && e.x < position.x + position.width) {
        setMove(-1);
      } else if (position.height - (e.y - position.y) >= 30 && position.height - (e.y - position.y) < 100 && e.x > position.x && e.x < position.x + position.width) {
        setMove(1);
      } else if (position.height - (e.y - position.y) > 0 && position.height - (e.y - position.y) < 30 && e.x > position.x && e.x < position.x + position.width) {
        setMove(2);
      } else {
        setMove(0);
      }
    };
    document.body.addEventListener('mousemove', listener);
    return () => {
      document.body.removeEventListener('mousemove', listener);
    };
  }, [imgZoneRef, init]);
  const [mouseDown, setMouseDown] = useState(false);
  const [type, setType] = useState<string>('');
  /* 画像エリアのサイズ調整 */
  useEffect(() => {
    if (!thumbHeight && thumbEle.current && selectedOrderIdArr.find((v) => v === orderInfoData?.metaModel?.id)) {
      handlerChangeThumbHeight(thumbEle.current?.getBoundingClientRect().height, thumbHeight);
    } else if (!thumbHeight && !thumbEle.current) {
      setThumbPropsList((prev) => [...prev]);
    }
  }, [selectedOrderIdArr, thumbEle]);
  /* アンマウントしないようにコンポネントを最初に開いた場合を制御 */
  useEffect(() => {
    if (init && selectedOrderIdArr.find((v) => v === orderInfoData?.metaModel?.id)) {
      setInit(false);
    }
  }, [selectedOrderIdArr]);

    // クラウドフォルダ連携オーダーがあるか確認
    const getCloudFolderOrder = useCallback(() => {
      dispatch(apiActions.run(
        new ApiGetCloudFolderOrderResult({ kijshopCd }),
        {
          onSuccess: (res: ResponseBase<OrderResult[]>) => {
            setIsViewCloudFolder(!!res.body.data?.length);
          },
        },
      ));
    }, []);
    useEffect(() => {
      getCloudFolderOrder();
    }, []);


  return selectedOrderIdArr.find((v) => v === orderInfoData?.metaModel?.id) ? (
    <div className="file_drop_zone">
      <div className="file_drop_zone__contents">
        {/* エラーメッセージ */}
        <div
          className="file_drop_zone__error"
          style={{ display: thumbPropsList.length > maxPageCount ? undefined : 'none' }}
        >
          写真の枚数が上限を超えています
        </div>

        {/* ドロップエリア */}
        {!ordering ? (
          <div className="file_drop_zone__drop__wrap">
            <div
              className={`file_drop_zone__drop ${isDragAccept ? 'drag_accept' : ''} ${isDragReject ? 'drag_reject' : ''}`}
              onDragOver={handleDragOver}
              onDrop={(e) => handleDrop(e, orderId)}
              onDragLeave={handleDragLeave}
            >
              {/*<span>ここに注文する画像をドラッグ＆ドロップしてください。</span>*/}
              {loadInit || !pageBreak?.viewModel.data?.image.length ? (
                <span>ここに注文する画像をドラッグ＆ドロップしてください。</span>
              ) : (
                <span>画像を読み込み中です...
                  （{editableImageList.filter((v) => v.thumbnailBase64).length}/{pageBreak.viewModel.data.image.length}）</span>
              )}
              <div className={'file_drop_zone__drop__buttons'}>

                <label
                  htmlFor={`file-upload-input-${orderId}`}
                  className="button sm warning"
                >
                  画像を選択
                  <input
                    id={`file-upload-input-${orderId}`}
                    type="file"
                    accept="image/jpg, image/jpeg"
                    multiple
                    onClick={(e) => {
                      if (!loadInit) {
                        e.stopPropagation();
                        e.preventDefault();
                        showLoadingInit();
                        return;
                      }
                      e.currentTarget.value = '';
                    }}
                    onChange={(e) => handleSelectFile(e, orderId)}
                  />
                </label>

                {isViewCloudFolder && (
                  <label
                    className="button sm primary"
                    onClick={handlerClickCloudFolder}
                  >
                    クラウドフォルダ
                  </label>
                )}
              </div>
            </div>
          </div>
        ) : (<></>)}

        {/* アップロードした画像のリスト */}
        <div className="file_drop_zone__list">
          {/*{thumbPropsList.length > 1 ?*/}
          {!ordering ? (
            <div className="file_drop_zone__list__sort">
              <div className="file_drop_zone__list__sort__menu">
                <div
                  onClick={() => handlerChangeSortType('name:up')}
                  className={sortType === 'name:up' ? 'selected' : ''}
                >
                  ファイル名順↑
                </div>
                <div
                  onClick={() => handlerChangeSortType('name:down')}
                  className={sortType === 'name:down' ? 'selected' : ''}
                >
                  ファイル名順↓
                </div>
                <div
                  onClick={() => handlerChangeSortType('date:up')}
                  className={sortType === 'date:up' ? 'selected' : ''}
                >
                  撮影日時順↑
                </div>
                <div
                  onClick={() => handlerChangeSortType('date:down')}
                  className={sortType === 'date:down' ? 'selected' : ''}
                >
                  撮影日時順↓
                </div>
                <div
                  onClick={() => handlerChangeSortType('manual')}
                  className={sortType === 'manual' ? 'selected' : ''}
                >
                  手動並び替え
                </div>
              </div>
              {thumbPropsList.length > 0 ? (
                <div className="file_drop_zone__list__sort__guidance">
                  レイアウトは下記の並び順を参考に、ラボで作成されます。
                </div>
              ) : (<></>)}
            </div>
          ) : (<></>)}
          <div
            className="file_drop_zone__list__thumbs"
            style={{ gap: thumbsGap, maxHeight: thumbsWrapMaxHeight }}
            ref={imgZoneRef}
          >
            {thumbPropsList.map((v, i) => (
              <SortBoundary
                key={`order-thumbnail_${v.src}_${i}`}
                showSide={orderId === selectedOrderId ? ((sortType === 'manual' && i === dragItem.targetIndex) ? dragItem.selectIndex < dragItem.targetIndex ? 'right' : dragItem.selectIndex > dragItem.targetIndex ? 'left' : undefined : undefined) : undefined}
                offset={{ x: `calc(${dragItem.selectIndex < dragItem.targetIndex ? '' : '-'}1 * (0.5rem - 1px))` }}
                color={{ border: '#0000FF', background: '#0000FF' }}
              >
                <Thumbnail
                  key={`order-thumbnail_${v.src}_${i}`}
                  thumbEle={
                    v.src ? <Image
                      key={v.src}
                      src={v.src}
                      alt=""
                      containerAspectRatio={1.5}
                      onMouseDown={(e) => {
                        e.preventDefault();
                      }}
                      // style={i === dragItem.selectIndex ? { opacity: '0.35' } : {}}
                      style={i >= maxPageCount ? { opacity: 0.35 } : {}}
                      orientation={orientationList.find((o) => v.selectId && o.selectId === v.selectId)?.orientation}
                    /> : <></>
                  }
                  label={v.label}
                  id={v.pageBreakId}
                  ref={thumbEle}
                  uploadStatus={v.uploadStatus && {
                    uploading: v.uploadStatus.uploading,
                    uploaded: v.uploadStatus.uploaded,
                  }}
                  callbackDelete={() => deleteImage((v.pageBreakId!))}
                  index={i}
                  areaSelect={areaSelect}
                  callbackChangeIndex={handlerChangeListIndex}
                  callbackMount={i === 0 ? (height) => handlerChangeThumbHeight(height, thumbHeight) : undefined}
                  xmlParams={{
                    kijshopCd,
                    shopOrderId,
                    orderId: orderInfoData.metaModel.id || '',
                    outputCount: orderInfoData.viewModel.goods?.productType === 'jptg300164'
                    && orderInfoData.orderPageDataArr.length > i
                      ? Number(orderInfoData.orderPageDataArr[i]?.viewModel.pageOutputCount) : undefined,
                  }}
                  className={i >= maxPageCount ? 'alert' : ''}
                  type={type}
                  setType={setType}
                  mouseDownStatus={setMouseDown}
                />
              </SortBoundary>
            ))}
          </div>
        </div>

      </div>
      {uploadData.orderId && (uploadData.files.length ||  alignmentData.restoreIdList.length) ? (
        <Uploading
          files={uploadData.files}
          sortType={sortType}
          orderInfoData={orderInfoData}
          orderSelect={orderSelect}
          callback={handlerUploaded}
          kijshopCd={kijshopCd}
          shopOrderId={shopOrderId}
          orderId={uploadData.orderId}
          editableImageList={editableImageList}
        />
      ) : (<></>)}
      {alignmentData.orderId && (alignmentData.files.length || alignmentData.restoreIdList.length) ? (
        <CloudFolderAlignmenting
          files={alignmentData.files}
          restoreIdList={alignmentData.restoreIdList}
          sortType={sortType}
          orderInfoData={orderInfoData}
          callback={handlerUploaded}
          kijshopCd={kijshopCd}
          shopOrderId={shopOrderId}
          orderId={alignmentData.orderId}
          editableImageList={editableImageList}
        />
      ) : (<></>)}
      { isDelete && <LoadingPopup label={'写真を削除中です...'} /> }
    </div>
  ) : (<></>);
};

type UploadingProps = {
  files: File[],
  sortType: string,
  orderInfoData: OrderInfoDataXml,
  orderSelect: OrderSelectXml | undefined,
  editableImageList: EditableImage[],
  callback: (editableImageList: EditableImage[]) => void,
  kijshopCd: string,
  shopOrderId: string,
  orderId: string,
};

const updateSec = 1;
const margin = 2;

const Uploading = (props: UploadingProps) => {
  const {
    files,
    sortType,
    orderInfoData,
    orderSelect,
    editableImageList,
    callback,
    kijshopCd,
    shopOrderId,
    orderId,
  } = props;
  const dispatch = useDispatch();
  const [uploaded, setUploaded] = useState(0);
  const [time, setTime] = useState(0);
  useEffect(() => {
    const editableImageArr: EditableImage[] = [];
    let exifFlg = false;
    const interval = setInterval(() => {
      setTime((prev) => prev + updateSec);
    }, updateSec * 1000);
    UiManager.ins.emit(
      'r->l:add-image',
      {
        files: Array.from(files),
        kijshopCd,
        shopOrderId,
        orderId: orderId,
        onUploaded: (e) => {
          if (!exifFlg && String(e.editableImage.exif.colorSpace) !== '1') {
            exifFlg = true;
          }
          setUploaded((prev) => prev + 1);
          editableImageArr.push(e.editableImage);
          if (editableImageArr.length === files.length) {
            clearInterval(interval);
            const sort: { type: 'name' | 'date' | 'free', case?: 'asc' | 'desc' } =
              sortType === 'name:up' ? { type: 'name', case: 'asc' } :
                sortType === 'name:down' ? { type: 'name', case: 'desc' } :
                  sortType === 'date:up' ? { type: 'date', case: 'asc' } :
                    sortType === 'date:down' ? { type: 'date', case: 'desc' } : { type: 'free' };
            if (exifFlg) {
              dispatch(dialogActions.pushMessage({
                title: '確認',
                message: !e.editableImage.exif.colorSpace ? [
                  'Exifヘッダ情報に色空間の情報がありません。',
                  '色空間がsRGBでない場合、色が変わる可能性があります。',
                ] : [
                  'sRGB以外の色空間が指定されています。',
                  '色空間がsRGBでない場合、色が変わる可能性があります。',
                ],
                buttons: [{
                  label: 'OK',
                  callback: () => {
                    dispatch(dialogActions.pop());
                  },
                }],
              }));
            }
            dispatch(
              xmlActions
                .uploadImage({ kijshopCd, shopOrderId })
                .select(orderId || '')
                .add(
                  editableImageArr,
                  orderInfoData.viewModel.goods?.productType === 'jptg300164',
                  sort,
                  (editableImageList) => {
                    callback(editableImageList);
                    editableImageList.forEach((v) => {
                      if (v.exif.orientation) {
                        dispatch(orderPreparationActions.setOrientationList({
                          selectId: v.selectId,
                          orientation: v.exif.orientation,
                        }));
                      }
                    });
                  },
                ),
            );
          }
          EditableImageManager.ins.toUse(e.editableImage.id);
          if (e.index + 1 === files.length) {
            dispatch(apiActions.removeConnect('connecting-file-drop'));
          }
        },
        selectIdList: [...(orderSelect?.metaModel?.imageData || [])].map((v) => ({
          path: v.selectFileName?.real.path || '',
          selectID: v.selectID || '',
        })),
      },
    );
  }, []);
  const leastSec = LeastSec({ time, uploaded, filesLen: files.length });
  return (
    <UpdatePopup
      label={`アップロード中（${uploaded}/${files.length}）`}
      least={time >= updateSec + margin && uploaded ? `完了までの推定時間\u3000残り...${leastSec || 99}秒` : ''}
    />
  );
};

type CloudFolderAlignmentingProps = {
  files: (FileDetail & { file: File | null, orderId: string })[],
  restoreIdList: string[],
  sortType: string,
  orderInfoData: OrderInfoDataXml,
  callback: (editableImageList: EditableImage[]) => void,
  editableImageList: EditableImage[],
  kijshopCd: string,
  shopOrderId: string,
  orderId: string,
};

const cfUpdateSec = 1;
const cfMargin = 2;

const CloudFolderAlignmenting = (props: CloudFolderAlignmentingProps) => {
  const {
    files,
    sortType,
    orderInfoData,
    callback,
    kijshopCd,
    shopOrderId,
    editableImageList,
    orderId,
    restoreIdList,
  } = props;
  const dispatch = useDispatch();
  const [uploaded, setUploaded] = useState(0);
  const [time, setTime] = useState(0);
  useEffect(() => {
    const editableImageArr: EditableImage[] = [];
    const interval = setInterval(() => {
      setTime((prev) => prev + cfUpdateSec);
    }, cfUpdateSec * 1000);
    UiManager.ins.emit(
      'r->l:add-image:cloud-folder',
      {
        files: files,
        kijshopCd,
        shopOrderId,
        orderId: orderId,
        onUploaded: (e) => {
          setUploaded((prev) => prev + 1);
          editableImageArr.push(e.editableImage);
          if (editableImageArr.length === files.length) {
            clearInterval(interval);
            const sort: { type: 'name' | 'date' | 'free', case?: 'asc' | 'desc' } =
              sortType === 'name:up' ? { type: 'name', case: 'asc' } :
                sortType === 'name:down' ? { type: 'name', case: 'desc' } :
                  sortType === 'date:up' ? { type: 'date', case: 'asc' } :
                    sortType === 'date:down' ? { type: 'date', case: 'desc' } : { type: 'free' };
            dispatch(
              xmlActions
                .uploadImage({ kijshopCd, shopOrderId })
                .select(orderId || '')
                .add(
                  editableImageArr,
                  orderInfoData.viewModel.goods?.productType === 'jptg300164',
                  sort,
                  (editableImageList) => {
                    callback(editableImageList);
                    editableImageList.forEach((v) => {
                      if (v.exif.orientation) {
                        dispatch(orderPreparationActions.setOrientationList({
                          selectId: v.selectId,
                          orientation: v.exif.orientation,
                        }));
                      }
                    });
                  },
                ),
            );
          }
          EditableImageManager.ins.toUse(e.editableImage.id);
          if (e.index + 1 === files.length) {
            dispatch(apiActions.removeConnect('connecting-file-drop'));
          }
        },
      },
    );
  }, []);
  const leastSec = LeastSec({ time, uploaded, filesLen: files.length });
  return (
    <UpdatePopup
      label={`連携中（${uploaded}/${files.length}）`}
      least={time >= cfUpdateSec + cfMargin && uploaded ? `完了までの推定時間\u3000残り...${leastSec || 99}秒` : ''}
    />
  );
};

const UpdatePopup = (props: { label: string, least: string }) => {
  const { label, least } = props;

  return (
    <div className="loading_popup__dimmer">
      <div
        className="loading_popup"
        style={{ width: '24rem', height: '12rem' }}
      >
        <span
          className="loading_popup__message"
          style={{ fontSize: '1.3rem' }}
        >
          {label}
        </span>
        <div style={{ transform: 'scale(0.7)' }}>
          <div
            className="loading_popup__dots"
            style={{ fontSize: '0.7rem', animationDuration: `${0.8}s`, WebkitAnimationDuration: `${0.8}s` }}
          />
        </div>
        {least}
      </div>
    </div>
  );
};
const LeastSec = (props: { time: number, uploaded: number, filesLen: number }) => {
  const { time, uploaded, filesLen } = props;
  const [least, setLeast] = useState(0);
  useEffect(() => {
    const par = uploaded / time;
    const least = filesLen - uploaded;
    setLeast(Math.ceil(least / par));
  }, [time]);
  return least;
};

FileDropZone.defaultProps = {
  orderId: null,
};

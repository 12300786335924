import { XmlClass } from './xml-class';
import { IShop } from '../interface/i-shop';
import { XmlClassName } from '../model/xml-class-name';
import { TShopMetaModel } from '../model/shop/xml-shop-model';

type TMeta = Partial<TShopMetaModel>;

export class ShopXml extends XmlClass<IShop, {}, TMeta> {

  name: XmlClassName = 'shop';

  metaModel: TMeta = {};
  viewModel: {} = {};

  protected init(): void {
    this.xmlUniqueName = this.xmlUniqueName || `PPM_${this.shopOrderId}_11_11_shop.xml`;
    this.xmlParentName = this.xmlParentName || `PPM_${this.shopOrderId}_11_11.xml`;
    this.rootTagModel = {
      ...this.rootTagModel,
      parentXPath: '/lnwOrder/order/shop',
      parentPath: this.xmlParentName,
    };
    this.docking({}, {});
  }

  protected docking(v: {}, m: TMeta): void {
    this.xmlModel = {
      lnwShop: {
        $: { ...this.rootTagModel },
        shop: [
          {
            $: {
              count: '1',
            },
            data: [
              {
                $: {
                  id: '1',
                  // 店舗コード
                  shopCode: m.shopCode,
                  mailTextForCloudFolder: m.mailTextForCloudFolder,
                },
              },
            ],
          },
        ],
      },
    };
  }

  protected split(x: IShop): void {
    if (!x?.lnwShop?.shop || !x.lnwShop.$) {
      this.metaModel = {};
      return;
    }
    this.metaModel = {
      shopCode: x.lnwShop.shop?.[0]?.data?.[0]?.$.shopCode,
      mailTextForCloudFolder: x.lnwShop.shop?.[0]?.data?.[0].$.mailTextForCloudFolder,
    };
    this.rootTagModel = Object.assign(this.rootTagModel, x.lnwShop.$);
  }
}

import { ApiBaseFront } from "../../front/api-base-front";

type Params = {
  kijshopCd: string,
  kind: 1 | 2,
};

export type CfOrderGetOneDataEntity = {
  /** 注文番号 */
  id:	string,
  /** 店舗番号 */
  kijshopCd:	string,
  /** 種別 */
  type:	string,
  /** 注文名 */
  name:	string,
  /** ステータス */
  status:	number,
  /** ステータス名 */
  statusName:	string,
  /** 目的 */
  purpose:	number,
  /** 目的名 */
  purposeName:	string,
  /** PPM使用フラグ */
  isUsePpm:	number,
  /** 依頼フラグ */
  isSubmitted:	number,
  /** 作業ステータス */
  workStatus:	number,
  /** 作業者ID */
  workerId:	string,
  /** 作業者名 */
  workerName:	string,
  /** 納品フラグ */
  isDelivery:	number,
  /** 納品日 */
  deliveredDate:	string,
  /** 備考 */
  remarks:	string,
  /** 注文ファイル合計サイズ(1注文に対して10GB制限) */
  totalSize:	number,
  /** 差し戻し回数 */
  remandCount:	number,
  /** アップロード日付 */
  uploadedAt:	string,
  /** ダウンロード期限 */
  downloadExpiredAt:	string,
  /** アプリ内ローカルパス */
  localPath:	string,
  /** アップロードユーザーID */
  uploadUserId:	string,
  /** アップロードユーザー名 */
  uploadUserName:	string,
  /** セレクト希望枚数 */
  selectedNum:	number,
  /** プラス10枚まで許容 */
  isPermissible:	number,
  /** 最終更新者ID */
  lastChangerId:	string,
  /** 最終更新者名 */
  lastChangerName:	string,
  /** ラボ最終更新者ID */
  lastLaboChangerId:	string,
  /** ラボ最終更新者名 */
  lastLaboChangerName:	string,
  /** 画像枚数(1注文あたり) */
  imageNum:	number,
  /** ファイル枚数(1注文あたり) */
  fileNum:	number,
  /** 得意先名 */
  shopName:	string,
  /** 入荷日予定日 */
  arrivalDate:	string,
  /** 納品日予定日 */
  deliveryDate:	string,
  /** 1次セレクト結果のDL */
  isSelectedDownload:	number,
  /** 商品使用画像 */
  useImageKind:	number,
  /** 色補正 */
  isColorCorrection:	number,
  /** 1次カリングのレタッチオプション */
  cullingRetouchOptId:	string,
  /** 商品に使用する画像のレタッチオプション */
  useImageRetouchOptId:	string,
  /** セレクト希望枚数下限(DL希望枚数) */
  minSelectedNum:	number,
  /** セレクト希望枚数上限(DL希望枚数) */
  maxSelectedNum:	number,
  /** 商品に使用する画像枚数下限 */
  minUseImageNum:	number,
  /** 商品に使用する画像枚数上限 */
  maxUseImageNum:	number,
  /** 元ファイルフォルダID */
  uploadFolderId:	string,
  /** 結果フォルダID */
  resultFolderId:	string,
  /** アップロードユーザー(カメラマンのID) */
  uploadUserIds:	string[],
  /** カメラマンアップロード情報 */
  uploadUsersInfo:	{
    /** フォルダID(uuid) */
    folderId:string,
    /** カメラマンID(uuid) */
    uploadUserId:string,
    /** アップロード完了フラグ */
    isUploadComplete:number,
    /** 再アップロード完了フラグ */
    isReUploadComplete:number,
    /** 画像(カメラマンがアップロードした)枚数 */
    imageNum:number,
    /** ファイル(カメラマンがアップロードした)数 */
    fileNum:number,
    /** ローカルパス(カメラマンがアップロードした) */
    localPath:string,
  }[]
  /** 結果画像枚数(1注文あたり) */
  resultImageNum:	number,
  /** 結果ファイル枚数(1注文あたり) */
  resultFileNum:	number,
  /** 納入枚数上限 */
  resultImageMax:	number,
  /** 納入ファイル数上限 */
  resultFileMax:	number,
  /** 納入枚数下限 */
  resultImageMin:	number,
  /** 納入ファイル数下限 */
  resultFileMin:	number,
  /** 作成日 */
  createdAt:	string,
  /** 更新日 */
  updatedAt:	string,
  shopOrderId: string,
}

export class ApiCfOrderGetList extends ApiBaseFront<CfOrderGetOneDataEntity[]> {
  constructor(param: Params) {
    const {
      kijshopCd,
    } = param;

    super(
      'GET',
      `/api/v1/c-folder/shop/${kijshopCd}/order`,
      'JSON',
      {
        kind: param.kind
      },
    );
  }
}

import { ApiBaseFront } from '../api-base-front';

type ParamType = {
  kijshopCd: string;
  orderId: string,
  ppmRemarks: {
    name: string,
    body: string,
  }[],
}

export class ApiUpdateCloudFolderPPMRemarks extends ApiBaseFront {
  constructor(_param: ParamType) {
    const { kijshopCd, orderId, ...param } = _param;
    super(
      'POST',
      `/api/v1/c-folder/shop/${kijshopCd}/order/${orderId}/ppmRemarks`,
      'JSON',
      param,
    );
  }
}

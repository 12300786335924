import {ApiBaseBack} from "../api-base-back";

export type SimpleRetouchInfo = {
  retouchProductNameId: string,
  retouchProductName: string,
  productNameId: string,
  productName: string,
  productShortName: string,
  itemOptpriceId: string,
  itemOptpriceName: string,
  startDate: string,
  endDate: string,
};
export type RetouchGetResponse = {
  productNameId: string,
  productName: string,
  productShortName: string,
  itemId: string,
  itemName: string,
  productTypeId: string,
  startDate: string,
  endDate: string,
  simpleRetouchInfo: SimpleRetouchInfo[],
}[];

export type RetouchKind = 'simpleRetouchName' |
'cullingRetouchName' |
'selectRetouchName';

type Param = {
  progFlg?: RetouchKind;
}

export class ApiRetouchGet extends ApiBaseBack {
  constructor(param: Param) {
    super(
      'GET',
      '/api/v1/retouch',
      'JSON',
      param,
    );
  }
}

import { ImagesGetResponse } from "../api/front/images/api-images";
import { XmlStructureModel } from "../xml/model/xml-structure-model"
import * as lodash from 'lodash';

type ResultType = {
  structure: XmlStructureModel,
  pushFiles: {
    filename: string;
    body: string;
    is_create: '1' | '0',
  }[],
}

/** 2024/10/02
 * SelectID採番方法変更対応
 * 画像一覧を取得し、XML内に保存されているSelectIDと、画像一覧のレコードのidが合致する場合は
 * 各SelectIDに、バックエンド採番された画像一覧のレコードのselectIdを代入し、structureを返却する
 * 
 * 仕様変更前のXMLにも対応させなければいけないため 画像一覧のidとXMLのSelectIDで合致しない場合はエラー処理をせず
 * XMLに記載されたSelectIdをそのまま使用する
 * 
 * OrderPageBreak：おまかせ画像/セレクトからお任せ
 * OrderPageData.orderPicture: レイアウト レタッチ
 */
export const SelectIdFormatter = (xml: XmlStructureModel, getImages: () => Promise<ImagesGetResponse[]>) => {
  return new Promise<ResultType>(async(resolve, reject) => {
    const images = await getImages();
    const files: ResultType['pushFiles'] = [];
    const findTarget = (selectId: string | undefined | null) => {
      const target = images.find((v) => v.id === selectId);
      return target;
    }

    if (!Array.isArray(images)) {
      reject();
      return;
    };

    const structure = lodash.cloneDeep(xml);
    const orderInfo = structure.orderInfo;
    const orderSelect = structure.orderSelect;
    /* OrderSelectのSelectID割り当て */
    const selectImageList = orderSelect?.metaModel.imageData ?? [];
    for (const img of selectImageList) {
      const targetImg = findTarget(img.selectID);
      if (targetImg) {
        img.selectID = targetImg.selectId;
        img.selectPictureID = targetImg.selectId.slice(-5);
      }
    }
    orderSelect?.build();
    await orderSelect?.parse();
    const infoData = orderInfo?.infoData ?? [];
    for (const info of infoData) {
      // おまかせ画像/セレクトからお任せ
      const pageBreak = info.pageBreak;
      if (pageBreak) {
        for (const breakData of pageBreak.viewModel.data?.image ?? []) {
          const targetImg = findTarget(breakData.selectID);
          if (targetImg) {
            breakData.selectID = targetImg.selectId;
          }
        }
        pageBreak.build();
      }
      // レイアウト レタッチ
      const parts = info.parts?.partsData ?? [];
      for (const partsData of parts) {
        console.log('parts:', partsData);
        const pageData = partsData.page?.pageData ?? [];
        for (const page of pageData) {
          const orderPicture = page.viewModel.orderPicture?.data ?? [];
          for (const pic of orderPicture) {
            const targetImg = findTarget(pic.selectID);
            if (targetImg) {
              pic.selectID = targetImg.selectId;
            }  
          }
          page.build();
          files.push({
            filename: page.xmlUniqueName,
            body: page.xml,
            is_create: '0',
          })
        }
      }
    }
    resolve({ structure, pushFiles: files });
  });
}
import { useCallback, useState } from "react"

export const useSelectChecker = (props?: { initial?: boolean, }) => {
  const {
    initial = true,
  } = props ?? {};
  const [checker, setChecker] = useState<boolean[] | []>(initial ? [true] : []);
  const onSelect = useCallback(() => {
    setChecker([true]);
  }, []);
  const onClear = useCallback(() => {
    setChecker([]);
  }, []);

  const getCheckState = useCallback(() => {
    return !!checker.length;
  }, [checker]);

  return {
    onSelect,
    onClear,
    getCheckState,
  }
}
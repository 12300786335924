import { Header } from '../../ui/header/header';
import { Sidebar } from '../../ui/sidebar/sidebar';
import { Button } from '../../ui/button/button';
import { Table } from '../../ui/table/table';
import { useDispatch } from 'react-redux';
import { Checkbox } from '../../ui/input/checkbox';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { push } from 'connected-react-router';
import { PathParams, RoutingPath } from '../../../routes/routing-path';
import { ImageUpload } from './image-upload';
import { useAppSelector } from '../../../app/hooks';
import { LogDecorator } from '@tenryu/log-decorator';
import './order-preparation.scss';
import { dialogActions } from '../../dialog/slice/dialog-slice';
import { AngleIcon } from '../../ui/angle-icon/angle-icon';
import * as lodash from 'lodash';
import { ColorIcon } from '../../ui/color-icon/color-icon';
import { apiActions } from '../../../slices/api-slice';
import { ResponseBase } from '../../../api/response-base';
import { orderPreparationActions } from '../../../slices/order-preparation-slice';
import { useParams } from 'react-router-dom';
import { EditableImageManager } from '../../../layout-editor/manager/editable-image/editable-image.manager';
import { xmlActions } from '../../../xml/slice/xml-slice';
import { orderLockActions } from '../../../slices/lock-order-slice';
import { DomContentTooltip } from '../../ui/tooltip/dom-content-tooltip';
import { XmlStructureOrderInfoData } from '../../../xml/model/xml-structure-model';
import { commonActions } from '../../../slices/common-slice';
import { LoadingPopup } from '../../ui/loading/loading-popup';
import { ImageProcessorManager } from '../../../layout-editor/manager/image-processor/image-processor.manager';
import {
  ApiMetaOrderDelete,
  ApiMetaOrderGet,
  ApiMetaOrderPost,
  MetaOrderGetResponse,
} from '../../../api/front/meta/api-meta-order';
import JSZip from 'jszip';
import { ApiAllGetXml, GetXmlResponse } from '../../../api/front/xml/api-all-get-xml';
import { ApiImagesGet, ImagesGetResponse } from '../../../api/front/images/api-images';
import { localStorageActions } from '../../../slices/local-storage-slice';
import { DateFormatter } from '../../../utilities/date-formatter';
import { ApiUploadXml } from '../../../api/front/xml/api-upload-xml';
import { ApiGetMetaShop } from '../../../api/front/system-config/api-meta-shop';
import { ApiMetaShopOrderPost } from '../../../api/front/meta/api-meta-shop-order';
import { NewPage } from '../../../utilities/new-page';
import { webPagePath } from '../../../models/web-page-path';
import {
  ApiShopOrderStatusListGet,
  ShopOrderListGetResponseStatus,
} from '../../../api/front/shop-order/api-shop-order-list';
import { isEqual } from 'lodash';
import warningIcon from '../../../assets/img/icon/warning_icon.svg'
import { ApiOrderRegister } from '../../../api/back/order-register/api-order-register';
import { LaboOrderCompChecker } from '../../../utilities/labo-order-comp-checker';
import { useCheckOneStop } from './hooks/use-check-one-stop';

type TableOptionDataType = {
  optionProduct?: {
    optionServiceId?: string | undefined;
    optionServiceName?: string | undefined;
    optionProductNameId?: string | undefined;
    optionProductName?: string | undefined;
    count: string,
    // sortNo?: string | undefined;
  }[],
  productName: string,
}

export const SelectCondition = (props: { selected: boolean }) => {
  const { selected } = props;
  return <AngleIcon direction={selected ? 'bottom' : 'top'} />;
};

const OrderStatus = (props: { status: string, kijshopCd: string, shopOrderId: string, orderId: string }) => {
  const { status: _status, kijshopCd, shopOrderId, orderId } = props;
  const { xml, isLaboOrderRetry } = useAppSelector((state) => ({
    xml: state.xml[shopOrderId],
    isLaboOrderRetry: state.orderPreparation.isLaboOrderRetry
  }), isEqual)
  const isError = useMemo<boolean>(() => {
    const orderInfo = xml?.orderInfo?.infoData?.find((infoData) => {
      return infoData.xml.metaModel.id === orderId;
    });
    if (!orderInfo) {
      return false;
    };
    if (orderInfo.xml.metaModel.layoutFinish === '3') {
      return true;
    }
    return false;
  }, [xml]);
  useCheckOneStop({ kijshopCd, shopOrderId });
  const [status, setStatus] = useState(_status);
  const [warningIconTooltip, setWarningIconTooltip] = useState(false);
  const warningIconRef = useRef(null);

  useEffect(() => {
    setStatus(_status);
  }, [_status]);
  return (
    <div className={`labeled_icon_hr${isLaboOrderRetry ? ' error' : ''}`} ref={warningIconRef} onMouseEnter={() => setWarningIconTooltip(true)} onMouseLeave={() => setWarningIconTooltip(false)} >
      {(isError || isLaboOrderRetry) && <img src={warningIcon} />}
      {warningIconTooltip && (isError || isLaboOrderRetry) && <DomContentTooltip
        onClick={(e) => {
          e.stopPropagation();
        }}
        content={
          isLaboOrderRetry ?
            (<div className={"order_preparation__warning-icon-tooltip"}>
              <div>発注処理が正常に完了しませんでした</div>
              <div>「再発注」ボタンをクリックしてください</div>
            </div>) :
            (<div className={"order_preparation__warning-icon-tooltip"}>
            <div>保存処理でエラーが発生しました</div>
            <div>レイアウト画面に戻ってエラー箇所を確認してください</div>
          </div>)
        }
        relativeEle={warningIconRef.current}
        autoPos={{ h: 'center', v: 'bottom' }}
      /> }
      {isLaboOrderRetry || <ColorIcon color={status} />}
      {status}
    </div>
  );
};
const NeedAgreement = (props: { agreement: string, kijshopCd: string, shopOrderId: string, orderId: string }) => {
  const { agreement: _agreement, kijshopCd, shopOrderId, orderId } = props;
  const dispatch = useDispatch();
  const [agreement, setAgreement] = useState(_agreement);
  const handlerClickCheck = useCallback((v) => {
    dispatch(xmlActions.order({ kijshopCd, shopOrderId }).changeIsNeedAgreement(orderId, v !== 'true'));
    setAgreement((v !== 'true') ? 'true' : 'false');
  }, [kijshopCd, shopOrderId, orderId]);
  useEffect(() => {
    if (agreement !== _agreement) {
      setAgreement(_agreement);
    }
  }, [_agreement]);
  return agreement ? (
    <div>
      <Checkbox
        value={agreement}
        label="有"
        checked={agreement === 'true'}
        onClick={(e) => handlerClickCheck(e.currentTarget.value)}
      />
    </div>
  ) : (<div>-</div>);
};

const TableOptionData = (props: TableOptionDataType) => {
  const { optionProduct, productName } = props;
  // - Ref -
  const optionDataEle = useRef<HTMLDivElement>(null);
  // - State -
  // -- オプションホバー時のツールチップ有無 --
  const [optionTooltip, setOptionTooltip] = useState(false);
  // - Memo -
  const relativeEle = useMemo(() => {
    return optionDataEle.current;
  }, [optionTooltip]);

  type HoverableEleProps = {
    label: string,
    isOptionProduct?: boolean,
  };
  const HoverableEle = (props: HoverableEleProps) => {
    const { label, isOptionProduct = true } = props;
    return (
      <div
        onMouseEnter={() => isOptionProduct && setOptionTooltip(true)}
        className="hoverable_ele"
      >{label}</div>
    );
  };
  return (
    <div
      ref={optionDataEle}
      onMouseLeave={() => setOptionTooltip(false)}
    >
      {(() => {
        let str = '';
        optionProduct?.map((v) => {
          if (str && v.optionProductName) {
            str += '/';
          }
          str += (v.optionProductName || '');
        });
        if (str.length > 20) {
          return <HoverableEle label={`${str.substr(0, 19)}…`} />;
        }
        return <HoverableEle
          label={str || '-'}
          isOptionProduct={(optionProduct && !optionProduct.length) || !!optionProduct}
        />;
      })()}
      {optionTooltip && optionProduct?.length ? <DomContentTooltip
        onClick={(e) => {
          e.stopPropagation();
        }}
        content={
          <>
            <table className="option_tooltip_content">
              <thead>
              <tr>
                <th>ラボ商品名</th>
                <th>数量</th>
              </tr>
              </thead>
              <tbody>
              {optionProduct?.map((v, i) => {
                return (
                  <tr
                    key={`option-product-name_${i}`}
                    className={i === 0 ? 'first_row' : ''}
                  >
                    <td>{v.optionProductName}</td>
                    <td>{v.count}</td>
                  </tr>
                );
              })}
              </tbody>
            </table>
          </>
        }
        relativeEle={relativeEle}
        autoPos={{ h: 'center', v: 'bottom' }}
      /> : <></>}
    </div>
  );
};

// 注文概要画面（元注文作成準備画面）
export const OrderPreparation = () => {
  // - Effect -
  // -- ログまとめ --
  useEffect(() => {
    LogDecorator.group('<green>注文作成準備</green>');
    return () => LogDecorator.groupEnd();
  }, []);
  const { kijshopCd, shopOrderId, orderId } = useParams<PathParams>();
  const {
    orderListRes,
    orderData,
    xml,
    xmlData,
    master,
    orderingShopId,
    orderedList,
    storage,
    noJudgeItem,
    loading,
    compImageList,
    imageLessOrder,
    isLaboOrderRetry,
  } = useAppSelector((state) => ({
    orderListRes: state.orderPreparation.orderList,
    orderData: state.orderPreparation.orderData,
    xml: state.xml[shopOrderId],
    master: state.common.data.masterShop,
    orderingShopId: state.common.orderingShopId,
    orderedList: state.common.orderedList?.order || [],
    xmlData: state.xml,
    noJudgeItem: state.common.data.masterProductNoJudgeItem,
    storage: state.storage,
    loading: Boolean(state.api.connectingIdArr.length > 0),
    compImageList: state.orderPreparation.compImageList,
    imageLessOrder: state.orderPreparation.imageLessOrder,
    isLaboOrderRetry: state.orderPreparation.isLaboOrderRetry,
  }), lodash.isEqual);
  // - Hooks -
  const dispatch = useDispatch();
  // - State -
  // -- テーブルprops select --
  const [selected, setSelected] = useState<number[]>([]);
  const [parentOrder, setParentOrder] = useState<XmlStructureOrderInfoData[]>([]);
  const [ordering, setOrdering] = useState(false);
  const [isOrdering, setIsOrdering] = useState(Boolean(orderingShopId && orderingShopId === shopOrderId));
  const [isMixOrderMethod, setIsMixOrderMethod] = useState(xml?.status?.isMixOrderMethod(xml));
  const [orderedStatusName, setOrderedStatusName] = useState('');
  const [uploadImageList, setUploadImageList] = useState<ImagesGetResponse[]>([]);
  // - Memo -
  const retouchDisabled = useMemo(() => {
    const layoutRetouch: string[] = ['20', '30'];
    const originInfos = xml?.orderInfo?.infoData ?? [];
    const originDatas = xml?.orderSelect?.metaModel?.imageData ?? [];

    const InfoIdDatas = originInfos.map((v) => {
      if (v.xml.viewModel.surrogateProcess?.id === '10') {
        return (v.pageBreak?.viewModel.data?.image.map((v2) => ({
          selectId: v2.selectID,
          orderId: v.xml.metaModel.id,
        })) ?? []);
      } else if (layoutRetouch.includes(v.xml.viewModel.surrogateProcess?.id ?? '')) {
        const resultPages = [];
        for (const pages of v.parts?.partsData ?? []) {
          for (const page of pages.page?.pageData ?? []) {
            const resultPage = page.viewModel?.orderPicture?.data?.map((v2) => ({
              selectId: v2.selectID,
              orderId: v.xml.metaModel.id,
            })) ?? [];
            resultPages.push(...resultPage);
          }
        }
        return resultPages;
      } else {
        return [];
      }
    }).filter((v) => v.length);

    return !(originDatas.map((v) => {
      const parentFind = InfoIdDatas.find((v2) => v2.find((v3) => v3.selectId === v.selectID));
      const find = parentFind?.find((v2) => v2.selectId === v.selectID);
      return find ? { data: v, orderId: find.orderId } : undefined;
    }).filter((v) => v).length);
  }, [xml]);
  // ラボ発注済みかの管理フラグ
  const isLaboOrdered = useRef(false);
  // ラボ発注の再発注を許諾するかの管理フラグ
  // const isLaboOrderRetry = useRef(false);
  // - Callback -
  // -- select更新 --
  const handlerChangeSelected = useCallback((v) => {
    const id = parentOrder[v]?.xml.metaModel.id;
    if (id) {
      dispatch(orderPreparationActions.setSelectedOrderId({ id, index: v + 1 + v }));
    }
    setSelected((prev) => {
      const arr = [...prev];
      const index = arr.findIndex((i) => i === v);
      if (index === -1) {
        arr.push(v);
      } else {
        arr.splice(index, 1);
      }
      arr.sort((a, b) => a - b);
      return arr;
    });
  }, [selected, parentOrder]);
  useEffect(() => {
    return () => {
      dispatch(orderPreparationActions.resetSelectedOrderId());
    };
  }, []);
  // -- 注文作成 --
  const handlerClickCreateOrder = useCallback(() => {
    if (isOrdering) {
      return;
    }
    dispatch(orderPreparationActions.setOrder(null));
    dispatch(push(RoutingPath.cnv.ordersAdd({ kijshopCd: kijshopCd, shopOrderId: shopOrderId })));
  }, [isOrdering]);
  // -- ラボ発注 --
  const checkLabOrder = (): boolean => {
    // TODO 暫定
    // return Boolean(xml?.status.getShopOrderStatus(xml) === 'ラボ発注可');
    return Boolean(parentOrder.length) && !parentOrder.find((v) => xml?.status.getOrderStatus(v.xml.metaModel.id || '', xml) !== 'ラボ発注可');
  };
  const handlerClickLabOrder = useCallback(() => {
    if (isLaboOrdered.current) {
      return;
    }
    if (
      xml?.delivery.xmlModel.lnwDelivery.delivery?.[0]?.data?.[0].$.type === 'customer'
      && (
        !xml.delivery.xmlModel.lnwDelivery.delivery?.[0]?.data?.[0]?.deliveryCustomerName?.[0]?.$.name
        || !xml.delivery.xmlModel.lnwDelivery.delivery?.[0]?.data?.[0]?.address?.[0]?.$.zipCode
        || !xml.delivery.xmlModel.lnwDelivery.delivery?.[0]?.data?.[0]?.address?.[0]?.$.state
        || !xml.delivery.xmlModel.lnwDelivery.delivery?.[0]?.data?.[0]?.address?.[0]?.$.address1
        || !xml.delivery.xmlModel.lnwDelivery.delivery?.[0]?.data?.[0]?.phone?.[0]?.$.phoneNumber
        || !xml.delivery.xmlModel.lnwDelivery.delivery?.[0]?.data?.[0]?.deliveryTimeZone?.[0]?.$.timeZone
        || !xml.delivery.xmlModel.lnwDelivery.delivery?.[0]?.data?.[0]?.deliveryFromName?.[0]?.$.name
        || !xml.delivery.xmlModel.lnwDelivery.report?.[0]?.data?.[0]?.shipmentForm?.[0]?.$.name
      )
    ) {
      dispatch(dialogActions.pushMessage({
        title: '確認',
        message: [
          '配送先情報が登録されていません。',
          '配送先情報の登録後、再度発注を行なってください。',
        ],
        buttons: [{
          label: 'OK',
          callback: () => {
            dispatch(dialogActions.pop());
          },
        }],
      }));
      return;
    }
    const orderCheck = EditableImageManager.ins.orderCheck(kijshopCd, shopOrderId);
    // -- ラボ発注API処理 --
    const onRegister = (retryCount: number = 0) => {
        dispatch(xmlActions.shopOrder({ kijshopCd, shopOrderId }).labOrder({
          success: () => {
            setOrdering(false);
            dispatch(dialogActions.pushMessage({
              title: '確認',
              message: ['発注処理を行いました'],
              buttons: [
                {
                  label: 'OK',
                  callback: () => {
                    dispatch(dialogActions.pop());
                    dispatch(push(RoutingPath.cnv.orders({ kijshopCd })));
                  },
                },
              ],
            }));
          },
          error: (e) => {
            console.log('e: ', e);
            if (String(e?.code) === '406') {
              setOrdering(false);
              dispatch(dialogActions.pop());
              /* 注文番号重複 */
              dispatch(dialogActions.push({
                title: '注文番号重複エラー',
                element: <SameOrderError />,
              }));
            } else {
              if (retryCount < 3 && e?.code === 'irregular') {
                onRegister(retryCount + 1);
              } else {
                setOrdering(false);
                dispatch(dialogActions.pop());
                dispatch(dialogActions.pushMessage({
                  title: '確認',
                  // message: [/* Number(e?.code) === 406 ? '' : */((!isNaN(Number(e?.code)) && e?.message) || 'データの更新に失敗しました。')],
                  message: e?.message || ['データの更新に失敗しました。'],
                  buttons: [
                    {
                      label: 'OK',
                      callback: () => {
                        dispatch(dialogActions.pop());
                      },
                    },
                  ],
                }));
              }
            }
          },
        }, false, (isLaboOrderRetry || !!retryCount)));
    }
    if (orderCheck.check) {
      dispatch(dialogActions.pushMessage({
        title: '確認',
        message: ['発注処理を行います。よろしいですか？'],
        buttons: [
          {
            label: 'いいえ',
            callback: () => {
              dispatch(dialogActions.pop());
            },
          },
          {
            label: 'はい',
            callback: () => {
              setOrdering(true);
              dispatch(dialogActions.pop());
              onRegister()
            },
          },
        ],
      }));
    } else {
      dispatch(dialogActions.pushMessage({
        title: '確認',
        message: [
          'アップロードが完了していない画像があります。',
          'しばらく時間をあけて再実行してください。',
          // `( ${orderCheck.total - orderCheck.yet} / ${orderCheck.total} )`,
        ],
        buttons: [
          {
            label: 'はい',
            callback: () => {
              dispatch(dialogActions.pop());
            },
          },
        ],
      }));
    }
  }, [orderData, isOrdering, xml]);
  // -- レタッチ --
  const handlerClickRetouch = useCallback(() => {
    if (isOrdering) {
      return;
    }
    EditableImageManager.ins.clear('list');
    ImageProcessorManager.ins.dequeue();
    /* レタッチ可能商品精査 */
    const itemArr: string[] = [];
    xml?.orderInfo?.infoData?.forEach((orderInfoData) => {
      if (orderInfoData.xml.viewModel.item?.name) {
        if (noJudgeItem?.lnwMasterProductNoJudgeItem.category?.[0]?.data?.find((v) => v.$.id === orderInfoData.xml.viewModel.category?.id)) {
          itemArr.push(orderInfoData.xml.viewModel.item.name);
        } else if (noJudgeItem?.lnwMasterProductNoJudgeItem.goods?.[0]?.data?.find((v) => v.$.id === orderInfoData.xml.viewModel.item?.id)) {
          itemArr.push(orderInfoData.xml.viewModel.item.name);
        }
      }
    });
    if (itemArr.length) {
      dispatch(dialogActions.pushMessage({
        title: '確認',
        message: [
          '以下の商品にのみ使用されている画像はレタッチの対象外となるため、レタッチ時に表示されません。',
          ...itemArr.map((v) => `・${v}`),
        ],
        buttons: [{
          label: 'OK',
          callback: () => {
            dispatch(dialogActions.pop());
            dispatch(push(RoutingPath.cnv.ordersRetouch({ kijshopCd: kijshopCd, shopOrderId: shopOrderId })));
          },
        }],
      }));
    } else {
      dispatch(push(RoutingPath.cnv.ordersRetouch({ kijshopCd: kijshopCd, shopOrderId: shopOrderId })));
    }
  }, [xml, isOrdering, noJudgeItem]);

  // --  削除実行時、メタ情報を更新 --
  const orderIdList = parentOrder.map((v) => (v.xml._indexes[0]));
  const deleteAndChangeMeta = useCallback((id: string, num: number, list: string[]) => {
    const ary = list;
    const newAry = ary.slice(ary.indexOf(id) + 1);
    newAry.forEach((v, i) => {
      // オーダーとオプションの数分、IDを繰り上げる
      const minus = Number(v) - num;
      const newId = (): string => {
        if (minus < 10) {
          return `00${minus}`;
        } else if (minus >= 10 && minus < 100) {
          return `0${minus}`;
        } else {
          return minus.toString();
        }
      };
      // 移動させるメタ情報を取得。
      dispatch(apiActions.run(
        new ApiMetaOrderGet({
          kijshopCd: kijshopCd,
          shopOrderId: shopOrderId,
          orderId: v,
        }),
        {
          onSuccess: (res: ResponseBase<MetaOrderGetResponse>) => {
            const data = res.body.data;
            if (data) {
              // 元々あった場所にあるメタ情報を削除。
              dispatch(apiActions.run(
                new ApiMetaOrderDelete({
                  kijshopCd: kijshopCd,
                  shopOrderId: shopOrderId,
                  orderId: v,
                }),
                {
                  onSuccess: () => {
                    // 移送させる場所に移動させるメタ情報を登録。
                    dispatch(apiActions.run(
                      new ApiMetaOrderPost({
                        kijshopCd: kijshopCd,
                        shopOrderId: shopOrderId,
                        orderId: newId(),
                        data: {
                          orderId: newId(),
                          note: data.note || '',
                          checkChangeTemplateList: data.checkChangeTemplateList,
                          imageSortType: data.imageSortType,
                          pictureData: res.body.data?.pictureData ?? '',
                        },
                      }),
                    ));
                  },
                },
              ));
            }
          },
        },
        { ignoreSystemError: true },
      ));
    });
  }, []);
  // - Effect -
  // -- 一覧データ取得 --
  useEffect(() => {
    dispatch(xmlActions.getXml(kijshopCd, shopOrderId));
    dispatch(apiActions.run(
      new ApiImagesGet({ kijshopCd, shopOrderId, kind: '6' }),
      {
        onSuccess: (res: ResponseBase<ImagesGetResponse[]>) => {
          setUploadImageList(res?.body?.data || []);
        },
      },
      { ignoreSystemError: true },
    ));
  }, []);

  useEffect(() => {
    if (xml?.orderInfo?.infoData) {
      const arr: XmlStructureOrderInfoData[] = [];
      (xml.orderInfo.xml.metaModel.parentSequence || []).forEach((orderInfoData, i) => {
        const v = xml?.orderInfo?.infoData?.find((data) => data.xml.metaModel.id === orderInfoData.id);
        // レタッチ排除
        if (v && v.xml.xmlModel.lnwOrderInfoData?.data?.[0]?.surrogateProcess?.[0]?.$?.id !== '2010') {
          arr.push(v);
        }
      });
      setParentOrder(arr);
    }
  }, [xml]);
  const tableData = parentOrder.map((orderInfoData, i) => {
    // レタッチ排除
    if (orderInfoData.xml.xmlModel.lnwOrderInfoData?.data?.[0]?.surrogateProcess?.[0]?.$?.id === '2010') {
      return [];
    }
    const v = xml?.orderInfo?.infoData?.find((data) => data.xml.metaModel.id === orderInfoData.xml.metaModel.id);
    if (!v) {
      return [];
    }
    const _statusList = ['注文作成中', '注文作成済', 'レイアウト中', 'ラボ発注可'];
    return ([
      <SelectCondition selected={Boolean(selected.find((index) => index === i) !== undefined)} />,
      <OrderStatus
        status={
        isLaboOrderRetry ? '送信エラー' :
          isOrdering || (orderedStatusName && !_statusList.find((v) => v === orderedStatusName))
            ? orderedStatusName
            : (imageLessOrder.find((v) => v === orderInfoData.xml.metaModel.id) ? '注文作成済' : (xml?.status.getOrderStatus(orderInfoData.xml.metaModel.id || '', xml) || ''))
        }
        kijshopCd={kijshopCd}
        shopOrderId={shopOrderId}
        orderId={v.xml.metaModel.id || ''}
      />,
      v.xml.viewModel.item?.name || '',
      v.xml.viewModel.infoOutputCount || '',
      v.parts?.xml?.orderPartsDataMain()?.viewModel.pageCount || '-',
      <TableOptionData
        optionProduct={
          (() => {
            const obj: {
              optionServiceId?: string | undefined;
              optionServiceName?: string | undefined;
              optionProductNameId?: string | undefined;
              optionProductName?: string | undefined;
              count: string,
            }[] = [];
            xml?.orderInfo?.infoData?.forEach((v) => {
              if (v.xml.metaModel.parentId === orderInfoData.xml.metaModel.id) {
                if (v.xml.viewModel?.item?.name) {
                  obj.push({
                    optionServiceId: v.xml.viewModel.category?.id,
                    optionServiceName: v.xml.viewModel.category?.name,
                    optionProductName: v.xml.viewModel.item.name,
                    optionProductNameId: v.xml.viewModel.item.id,
                    count: v.xml.viewModel.infoOutputCount || '1',
                  });
                }
              }
            });
            return obj;
          })()
        }
        productName={
          (() => {
            let str = '';
            xml?.orderInfo?.infoData?.forEach((v) => {
              if (v.xml.metaModel.parentId === orderInfoData.xml.metaModel.id) {
                if (v.xml.viewModel?.item?.name) {
                  if (str) {
                    str += '/';
                  }
                  str += v.xml.viewModel.item.name;
                }
              }
            });
            if (str.length > 20) {
              return `${str.substr(0, 19)}…`;
            }
            return str || '';
          })()
        }
      />,
      v.xml.viewModel.orderMethod?.name || '',
      (v.xml.metaModel.isNeedAgreement === 'true' || v.xml.metaModel.isNeedAgreement === 'false') ? (
        <NeedAgreement
          agreement={v.xml.metaModel.isNeedAgreement}
          kijshopCd={kijshopCd}
          shopOrderId={shopOrderId}
          orderId={v.xml.metaModel.id || ''}
        />
      ) : (<div>-</div>),
    ]);
  }).filter((v) => v.length);

  useEffect(() => {
    // if (selected !== -1) {
    //   setSelected(-1);
    // }
    if (selected.length) {
      setSelected([]);
    }
  }, [tableData.length]);
  useEffect(() => {
    if (storage.kijshopCd !== kijshopCd || storage.kijshopCd === '') {
      dispatch(localStorageActions.getLocalData(kijshopCd));
    }
    dispatch(orderLockActions.check(kijshopCd, shopOrderId, {
      yes: () => {
        dispatch(orderLockActions.setId(shopOrderId));
      },
      no: () => {
        dispatch(orderLockActions.setId(''));
        dispatch(push(RoutingPath.cnv.orders({ kijshopCd })));
      },
    }));
    if (!master) {
      dispatch(commonActions.getData(kijshopCd));
    }
    return () => {
      dispatch(orderPreparationActions.resetOrderData());
      dispatch(orderPreparationActions.resetCompImages());
    };
  }, []);
  useEffect(() => {
    const ordering = Boolean(orderingShopId && orderingShopId === shopOrderId);
    if (isOrdering !== ordering) {
      setOrdering(ordering);
    }
    if (!isOrdering && xml?.info?.xmlModel?.lnwOrder?.order?.[0]?.$?.orderDate) {
      setIsOrdering(true);
    }
  }, [xml]);
  /* デバッグ用XML取得 TODO 消す */
  useEffect(() => {
    if (!(window as any).xml) {
      (window as any).xml = (id: string) => {
        new ApiAllGetXml(kijshopCd, id)
          .do()
          .then((res) => {
            const files = ((res as ResponseBase<GetXmlResponse>)?.body?.data as GetXmlResponse).fileinfo;
            if (!files?.length) {
              console.error('ファイルの取得に失敗しました');
              console.log(res.message);
              return;
            }
            const zip = new JSZip();
            files.forEach((v) => zip.file(v.filename, v.body));
            zip
              .generateAsync({ type: 'blob' })
              .then((content) => {
                const url = (window.URL || window.webkitURL).createObjectURL(content);
                const a = document.createElement('a');
                a.href = url;
                a.download = `${id}.zip`;
                a.click();
                (window.URL || window.webkitURL).revokeObjectURL(url);
              });
          })
          .catch((e) => {
            console.error('ファイルの取得に失敗しました');
            console.log(e);
          });
      };
    }
  }, []);
  useEffect(() => {
    if (orderedStatusName !== '' || orderedList.length === 0) return;
    const data = orderedList.find((v) => v.shopOrderId === shopOrderId);
    if (data) {
      setOrderedStatusName(data.orderStatusName);
      isLaboOrdered.current = true;
    } else {
      dispatch(apiActions.run(
        new ApiShopOrderStatusListGet(kijshopCd),
        {
          onSuccess: (res: ResponseBase<ShopOrderListGetResponseStatus>) => {
            const data = res.body.data?.statusList.find((v) => v.shopOrderId === shopOrderId);
            if (data) {
              setOrderedStatusName(data.status);
              // -- 一旦ラボ発注中で止まっている場合のみに絞る --
              if (data.status === 'ラボ発注中' && LaboOrderCompChecker.checkTimeOut(data)) {
                isLaboOrdered.current = false;
              }
            }
          },
        },
      ));
    }
  }, [shopOrderId, orderedList]);
  useEffect(() => {
    const flag = xml?.status?.isMixOrderMethod(xml);
    if (isMixOrderMethod !== flag) {
      setIsMixOrderMethod(flag);
    }
  }, [xml]);
  /* 注文コピー時に作成日時の小数部分をサーバーサイドで切っているので一旦フロントで仕方なしに対応 */
  useEffect(() => {
    const info = xml?.info;
    if (info && info.metaModel.createDate && info.metaModel.createDate.length !== DateFormatter.date2str(new Date(), 'YYYYMMDD_HHmmSS.sss').length) {
      info.metaModel.createDate = `${info.metaModel.createDate.split('.')[0]}.${Math.floor(Math.random() * 10) % 10}${Math.floor(Math.random() * 10) % 10}${Math.floor(Math.random() * 10) % 10}`;
      info.build();
      dispatch(apiActions.run(
        new ApiUploadXml(
          kijshopCd,
          shopOrderId,
          {
            files: [{
              filename: info.xmlUniqueName,
              body: info.xml,
              is_create: '0',
            }],
          }),
        {},
        { ignoreSystemError: true },
      ));
    }
  }, [xml]);
  /* アプリから遷移した際の店舗コード確認用 */
  useEffect(() => {
    dispatch(apiActions.run(
      new ApiGetMetaShop(kijshopCd),
      {
        onSuccess: () => {
        },
        onError: (e) => {
          if (String((e as ResponseBase<any>)?.error?.errorCode) === '400') {
            dispatch(push(RoutingPath.login));
          }
        },
      },
      { ignoreSystemError: true },
    ));
    dispatch(commonActions.checkBrowser());
  }, []);
  // const insertPage = selected.length ? [...selected].map((v, i) => ({
  const insertPage = parentOrder.length ? [...parentOrder].map((v, i) => ({
    // index: v + i + 1,
    // index: Number(v.xml.indexes[0]) + i,
    index: i + 1 + (i),
    element: <ImageUpload
      memo={''}
      // orderInfoDataStructureModel={parentOrder[v]}
      orderInfoDataStructureModel={v}
      metaDelete={deleteAndChangeMeta}
      orderIdList={orderIdList}
      // creating={Boolean(xml?.status.getOrderStatus(parentOrder[v]?.xml.metaModel.id || '', xml) === '注文作成中')}
      // enableLabOrder={Boolean(xml?.status.getOrderStatus(parentOrder[v]?.xml.metaModel.id || '', xml) === 'ラボ発注可')}
      creating={Boolean(xml?.status.getOrderStatus(v?.xml.metaModel.id || '', xml) === '注文作成中')}
      enableLabOrder={Boolean(xml?.status.getOrderStatus(v?.xml.metaModel.id || '', xml) === 'ラボ発注可')}
      uploadImageList={uploadImageList}
      insertIndex={i + 1 + i}
    />,
  })) : undefined;
  useEffect(() => {
    if (window.location.href.indexOf('?reload') === -1) {
      window.location.href += '?reload';
      window.location.reload();
    }
  }, []);
  return (
    <div className="order_preparation page_base">
      <Header />
      <div className="order_preparation__body">
        <Sidebar />
        <div className="order_preparation__body__contents">
          <div className="order_preparation__body__contents__menu">
            <Button
              label="注文作成"
              onClick={handlerClickCreateOrder}
              disabled={isOrdering}
            />
            <Button
              label={isLaboOrderRetry ? '再発注' : 'ラボ発注'}
              onClick={handlerClickLabOrder}
              disabled={(isOrdering && !isLaboOrderRetry) || !checkLabOrder() || isLaboOrdered.current || !xml?.customer?.xmlModel?.lnwCustomer.customer?.[0]?.data?.[0]?.name?.[0]?.$.lastName || isMixOrderMethod || loading || Boolean(imageLessOrder.length)}
            />
            <Button
              label="レタッチ"
              onClick={handlerClickRetouch}
              disabled={retouchDisabled || isOrdering || loading}
            />
          </div>

          {isMixOrderMethod ? (
            <div className="order_preparation__body__contents__caution">
              「完成画像を入稿」方法と、その他の注文方法を混在させることはできません。
              注文を再作成し、注文方法を統一してください。
            </div>
          ) : (<></>)}

          <div className="order_preparation__body__contents__table">
            <Table
              head={[
                '',
                'ステータス',
                'ラボ商品名',
                '数量',
                'ページ',
                'オプション',
                '注文方法',
                '校正承認',
              ]}
              body={tableData}
              selected={selected}
              handlerSelect={handlerChangeSelected}
              minimumNumOfRows={10}
              insertPage={insertPage}
              excludedIndex={7}
              cursorPointer
            />
          </div>
        </div>
      </div>
      {ordering ? (<LoadingPopup label={'ラボ発注中です...'} />) : (<></>)}
    </div>
  );
};

const SameOrderError = () => {
  const dispatch = useDispatch();
  const handlerCLickButton = useCallback(() => {
    dispatch(dialogActions.pop());
  }, []);
  const ref = useRef<HTMLAnchorElement>(null);
  useEffect(() => {
    if (ref.current) {
      ref.current.target = '_blank';
      ref.current.href = webPagePath.conflictShopOrderId;
      ref.current.rel = 'noopener noreferrer';
    }
  }, []);
  return (
    <div>
      <div>
        注文番号の重複が発生したため、発注を完了できません。<br />
        以下の手順を参照してエラーの回避をお願いします。<br /><br />
        <a ref={ref}>
          →注文番号の重複エラーが発生する場合
        </a>
      </div>
      {/*NOTE: 緊急対応時、メッセージ 2023.07.07 kurokawa*/}
      {/*<div>*/}
      {/*  本注文はサーバー通信の問題で発注エラーと表示されますが、<br />*/}
      {/*  注文データはラボ側でリカバリー処理させていただきます。<br />*/}
      {/*  このままの状態で注文完了とみなしてください。<br /><br />*/}
      {/*  <a ref={ref}>*/}
      {/*    →【お詫び】発生中の不具合について*/}
      {/*  </a>*/}
      {/*</div>*/}
      <div
        style={{
          marginTop: '1rem',
          textAlign: 'center',
        }}
      >
        <Button
          label="OK"
          onClick={handlerCLickButton}
          style={{
            display: 'inline-block',
            textAlign: 'left',
          }}
        />
      </div>
    </div>
  );
};

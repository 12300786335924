import { XmlClass } from './xml-class';
import { XmlClassName } from '../model/xml-class-name';
import { TPrimarySelectViewModel } from '../model/primary-select/xml-primary-select-model';
import { IPrimarySelect } from '../interface/i-primary-select';

// type TView = Partial<TOrderSelectViewModel>;
type TView = Partial<TPrimarySelectViewModel>;

export class PrimarySelectXml extends XmlClass<IPrimarySelect, TView, {}> {

  name: XmlClassName = 'primary-select';
  viewModel: TView = {};
  metaModel: {} = {};


  protected init(): void {
    this.xmlUniqueName = this.xmlUniqueName || `PPM_${this.shopOrderId}_11_11_primarySelect.xml`;
    this.xmlParentName = this.xmlParentName || `PPM_${this.shopOrderId}_11_11.xml`;
    this.rootTagModel = {
      ...this.rootTagModel,
      parentXPath: '/lnwOrder/order/primarySelect',
      parentPath: this.xmlParentName,
    };
    this.docking({}, {});
  }

  protected docking(v: TView, m: {}): void {
    this.xmlModel = {
      lnwPrimarySelect: {
        $: { ...this.rootTagModel },
        primarySelect: [
          {
            $: {
              primarySelectLipsId: v.primarySelectLipsId ?? '',
              primarySelectName: v.primarySelectName ?? '',
              quantity: v.quantity ?? '',
              unitPrice: v.unitPrice ?? '',
              primarySelectOrderId: v.primarySelectOrderId ?? '',
              primarySelectPurpose: v.primarySelectPurpose ?? '',
              primarySelectUploadNum: v.primarySelectUploadNum ?? '',
              primarySelectFileNum: v.primarySelectFileNum ?? '',
            }
          }
        ],
      },
    };
  }

  protected split(x: IPrimarySelect): void {
    if (!x?.lnwPrimarySelect) {
      this.viewModel = {};
      this.metaModel = {};
      return;
    }
    this.viewModel = {
      primarySelectLipsId: x.lnwPrimarySelect?.primarySelect?.[0]?.$.primarySelectLipsId ?? '',
      primarySelectName: x.lnwPrimarySelect?.primarySelect?.[0]?.$.primarySelectName ?? '',
      quantity: x.lnwPrimarySelect?.primarySelect?.[0]?.$.quantity ?? '',
      unitPrice: x.lnwPrimarySelect?.primarySelect?.[0]?.$.unitPrice ?? '',
      primarySelectOrderId: x.lnwPrimarySelect?.primarySelect?.[0]?.$.primarySelectOrderId ?? '',
      primarySelectPurpose: x.lnwPrimarySelect?.primarySelect?.[0]?.$.primarySelectPurpose ?? '',
      primarySelectUploadNum: x.lnwPrimarySelect?.primarySelect?.[0]?.$.primarySelectUploadNum ?? '',
      primarySelectFileNum: x.lnwPrimarySelect?.primarySelect?.[0]?.$.primarySelectFileNum ?? '',
    };
    this.rootTagModel = Object.assign(this.rootTagModel, x.lnwPrimarySelect.$);
  }
}

import { ApiBaseBack } from '../api-base-back';
import { CloudFolderOrderDetailViewModel } from '../../../models/cloud-folder-order-detail-view-model';


export type PurposeInfo = {
  purposeId: string,
  purposeName: string,
  sortNo: number | null,
};

export type ResponseGetPurpose = {
  products : PurposeInfo[];
}

export class ApiGetPurpose extends ApiBaseBack {
  constructor(param: {}) {
    super(
      'GET',
      '/api/v1/purposes',
      'JSON',
      param,
    );
  }
}

import { XmlClass } from '../xml-class';
import { IOrderSelect } from '../../interface/order/i-order-select';
import { XmlClassName } from '../../model/xml-class-name';
import { TOrderSelectMetaModel, TOrderSelectViewModel } from '../../model/order/xml-order-select-model';

// type TView = Partial<TOrderSelectViewModel>;
type TMeta = Partial<TOrderSelectMetaModel>;

export class OrderSelectXml extends XmlClass<IOrderSelect, {}, TMeta> {

  name: XmlClassName = 'order-select';
  viewModel: {} = {};
  metaModel: TMeta = {};

  protected init(): void {
    this.xmlUniqueName = this.xmlUniqueName || `PPM_${this.shopOrderId}_11_11_orderSelect.xml`;
    this.xmlParentName = this.xmlParentName || `PPM_${this.shopOrderId}_11_11.xml`;
    this.rootTagModel = {
      ...this.rootTagModel,
      parentXPath: '/lnwOrder/order/orderSelect',
      parentPath: this.xmlParentName,
    };
    this.docking({}, {});
  }

  protected docking(v: {}, m: TMeta): void {
    this.xmlModel = {
      lnwOrderSelect: {
        $: { ...this.rootTagModel },
        inputGroup: [
          {
            $: {
              count: '1',
            },
            data: [
              {
                $: {
                  groupID: m.groupID || '01',
                  orderID: m.orderID || '',
                  name: m.name || '',
                  path: m.path || '',
                  place: m.place || '',
                  date: m.date || '',
                  cameraman: m.cameraman || '',
                },
              },
            ],
          },
        ],
        orderSelect: m.imageData?.length ? [
          {
            $: {
              count: `${m.imageData?.length}`,
            },
            data: m.imageData?.map((data, index) => ({
              $: {
                selectID: data.selectID,
                selectGroupID: '01',
                selectPictureID: data.selectPictureID,
                selectCode: data.selectCode,
                id: `${index + 1}`,
                width: data.width,
                height: data.height,
              },
              originalFileName: [{
                real: [{
                  $: {
                    path: data?.originalFileName?.real.path,
                    originalFileTime: data?.originalFileName?.real.originalFileTime,
                  },
                }],
              }],
              selectFileName: [{
                real: [{
                  $: {
                    path: data?.selectFileName?.real.path,
                    realSelectFileTime: data?.selectFileName?.real.realSelectFileTime,
                  },
                }],
                virtual: [''],
              }],
            })),
          },
        ] : undefined,
      },
    };
  }

  protected split(x: IOrderSelect): void {
    if (!x?.lnwOrderSelect) {
      this.viewModel = {};
      this.metaModel = {};
      return;
    }
    if ((x.lnwOrderSelect as any).orderSeledct) {
      x.lnwOrderSelect.orderSelect = (x.lnwOrderSelect as any).orderSeledct;
    }
    this.viewModel = {
      data: (x.lnwOrderSelect.orderSelect?.[0].data) ?
        x.lnwOrderSelect.orderSelect[0].data?.map((data) => ({
          selectID: data.$.selectID!,
          selectPictureID: data.$.selectPictureID!,
          selectCode: data.$.selectCode!,
          width: data.$.width!,
          height: data.$.height!,
          originalFileName:{
            real: {
              path: data.originalFileName?.[0].real![0].$.path!,
              originalFileTime: data.originalFileName?.[0].real![0].$.originalFileTime!,
            },
          },
          selectFileName: {
              real: {
                path: data.selectFileName?.[0].real![0].$.path!,
                realSelectFileTime: data.selectFileName?.[0].real![0].$.realSelectFileTime!,
              },
            },
        })) : [],
    };
    this.metaModel = {
      groupID: x.lnwOrderSelect.inputGroup![0].data![0].$.groupID!,
      orderID: x.lnwOrderSelect.inputGroup![0].data![0].$.orderID!,
      name: x.lnwOrderSelect.inputGroup![0].data![0].$.name!,
      path: x.lnwOrderSelect.inputGroup![0].data![0].$.path!,
      place: x.lnwOrderSelect.inputGroup![0].data![0].$.place!,
      date: x.lnwOrderSelect.inputGroup![0].data![0].$.date!,
      cameraman: x.lnwOrderSelect.inputGroup![0].data![0].$.cameraman!,
      imageData: x.lnwOrderSelect.orderSelect?.[0]?.data?.map((data) => ({
        id: data.$.id,
        selectGroupID: data.$.selectGroupID,
        selectPictureID: data.$.selectPictureID,
        selectID: data.$.selectID,
        selectCode: data.$.selectCode,
        width: data.$.width,
        height: data.$.height,
        originalFileName: data.originalFileName?.[0]?.real?.[0]?.$ && {
          real: {
            path: data.originalFileName[0].real[0].$.path || '',
            originalFileTime: data.originalFileName[0].real[0].$.originalFileTime || '',
          },
        },
        selectFileName: data.selectFileName?.[0]?.real?.[0]?.$ && {
          real: {
            path: data.selectFileName[0].real[0].$.path || '',
            realSelectFileTime: data.selectFileName[0].real[0].$.realSelectFileTime || '',
          },
        },
      })),
    };
    this.rootTagModel = Object.assign(this.rootTagModel, x.lnwOrderSelect.$);
  }
}

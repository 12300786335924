import { useCallback, useEffect, useRef } from "react";
import { XmlStructureModel } from "../../../../xml/model/xml-structure-model"
import { useAppDispatch } from "../../../../app/hooks";
import { dialogActions } from "../../../dialog/slice/dialog-slice";
import { useLocation } from "react-router-dom";
import { replace } from "connected-react-router";

type Props = {
  xml: XmlStructureModel | null,
}

export const useAlertCompleteImage = (props: Props) => {
  const {
    xml,
  } = props;
  const dispatch = useAppDispatch();
  const init = useRef<boolean>(false);
  const location = useLocation();
  return useCallback(() => {
    if (init.current || !xml) return;
    if (location.search.includes('?osci')) {
      init.current = true;
      dispatch(replace({ ...location, search: location.search?.replace('?osci', '') }));
      const target = xml.orderInfo?.infoData?.find((v) => v.xml.viewModel.orderMethod?.id === '40');
      if (target) {
        dispatch(dialogActions.pushMessage({
          title: '確認',
          message: [
            '「セレクト + 商品作成」の目的の注文では、「完成画像を入稿」方法はご利用頂けません。',
            '「完成画像を入稿」方法の注文を削除してください。',
          ],
          buttons: [
            { label: 'OK', callback: () => dispatch(dialogActions.pop()) },
          ],
        }));
      }
    }
  }, [xml]);
}
import { ApiBaseFront } from "../api-base-front";

type Params = {
  kijshopCd: string,
  shopOrderId: string,
  cfOrderId: string,
  oneStopOrderUser: string,
  status: string,
};

export class ApiCfLabOrder extends ApiBaseFront<{}> {
  constructor(param: Params) {
    const {
      kijshopCd,
      shopOrderId,
      ...defaultProps
    } = param;

    super(
      'POST',
      `/api/v1/request/${kijshopCd}/${shopOrderId}/complete`,
      'JSON',
      defaultProps,
    );
  }
}

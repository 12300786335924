import { DisplayObject } from 'createjs-module';
import * as lodash from 'lodash';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useAppSelector, useAppDispatch } from '../../../../app/hooks';
import iconCopy from '../../../../assets/img/icon/copy.svg';
import iconDelete from '../../../../assets/img/icon/delete_alt.svg';
import iconMove from '../../../../assets/img/icon/frame_move.svg';
import iconReset from '../../../../assets/img/icon/history_back.svg';
import iconPicture from '../../../../assets/img/icon/picture.svg';
import iconFrame from '../../../../assets/img/icon/picture_frame.svg';
import iconRotation from '../../../../assets/img/icon/rotate90.svg';
import { UiManager } from '../../../../layout-editor/manager/ui/ui.manager';
import { DynamicTooltip } from '../../../ui/tooltip/dynamic-tooltip';
import { StaticTooltip } from '../../../ui/tooltip/static-tooltip';
import './layout.edit-template.scss';
import { AlbumPage } from '../../../../layout-editor/albam/albam';
import iconTextCursor from '../../../../assets/img/icon/text-cursor.svg';
import { useParams } from 'react-router-dom';
import { PathParams, RoutingPath } from '../../../../routes/routing-path';
import { AddAlbumParam, LayoutContext, MenuType } from '../layout';
import { AdditionalShowType } from '../toolbar/layout.toolbar';
import { layoutActions } from '../../../../slices/layout-slice';
import { UuidGenerator } from '../../../../utilities/uuid-generator';
import { ApiImagesGetOne } from '../../../../api/front/images/api-images';
import { apiActions } from '../../../../slices/api-slice';
import { TOrderPageDataViewModel } from '../../../../xml/model/order/xml-order-page-data-model';
import { XmlStructureOrderPartsData, XmlStructureModel } from '../../../../xml/model/xml-structure-model';
import { LayoutXmlUtile, RestorePicData } from '../../../../layout-editor/model/layout.xml.utile';
import { store } from '../../../../app/store';
import { dialogActions } from '../../../dialog/slice/dialog-slice';
import { push } from 'connected-react-router';
import { cloneDeep } from 'lodash';
import { getTargetPathUrl } from '../all-template/layout.all-template';
import { TemplateLoaderManager } from '../../../../layout-editor/manager/template-loader/template-loader.manager';
import { padding } from '../../../../xml/slice/xml-slice';
import { SelectIdChecker } from '../../../../utilities/select-id-checker';
import { useDispatch } from 'react-redux';
import { CjContainer } from '../../../../layout-editor/model/cj-factory';
import { FontFamilyChecker } from '../../../../utilities/font-family-checker';
import { ThumbChecker } from '../../../../utilities/thumb-checker';

export type LayoutInitType = {
  modeAbleArea: 'on' | 'off',
  modeInOut: 'in' | 'out',
  display: boolean,
  fitNum: number,
  additionalShow: AdditionalShowType,
  modeCursor: 'pan-zoom' | 'select' | 'text',
}

type ShowTooltipType = 'imageHover' | 'frameMove' | 'scaleIcon' | 'rotateIcon' | 'none';
// - 編集画面内ツールチップ -
const TemplateItemsTooltip = () => {
  // - State -
  // -- ツールチップ表示する種類 --
  const [showType, setShowType] = useState<ShowTooltipType>('none');
  // -- ツールチップの各表示位置 --
  const [imageHoverPos, setImageHoverPos] = useState<{ x: string, y: string }>({ x: '0', y: '0' });
  const [frameMovePos, setFrameMovePos] = useState<{ x: string, y: string }>({ x: '0', y: '0' });
  const [dragIconPos, setDragIconPos] = useState<{ x: string, y: string }>({ x: '0', y: '0' });
  // - Effect -
  // -- 表示位置の更新 --
  useEffect(() => {
    const setTooltip = (v: { type: 'imageHover' | 'frameMove' | 'scaleIcon' | 'rotateIcon' | 'none', pos: { x: number, y: number } }) => {
      setShowType(v.type);
      if (v.type === 'imageHover') {
        setImageHoverPos({ x: `${v.pos.x}px`, y: `${v.pos.y}px` });
      } else if (v.type === 'frameMove') {
        setFrameMovePos({ x: `${v.pos.x}px`, y: `${v.pos.y}px` });
      } else if (v.type === 'scaleIcon' || v.type === 'rotateIcon') {
        setDragIconPos({ x: `${v.pos.x}px`, y: `${v.pos.y}px` });
      } else {
        setShowType(v.type);
      }
    };
    UiManager.ins.on('l->r:show-tooltip', setTooltip);
    return () => {
      UiManager.ins.off('l->r:show-tooltip', setTooltip);
    };
  }, []);
  return (
    <div>
      {showType === 'imageHover' ?
        <StaticTooltip
          messages={['「ダブルクリック」でトリミング']}
          pos={imageHoverPos}
        />
        : showType === 'frameMove' ?
          <StaticTooltip
            messages={['エリア外をクリックすると', '移動モードが解除されます']}
            pos={frameMovePos}
          />
          : showType === 'scaleIcon' ?
            <StaticTooltip
              messages={['ドラッグして拡大・縮小します']}
              pos={dragIconPos}
            />
            : showType === 'rotateIcon' ?
              <StaticTooltip
                messages={['ドラッグして回転します']}
                pos={dragIconPos}
              />
              : <></>
      }
    </div>
  );
};

export type ErrorToastProps = {
  // トーストの淵やタイトル部分の色
  text: string[],
  className?: string,
};

const ErrorToast = (props: ErrorToastProps) => {
  const { text, className } = props;
  return (
    <>
      <div className={`error_toast ${className || ''}`}>
        <div className="error_toast__icon">
          <i className="fas fa-exclamation" />
        </div>

        <div className="error_toast__txt">
          {text.map((v, i) => (
            <div key={`error-toast-text-row_${i}`}>{v}</div>
          ))}
        </div>
      </div>
    </>
  );
};

const ToolBox = () => {
  // const { album } = useAppSelector((state) => ({
  //   album: state.layout.currentAlbumPage,
  // }), lodash.isEqual);
  // - Ref -
  const toolBoxEle = useRef<HTMLDivElement>(null);
  const groupLabelEle = useRef<HTMLDivElement>(null);
  // -- 「写真」回転 --
  const rotateImageEle = useRef<HTMLDivElement>(null);
  // -- 「写真」リセット --
  const resetImageEle = useRef<HTMLDivElement>(null);
  // -- 「写真」削除 --
  const deleteImageEle = useRef<HTMLDivElement>(null);
  // -- 「写真枠」回転 --
  const rotateFrameEle = useRef<HTMLDivElement>(null);
  // -- 「写真枠」移動 --
  const moveFrameEle = useRef<HTMLDivElement>(null);
  // -- 「写真枠」コピー --
  const cloneFrameEle = useRef<HTMLDivElement>(null);
  // -- 「写真枠」削除 --
  const deleteFrameEle = useRef<HTMLDivElement>(null);
  // - State -
  const [mode, setMode] = useState<'image' | 'image-frame'>('image-frame');
  // -- 選択画像 --
  const [toolBoxHeight, setToolBoxHeight] = useState(0);
  const [toolBoxWidth, setToolBoxWidth] = useState(0);
  const [image, setImage] = useState<DisplayObject | null>(null);
  const [pos, setPos] = useState<{ x: number, y: number, scale: number }>({ x: 0, y: 0, scale: 1 });
  const [display, setDisplay] = useState(false);
  const [isImgDisabled, setIsImgDisabled] = useState(true);
  const [isFrameDisabled, setIsFrameDisabled] = useState(false);
  // -- 「写真」<->「写真枠」 --
  const [groupLabelTooltip, setGroupLabelTooltip] = useState(false);
  // -- 「写真」回転 --
  const [rotateImageTooltip, setRotateImageTooltip] = useState(false);
  // -- 「写真」リセット --
  const [resetImageTooltip, setResetImageTooltip] = useState(false);
  // -- 「写真」削除 --
  const [deleteImageTooltip, setDeleteImageTooltip] = useState(false);
  // -- 「写真枠」回転 --
  const [rotateFrameTooltip, setRotateFrameTooltip] = useState(false);
  // -- 「写真枠」移動 --
  const [moveFrameTooltip, setMoveFrameTooltip] = useState(false);
  // -- 「写真枠」コピー --
  const [cloneFrameTooltip, setCloneFrameTooltip] = useState(false);
  // -- 「写真枠」削除 --
  const [deleteFrameTooltip, setDeleteFrameTooltip] = useState(false);
  // - Callback -
  // -- 写真/写真枠編集切り替え --
  const handlerClickChangeMode = useCallback((isImg) => {
    setMode(isImg ? 'image-frame' : 'image');
    UiManager.ins.emit('r->l:change-image/frame', { mode: isImg ? 'image-frame' : 'image' });
  }, []);
  // -- 写真削除 --
  const handlerClickDeleteImage = useCallback(() => {
    UiManager.ins.emit('r->l:delete');
  }, []);
  // -- 写真初期状態 --
  const handlerClickResetImage = useCallback(() => {
    UiManager.ins.emit('r->l:reset');
  }, []);
  // -- 写真回転 --
  const handlerClickRotateImage = useCallback(() => {
    UiManager.ins.emit('r->l:rotate', { angle: 90 });
  }, []);
  // -- 写真枠削除 --
  const handlerClickDeleteImageFrame = useCallback(() => {
    UiManager.ins.emit('r->l:delete');
  }, []);
  // -- 写真枠回転 --
  const handlerClickRotateImageFrame = useCallback(() => {
    UiManager.ins.emit('r->l:rotate', { angle: 90 });
  }, []);
  // -- 写真枠移動 --
  const handlerClickMoveImageFrame = useCallback(() => {
    UiManager.ins.emit('r->l:move-mode');
    setDisplay(false);
  }, []);
  // -- 写真枠複製 --
  const handlerClickCloneImageFrame = useCallback(() => {
    UiManager.ins.emit('r->l:clone');
  }, []);
  // - Effect -
  // -- 選択画像更新 --
  useEffect(() => {
    if (toolBoxEle && toolBoxEle.current) {
      setToolBoxHeight(toolBoxEle.current.getBoundingClientRect().height);
      setToolBoxWidth(toolBoxEle.current.getBoundingClientRect().width);
    }
  }, [toolBoxEle, display]);
  useEffect(() => {
    UiManager.ins.on('l->r:select', (v) => {
      if (!v.image || !v.pos) return;
      setImage(v.image);
      setPos({ x: v.pos.x, y: v.pos.y, scale: v.pos?.scale || 1 });
      setDisplay(true);
      setIsImgDisabled(v.isImgDisabled || false);
    });
    UiManager.ins.on('l->r:dis-select', () => {
      setDisplay(false);
    });
    UiManager.ins.on('l->r:change-image/frame', (v) => {
      setMode(v.mode || 'image');
      setIsFrameDisabled(!!v.isAdditional);
    });
    const listenerScroll = () => {
      setDisplay(false);
    };
    // TODO ダミー
    const dummy = document.getElementById('edit-dummy');
    if (dummy) {
      dummy.addEventListener('scroll', listenerScroll);
    }
    return () => {
      if (dummy) {
        dummy.removeEventListener('scroll', listenerScroll);
        UiManager.ins.off('l->r:select');
        UiManager.ins.off('l->r:dis-select');
        UiManager.ins.off('l->r:change-image/frame');
      }
    };
  }, []);
  const toolBoxPosition = ():{left:number, top: number} => {
    // - 編集エリアの座標 左ツールバーや、写真一覧の有無で変動するため、下記から始点の座標を取得 -
    const area = document.getElementById('edit-dummy')?.getBoundingClientRect();
    if (area && pos && image) {
      // - 写真の中心にツールボックスを表示するための座標 -
      const centerHeight = pos.y + (image as CjContainer).virtual.height * pos.scale / 2;
      const centerWidth = pos.x + (image as CjContainer).virtual.width * pos.scale / 2 - toolBoxWidth / 2;
      // - 写真の始点がツールボックスが見切れる位置にある場合、中央に表示。中央でも見切れる場合は端にそって表示。 -
      const top = pos.y - toolBoxHeight >= area.y && pos.x >= area.x ? pos.y - toolBoxHeight : centerHeight > area.y ? centerHeight : area.y;
      const left = pos.y - toolBoxHeight >= area.y && pos.x >= area.x ? pos.x : centerWidth > area.x ? centerWidth : area.x;
      return { left, top };
    }
    return { left: pos.x, top: pos.y - toolBoxHeight };
  };
  return display && image ? (
    <div
      ref={toolBoxEle}
      className="tool_box"
      style={toolBoxPosition()}
    >
      <div className="tool_box__group">
        <div className="tool_box__group__label">
          {mode === 'image' ? '写真枠' : '写真'}
        </div>
        <div className="tool_box__group__menu__wrap">
          <div
            className={`tool_box__group__menu ${(isImgDisabled || isFrameDisabled) ? 'disabled' : ''}`}
            onClick={() => handlerClickChangeMode(mode === 'image')}
            onMouseEnter={() => setGroupLabelTooltip(true)}
            onMouseLeave={() => setGroupLabelTooltip(false)}
            ref={groupLabelEle}
          >
            <img
              src={mode === 'image' ? iconFrame : iconPicture}
              alt=""
            />
            {groupLabelTooltip ?
              <DynamicTooltip
                messages={[mode === 'image' ? '写真枠編集へ' : '写真編集へ']}
                relativeEle={groupLabelEle.current}
              />
              : <></>}

          </div>
        </div>
      </div>
      <div className="tool_box__group">
        {mode === 'image' ? (
          <>
            <div className="tool_box__group__label">写真</div>
            <div className="tool_box__group__menu__wrap">
              <div
                className="tool_box__group__menu"
                onClick={handlerClickRotateImage}
                onMouseOver={() => setRotateImageTooltip(true)}
                onMouseLeave={() => setRotateImageTooltip(false)}
                ref={rotateImageEle}
              >
                <img
                  src={iconRotation}
                  alt=""
                />
                {rotateImageTooltip && <DynamicTooltip
                  messages={['回転']}
                  relativeEle={rotateImageEle.current}
                />}
              </div>
              <div
                className="tool_box__group__menu"
                onClick={handlerClickResetImage}
                onMouseOver={() => setResetImageTooltip(true)}
                onMouseLeave={() => setResetImageTooltip(false)}
                ref={resetImageEle}
              >
                <img
                  src={iconReset}
                  alt=""
                />
                {resetImageTooltip && <DynamicTooltip
                  messages={['リセット']}
                  relativeEle={resetImageEle.current}
                />}
              </div>
              <div
                className="tool_box__group__menu"
                onClick={handlerClickDeleteImage}
                onMouseOver={() => setDeleteImageTooltip(true)}
                onMouseLeave={() => setDeleteImageTooltip(false)}
                ref={deleteImageEle}
              >
                <img
                  src={iconDelete}
                  alt=""
                />
                {deleteImageTooltip && <DynamicTooltip
                  messages={['削除']}
                  relativeEle={deleteImageEle.current}
                />}
              </div>
            </div>
          </>
        ) : (<></>)}
        {mode === 'image-frame' ? (
          <>
            <div className="tool_box__group__label">写真枠</div>
            <div className="tool_box__group__menu__wrap">
              <div
                className="tool_box__group__menu"
                onClick={handlerClickRotateImageFrame}
                onMouseOver={() => setRotateFrameTooltip(true)}
                onMouseLeave={() => setRotateFrameTooltip(false)}
                ref={rotateFrameEle}
              >
                <img
                  src={iconRotation}
                  alt=""
                />
                {rotateFrameTooltip && <DynamicTooltip
                  messages={['回転']}
                  relativeEle={rotateFrameEle.current}
                />}
              </div>
              <div
                className="tool_box__group__menu"
                onClick={handlerClickMoveImageFrame}
                onMouseOver={() => setMoveFrameTooltip(true)}
                onMouseLeave={() => setMoveFrameTooltip(false)}
                ref={moveFrameEle}
              >
                <img
                  src={iconMove}
                  alt=""
                />
                {moveFrameTooltip && <DynamicTooltip
                  messages={['移動']}
                  relativeEle={moveFrameEle.current}
                />}
              </div>
              <div
                className="tool_box__group__menu"
                onClick={handlerClickCloneImageFrame}
                onMouseOver={() => setCloneFrameTooltip(true)}
                onMouseLeave={() => setCloneFrameTooltip(false)}
                ref={cloneFrameEle}
              >
                <img
                  src={iconCopy}
                  alt=""
                />
                {cloneFrameTooltip && <DynamicTooltip
                  messages={['コピー']}
                  relativeEle={cloneFrameEle.current}
                />}
              </div>
              <div
                className="tool_box__group__menu"
                onClick={handlerClickDeleteImageFrame}
                onMouseOver={() => setDeleteFrameTooltip(true)}
                onMouseLeave={() => setDeleteFrameTooltip(false)}
                ref={deleteFrameEle}
              >
                <img
                  src={iconDelete}
                  alt=""
                />
                {deleteFrameTooltip && <DynamicTooltip
                  messages={['削除']}
                  relativeEle={deleteFrameEle.current}
                />}
              </div>
            </div>
          </>
        ) : (<></>)}
      </div>
    </div>
  ) : (<></>);
};

type ImageFrameProps = {
  initMenu: MenuType;
  isRestore: boolean;
  callbackRestore: () => void;
  xml: XmlStructureModel | null;
  pageTypeIdArr: any[],
}

const ImageFrame = (props: ImageFrameProps) => {
  const { initMenu, isRestore, callbackRestore, xml, pageTypeIdArr } = props;

  const {
    preparationPath,
  } = useContext(LayoutContext);

  // - selector -
  const { kijshopCd, shopOrderId, orderId } = useParams<PathParams>();
  const dispatch = useAppDispatch();

  const {
    albumPage,
    restoreTemplates,
    templatesXmlArr,
    isOnSave,
    albumInfo,
    albumSetting,
    restorePicData,
    pageTypeList,
  } = useAppSelector((state) => ({
    albumPage: state.layout.currentAlbumPage,
    restoreTemplates: state.layout.restoreTemplates,
    templatesXmlArr: state.layout.templatesXmlList,
    isOnSave: state.layout.isSaving,
    albumInfo: state.layout.albumPages,
    albumSetting: state.layout.albumSettingData,
    restorePicData: state.layout.metaData,
    pageTypeList: state.layout.pageTypeList,
  }), lodash.isEqual);
  // - state -
  const [isRuler, setIsRuler] = useState(true);
  const [restoreInit, setRestoreInit] = useState(false);
  // - ref -
  const layoutEditorContainer = useRef<HTMLDivElement>(null);
  // -- layoutEditorManager操作 --
  useEffect(() => {
    let isSave = false;
    UiManager.ins.emit('l->r:getOnSave', { callback: (v) => isSave = v });
    if (!layoutEditorContainer.current || isRestore || isSave) return;
    const container = layoutEditorContainer.current;
    const initData: LayoutInitType = {
      modeAbleArea: initMenu.ableArea ? 'on' : 'off',
      modeInOut: initMenu.inOut === 'in' ? 'in' : 'out',
      display: initMenu.center,
      fitNum: Number(initMenu.fitType),
      additionalShow: initMenu.additionalShow,
      modeCursor: 'select',
    };
    UiManager.ins.emit('r->l:container:didmount', { container, kijshopCd, shopOrderId, orderId, initData });
    UiManager.ins.on('r->l:ruler', (v) => {
      if (v.display) {
        setIsRuler(true);
      } else {
        setIsRuler(false);
      }
    });
    UiManager.ins.on('r->l:change-operation-cursor', (v) => {
      if (!layoutEditorContainer.current) return;
      if (v.mode === 'pan-zoom') {
        layoutEditorContainer.current.style.cursor = 'grab';
        layoutEditorContainer.current.addEventListener('mousedown', grabbingCursor);
        layoutEditorContainer.current.addEventListener('mouseup', grabedCursor);
      } else if (v.mode === 'select') {
        layoutEditorContainer.current.style.cursor = 'default';
        layoutEditorContainer.current.removeEventListener('mousedown', grabbingCursor);
        layoutEditorContainer.current.removeEventListener('mouseup', grabedCursor);
      } else {
        layoutEditorContainer.current.style.cursor = `url(${iconTextCursor}) 13 13, text`;
      }
    });
    return () => {
      if (isSave) return;
      albumPage && UiManager.ins.emit('r->l:container:willunmount', { albumPage });
      UiManager.ins.off('r->l:ruler');
      UiManager.ins.off('r->l:change-operation-cursor');
      if (layoutEditorContainer.current) {
        layoutEditorContainer.current.style.cursor = 'default';
        layoutEditorContainer.current.removeEventListener('mousedown', grabbingCursor);
        layoutEditorContainer.current.removeEventListener('mouseup', grabedCursor);
      }
    };
  }, [albumPage]);

  /* templateXml取得処理 */
  const getTemplateXML = useCallback<any>(async (targetPath: string, templateName: string, pageType: string) => {
    const nextPathData = await TemplateLoaderManager.checkNextPath(targetPath, pageType);
    const data = await TemplateLoaderManager.ins.onGetTargetFile(nextPathData.path, 'templateXml', templateName);
    if (data) return data;
    // if (nextPath) return await getTemplateXML(nextPath, templateName, pageType);
    // if (nextPath) return await getTemplateXML(nextPath, 'templateXML', templateName);
    return undefined;
  }, []);


  const onGetXml = useCallback(async (loadData: any, originData: any) => {
    const _data = loadData;
    return new Promise<any>(async (resolve) => {
      let templatesXmlData: { xml: any, pageType: string } = { xml: '', pageType: '' };
      let templateXmlData: any;
      let thumbnailData: string;
      let zipLevel: { isZip: boolean, level: number };
      // let data = await TemplateLoaderManager.ins.onGetTargetFile(targetPath, 'templateXml', templateName);
      const data = await getTemplateXML(_data.targetPath, _data.templateName, _data.templateType);
      thumbnailData = _data.thumbnail;
      zipLevel = { isZip: false, level: 0 };
      if (data?.templates) {
        const target = data?.templates.find((v: any) => v.$.path === loadData.id);
        if (target) {
          const dd = target.templateGroup[0].templateData.find((v: any) => v.$.path === _data.targetPath);
          if (dd) {
            templatesXmlData = { xml: dd, pageType: target.$.name };
          }
        }
      }
      if (data?.thumbnail) {
        thumbnailData = URL.createObjectURL(data.thumbnail as Blob);
      }
      if (data?.zipLevel) {
        zipLevel = data.zipLevel;
      }
      if (data?.template) {
        templateXmlData = data.template;
      }
      const result = {
        itemData: {
          id: originData.itemData.id,
          templateName: _data.templateName,
          thumbnail: _data.thumbnail,
          templateXmlUrl: originData.itemData.templateXmlUrl,
          targetPath: _data.targetPath,
        },
        templates: templatesXmlData,
        template: templateXmlData,
        thumbnail: thumbnailData,
        zipLevel,
      };
      dispatch(layoutActions.addAcquiredTemplateList(result));
      resolve(result);
    });
  }, []);


  /* 復元、アルバムセット処理 */
  const onSetAlbum = useCallback(async (v: AddAlbumParam) => new Promise<AlbumPage>(async (resolve) => {
    const optionTypeList = albumInfo.option.map((v) => v.xml.viewModel.pageType ?? '');
    const optionPrintTypeList = albumInfo.opPrint.map((v) => v.xml.viewModel.pageType ?? '');
    const target = templatesXmlArr.find((v2) => v2.$.path === v.itemData.id);
    const isOption = !!optionPrintTypeList.find(v => v === target?.templateGroup?.[0].templateData[0].$.type)
      || !!optionTypeList.find((v) => (v === target?.templateGroup?.[0].templateData[0].$.type)
        || !!(target?.templateGroup?.find((v2: any) => v2.templateData?.find((v3: any) => v3.$.type === v))));
    const data = target?.templateGroup?.[0].templateData.find((v2: any) => v2.$.path === v.itemData.targetPath);
    const orderInfo = xml?.orderInfo?.infoData?.find((info) => info.xml.metaModel.id === orderId);
    const optionInfo = LayoutXmlUtile.getOptionInfoData(orderId, xml);
    const optionPartsData = LayoutXmlUtile.flatOptionParts(optionInfo);
    const partsData: XmlStructureOrderPartsData[] | undefined = orderInfo?.parts?.partsData;

    const pageData: Partial<TOrderPageDataViewModel>[] = [];
    if (!partsData) return;
    for (const v of partsData.concat(optionPartsData)) {
      if (!v || !v.page || !v.page.pageData) continue;
      for (const vv of v.page?.pageData) {
        pageData.push(vv.viewModel);
      }
    }
    const pageTypeId = target?.templateGroup?.[0].templateData[0].$.type;
    const pageType = target?.$.name;

    const _pageType = (() => {
      const opPrint = pageTypeList.opPrint.find((v) => v.pageTypeID === pageTypeId);
      if (opPrint) {
        return opPrint;
      }
      const cover = pageTypeList.cover.find((v) => v.pageTypeID === pageTypeId);
      if (cover) {
        return cover;
      }
      const top = pageTypeList.top.find((v) => v.pageTypeID === pageTypeId);
      if (top) {
        return top;
      }
      const page = pageTypeList.page.find((v) => v.pageTypeID === pageTypeId);
      if (page) {
        return page;
      }
      const end = pageTypeList.endCover.find((v) => v.pageTypeID === pageTypeId);
      if (end) {
        return end;
      }
      const option = pageTypeList.option.find((v) => v.pageTypeID === pageTypeId);
      if (option) {
        return option;
      }
    })();
    // const _pageType = pageTypeData.find((v) => v.name === pageType);
    if (!_pageType) return;
    const _page = pageData.find((v) => v.pageType === _pageType.pageTypeID && !isOption);
    const option = pageData.find((v) => v.pageType === _pageType.pageTypeID && isOption);
    const pageTypeID = _page ? _page.pageType : (option?.pageType ?? data.$.type);
    const pageNo = _page ? _page.displayPageNo! : (option?.displayPageNo ?? '***');
    const displayName = _page ? _page.displayPageType : (option?.displayPageType ?? pageType);
    let thumbPath = '';
    if (!v.isChange) {
      const readThumb = (): Promise<string> => new Promise((resolve) => {
        dispatch(apiActions.run(
          new ApiImagesGetOne({
            kijshopCd,
            path: v.thumbPath,
          }),
          {
            onSuccess: (v: Blob) => {
              const reader = new FileReader();
              reader.readAsDataURL(v);
              reader.onload = () => {
                resolve(reader.result as string);
              };
            },
          },
        ));
      });
      if (v.thumbPath) thumbPath = await readThumb();
    } else {
      thumbPath = v.thumbPath;
    }
    // const isOption = !!albumInfo.option.find((v) => v.xml.viewModel.pageType === pageTypeID);
    const optionLevel = (() => {
      if (albumInfo.opPrint.find(v => v.xml.viewModel.pageType === pageTypeID)) {
        return albumInfo.opPrint.findIndex((v) => v.xml.viewModel.pageType === pageTypeID) + 1;
      } else {
        return albumInfo.option.findIndex((v) => v.xml.viewModel.pageType === pageTypeID) + 1;
      }
    })();

    const getGroupChildren = () => {
      const parentTarget = cloneDeep(templatesXmlArr.find((_v) =>
        _v.templateGroup.find((v2: any) => v2.templateData.find((v3: any) =>
          (v3.$.path.indexOf(v.itemData.templateName) > -1) && pageTypeIdArr.includes(v3.$.type)))));
      // なぜかSTGと本番でデータが違うためエラー回避を追加
      if (!parentTarget) return [];
      if (parentTarget.$.name.indexOf('cover') !== -1) {
        parentTarget.templateGroup[0].templateData = parentTarget.templateGroup[0].templateData.filter((v: any) => {
          if (v.cover) {
            const min = Number(v.cover[0].$.minPageCount) || 0;
            const max = Number(v.cover[0].$.maxPageCount) || 0;
            return !(min > (Number(albumSetting.pageCount)) || max < (Number(albumSetting.pageCount)));
          } else {
            return true;
          }
        });
      }
      return (parentTarget.templateGroup[0].templateData as any[]).map((v) => ({
        id: v.$.path,
        templateName: (() => {
          const _name = v.$.path.split('/')[v.$.path.split('/').length - 1];
          return _name.indexOf('___') !== -1 ? _name.substring(0, _name.indexOf('___')) : _name;
        })(),
        thumbnail: getTargetPathUrl(v.$.path + '/thumbnail.jpg'),
        templateXmlUrl: getTargetPathUrl(parentTarget.$.path),
        targetPath: v.$.path,
        templateType: v.$.type,
      }));
    };

    const aquireData = getGroupChildren();

    const additionalDatas: any[] = [];
    if (v.template.templateData.additionalFrame?.length) {
      for (const child of aquireData) {
        const childXml = await onGetXml({
          ...child,
        }, {
          ...v,
        });
        additionalDatas.push(childXml);
      }
    }
    const picData: RestorePicData[] = restorePicData?.pictureData ? JSON.parse(restorePicData.pictureData) : [];
    const findOrder = picData.find((v2: RestorePicData) => v2.optionLevel === optionLevel);
    const findParts = findOrder?.data.find((v2) => padding(Number(v2.partsId), 3) === v.indexes[1]);
    const filterPicData = findParts?.picData.filter((v2) => padding(Number(v2.pageId), 3) === v.indexes[2]);
    const album = new AlbumPage(
      v.template,
      `layout-order:${UuidGenerator.create()}`,
      pageTypeID,
      pageType,
      displayName,
      pageNo,
      { templatePath: v.itemData.thumbnail, image: thumbPath ?? v.thumbnail },
      v.zipLevel,
      undefined,
      v.indexes,
      isOption,
      v.memo,
      additionalDatas.length ? additionalDatas : undefined,
      v.templateBlob,
      v.isChange ? undefined : filterPicData,
      v.isChange,
    );
    album.restoreMount = true;
    dispatch(layoutActions.addAlbumPage({
      type: (isOption && !albumInfo.opPrint.find((v) => v.xml.viewModel.pageType === pageTypeID)) ? 'option' : pageType,
      album,
    }));
    UiManager.ins.emit('r->l:add-album', {
      album, callback: () => resolve(album), restoreData: {
        kijshopCd,
        shopOrderId,
        orderId,
      },
    });
    dispatch(layoutActions.setCurrentAlbumPage(album));
    dispatch(layoutActions.setPage('edit'));
    UiManager.ins.emit('r->l:select-album', { album });
    // window.setTimeout(() => {
    //   resolve();
    // }, 3000);
  }), [templatesXmlArr, xml, albumInfo, albumSetting, pageTypeIdArr, restorePicData]);

  useEffect(() => {
    if (restoreInit || !layoutEditorContainer.current || !restoreTemplates.length) return;
    setRestoreInit(true);
    const initData: LayoutInitType = {
      modeAbleArea: initMenu.ableArea ? 'on' : 'off',
      modeInOut: initMenu.inOut === 'in' ? 'in' : 'out',
      display: initMenu.center,
      fitNum: Number(initMenu.fitType),
      additionalShow: initMenu.additionalShow,
      modeCursor: 'select',
    };
    const container = layoutEditorContainer.current;
    const totalCount = restoreTemplates.length;

    const setAlbum = () => new Promise<void>(async (resolve) => {
      let count = 0;
      const albums: AlbumPage[] = [];
      for (const temp of restoreTemplates) {
        const album = await onSetAlbum(temp);
        await layoutDidMount();
        count++;
        album && UiManager.ins.emit('r->l:container:willunmount', { albumPage: album, isRestore: true });
        UiManager.ins.off('r->l:ruler');
        UiManager.ins.off('r->l:change-operation-cursor');
        if (layoutEditorContainer.current) {
          layoutEditorContainer.current.style.cursor = 'default';
          layoutEditorContainer.current.removeEventListener('mousedown', grabbingCursor);
          layoutEditorContainer.current.removeEventListener('mouseup', grabedCursor);
        }
        UiManager.ins.emit('l->r:wait-loading', { message: `復元処理中です。${count}/${totalCount}` });
        albums.push(album);
      }
      xml && await SelectIdChecker.check(xml, kijshopCd, shopOrderId, orderId);
      xml && await FontFamilyChecker.check(xml, kijshopCd, shopOrderId, orderId);
      xml && await ThumbChecker.check(xml, kijshopCd, shopOrderId, orderId, false, albums);
      resolve();
    });

    const layoutDidMount = () => new Promise<void>((resolve) => {
      UiManager.ins.emit('r->l:container:didmount', {
        container,
        kijshopCd,
        shopOrderId,
        orderId,
        initData,
        callback: () => resolve(),
      });
    });

    const action = async () => {
      await setAlbum();
      await new Promise<void>((resolve) => {
        const fontList = store.getState().layout.unfindFontList.map((v) => `・${v}`);
        if (fontList.length) {
          dispatch(dialogActions.pushMessage({
            title: 'フォント読み込みエラー',
            message: [
              'お使いのPCで見つからないフォントが使用されています。',
              '以下をデフォルトのフォントに置き換えてレイアウトを続けますか？',
              '',
              ...fontList,
            ],
            buttons: [
              {
                label: 'いいえ', callback: () => {
                  dispatch(dialogActions.pop());
                  dispatch(push(preparationPath));
                },
              },
              {
                label: 'はい', callback: () => {
                  dispatch(dialogActions.pop());
                  dispatch(layoutActions.pushUnFindFontList(null));
                  resolve();
                },
              },
            ],
          }));
        } else {
          resolve();
        }
      });
      dispatch(layoutActions.setRestoreTemplates([]));
      UiManager.ins.emit('l->r:wait-loading', { message: '' });
      callbackRestore();
    };
    UiManager.ins.on('r->l:change-operation-cursor', (v) => {
      if (!layoutEditorContainer.current) return;
      if (v.mode === 'pan-zoom') {
        layoutEditorContainer.current.style.cursor = 'grab';
        layoutEditorContainer.current.addEventListener('mousedown', grabbingCursor);
        layoutEditorContainer.current.addEventListener('mouseup', grabedCursor);
      } else if (v.mode === 'select') {
        layoutEditorContainer.current.style.cursor = 'default';
        layoutEditorContainer.current.removeEventListener('mousedown', grabbingCursor);
        layoutEditorContainer.current.removeEventListener('mouseup', grabedCursor);
      } else {
        layoutEditorContainer.current.style.cursor = `url(${iconTextCursor}) 13 13, text`;
      }
    });
    action();
  }, [restoreTemplates, layoutEditorContainer.current]);

  const grabbingCursor = () => {
    layoutEditorContainer.current!.style.cursor = 'grabbing';
  };
  const grabedCursor = () => {
    layoutEditorContainer.current!.style.cursor = 'grab';
  };

  const saveCreateThumbnail = async (v: {
    albumPage: AlbumPage;
    callback: () => void;
  }) => {
    const initData: LayoutInitType = {
      modeAbleArea: 'on',
      modeInOut: initMenu.inOut === 'in' ? 'in' : 'out',
      display: initMenu.center,
      fitNum: Number(initMenu.fitType),
      additionalShow: initMenu.additionalShow,
      modeCursor: 'select',
    };
    const container = layoutEditorContainer.current;
    if (container) {
      dispatch(layoutActions.setCurrentAlbumPage(v.albumPage));
      initData.modeAbleArea = v.albumPage.isGuide ? 'on' : 'off';
      const didMount = () => new Promise<void>((resolve) => {
        UiManager.ins.emit('r->l:select-album', {
          album: v.albumPage, callback: () => {
            UiManager.ins.emit('r->l:container:didmount', {
              container,
              kijshopCd,
              shopOrderId,
              orderId,
              initData,
              callback: () => resolve(),
            });
          },
        });
      });
      const willUnmount = () => new Promise<void>((resolve) => {
        v.albumPage && UiManager.ins.emit('r->l:container:willunmount', {
          albumPage: v.albumPage,
          isRestore: false,
          isComp: true,
        });
        UiManager.ins.off('r->l:ruler');
        UiManager.ins.off('r->l:change-operation-cursor');
        if (layoutEditorContainer.current) {
          layoutEditorContainer.current.style.cursor = 'default';
          layoutEditorContainer.current.removeEventListener('mousedown', grabbingCursor);
          layoutEditorContainer.current.removeEventListener('mouseup', grabedCursor);
        }
        resolve();
      });
      const selectAlbum = () => new Promise<void>((resolve) => {
        UiManager.ins.emit('r->l:select-album', { album: v.albumPage, callback: () => resolve() });
      });
      await selectAlbum();
      await didMount();
      await willUnmount();
      v.callback();
    }
  };

  useEffect(() => {
    UiManager.ins.on('l->r:set-album', saveCreateThumbnail);
    return (() => {
      UiManager.ins.off('l->r:set-album');
    });
  }, []);

  return (
    <div // id={'layout-editor-container'}
      className={`image_frame${isRuler ? '' : ' close'}`}
    >
      <div
        className="image_frame__inner"
        style={(isRestore || isOnSave) ? { display: 'none' } : undefined}
        ref={layoutEditorContainer}
      >
        <div className="image_frame__ruler_origin" />
      </div>
      {/*<canvas ref={canvas} width={canvasWidth} height={canvasHeight} />*/}
    </div>
  );
};

type Props = {
  initMenu: MenuType;
  isRestore: boolean;
  callbackRestore: () => void;
  xml: XmlStructureModel | null;
  pageTypeIdArr: any[],
}

export const LayoutEditTemplate = (props: Props) => {
  const { initMenu, isRestore, callbackRestore, xml, pageTypeIdArr } = props;
  const dispatch = useDispatch();
  const [toast, setToast] = useState(false);

  // -- 右クリック --
  // // -- 右クリック --
  // const handlerRightClick = useCallback(async (e: React.MouseEvent<HTMLDivElement>) => {
  //   e.preventDefault();
  // }, [ ]);


  useEffect(() => {
    const handlerRightClick = (e: MouseEvent) => {
      e.preventDefault();
    };
    UiManager.ins.on('l->r:show-toast', (v) => {
      setToast(v.isToast);
      if (v.isToast) {
        dispatch(layoutActions.setFitErrorList(v.target.pageId || ''));
      } else {
        if (v.target.name !== 'stage-container') {
          dispatch(layoutActions.removeFitErrorList(v.target.pageId || ''));
        }
      }
    });
    window.addEventListener('contextmenu', handlerRightClick);
    return () => {
      UiManager.ins.off('l->r:show-toast');
      window.removeEventListener('contextmenu', handlerRightClick);
    };
  }, []);


  return (
    <div
      className="layout_edit_template"
      // onContextMenu={handlerRightClick}
    >
      <ImageFrame
        initMenu={initMenu}
        isRestore={isRestore}
        callbackRestore={callbackRestore}
        xml={xml}
        pageTypeIdArr={pageTypeIdArr}
      />
      <ToolBox />
      <TemplateItemsTooltip />
      {/*<ImageInfo />*/}
      <ErrorToast
        text={['写真枠内に空白部分があります。']}
        className={toast ? 'enter' : 'leave'}
      />
    </div>
  );
};

import { MetaShopOrderGetResponse } from "../api/front/meta/api-meta-shop-order";

type IsOneStop = 1 | 0 | boolean | null | undefined;

type NullableMetaShopOrderGetResponse = {
  [P in keyof MetaShopOrderGetResponse]: P extends 'isOneStop' ? IsOneStop
    : P extends 'isOneStopCancel' ? IsOneStop
    : MetaShopOrderGetResponse[P] | null;
}
/** ラボ発注が正常に完了しているかチェック機能 */
export class LaboOrderCompChecker {
  /**
   * 一定時間が経過しているかチェック
   * @param info ShopOrderMeta情報
   */
  static checkTimeOut(info: NullableMetaShopOrderGetResponse) {
    const checkDate = info.isOneStop ? info.oneStopOrderDate : info.lastUpdateDate;
    if (!checkDate) return false;
    const elapsedTime = Math.floor((Date.now() - (Date.parse(checkDate))))
    /* ミリ秒 -> 分に変換 */
    const m = 1000 * 60;
    /* 不都合ありと判断する経過時間を何分にするか指定 */
    const limit = 30
    return elapsedTime > m * limit
  }
}

import { ApiBaseFront } from "../../front/api-base-front";

type Params = {
  kijshopCd: string,
  orderId: string, // CloudFolderの注文番号
  shopOrderId: string, //PPM-Cloudの注文番号
  lastChangerId: string, // staffID　か、マシンID
};

export class ApiCfOrderUpdate extends ApiBaseFront {
  constructor(param: Params) {
    const {
      kijshopCd,
      orderId,
      shopOrderId,
      lastChangerId,
    } = param;

    super(
      'POST',
      `/api/v1/c-folder/shop/${kijshopCd}/order/${orderId}/ppm/update`,
      'JSON',
      {
        shopOrderId,
        lastChangerId,
      },
    );
  }
}

import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { ApiRetouchGet, RetouchGetResponse, RetouchKind, SimpleRetouchInfo } from "../../../api/back/retouch/api-retouch";
import { InitRetouchCheck } from "../../../components/pages/retouch/retouch";
import { XmlStructureModel } from "../xml-structure-model"
import { RootState } from "../../../app/store";
import { apiActions } from "../../../slices/api-slice";
import { ResponseBase } from "../../../api/response-base";
import { CfOrderGetOneDataEntity } from "../../../api/cf/api-cf-order-get-one/api-cf-order-get-one";
import { XMLRetouchModel } from "./xml-retouch-model";

type Param = {
  xml: XmlStructureModel,
  cfData?: CfOrderGetOneDataEntity,
  kijshopCd?: string,
  shopOrderId?: string,
  dispatch: ThunkDispatch<
    RootState,
    unknown,
    Action
  >,
}

export const createOneStopRetouch = async(param: Param) => {
  const {
    xml,
    cfData,
    kijshopCd = '',
    shopOrderId = '',
    dispatch,
  } = param;
  let parentId = '0';
  const [
    simpleRetouch,
    cullingRetouch,
    selectRetouch,
  ] = await Promise.all([
    getRetouchList(dispatch, 'simpleRetouchName'),
    getRetouchList(dispatch, 'cullingRetouchName'),
    getRetouchList(dispatch, 'selectRetouchName'),
  ]);
  console.log(
    simpleRetouch,
    cullingRetouch,
    selectRetouch,
  )
  if (!xml.orderInfo?.infoData) throw new Error('');
  const parentXml = xml.orderInfo?.infoData?.find((v) => v.xml.viewModel.surrogateProcess?.id === '2010');
  if (parentXml?.xml.metaModel.id) parentId = parentXml.xml.metaModel.id;
  const initRetouch: Record<string, SimpleRetouchInfo & {selectID: string[]}> = {};
  xml.orderInfo?.infoData?.forEach((v) => {
    if (v.xml.metaModel.parentId === parentId) {
      const retouchId = v.xml.viewModel.goods?.goodsId;
      const targetRetouchInfo = simpleRetouch.simpleRetouchInfo.find((v) => v.productNameId === retouchId);
      if (targetRetouchInfo) {
        v.parts?.partsData?.forEach((vv) => {
          vv.page?.pageData?.forEach((vvv) => {
            const orderPicture = vvv.viewModel.orderPicture?.data ?? [];
            const target = initRetouch[targetRetouchInfo.retouchProductNameId];
            const selectIdList = orderPicture.map((v) => v.selectID ?? '').filter((v) => v);
            if (!target) {
              initRetouch[targetRetouchInfo.retouchProductNameId] = (simpleRetouch.productNameId, {
                ...targetRetouchInfo,
                selectID: selectIdList,
              });
            } else {
              target.selectID.push(...selectIdList);
            }
          });
        })        
      }
    }
  });
  if (cfData) {
    if (cfData.cullingRetouchOptId) {
      const target = cullingRetouch.simpleRetouchInfo.find((v) => v.retouchProductNameId === cfData.cullingRetouchOptId);
      if (target) {
        if (!initRetouch[target.productNameId]) {
          initRetouch[target.productNameId] = {
            ...target,
            selectID: [],
          }
        }
      }
    }

    if (cfData.useImageRetouchOptId) {
      const target = selectRetouch.simpleRetouchInfo.find((v) => v.retouchProductNameId === cfData.useImageRetouchOptId);
      if (target) {
        if (!initRetouch[target.productNameId]) {
          initRetouch[target.productNameId] = {
            ...target,
            selectID: [],
          }
        }
      } 
    }
  }
  
  const model = new XMLRetouchModel({
    infoOutputCount: '1',
    kijshopCd,
    shopOrderId,
    structure: xml,
    infoData: xml.orderInfo.infoData,
    checkList: Object.values(initRetouch),
  });
  return model;
}

const getRetouchList = (dispatch: Param['dispatch'], kind: RetouchKind) => new Promise<RetouchGetResponse[0]>((resolve, reject) => {
  dispatch(apiActions.run(
    new ApiRetouchGet({ progFlg: kind }),
    {
      onSuccess: (res: ResponseBase<RetouchGetResponse>) => {
        const data = res.body.data?.[0];
        if (data) {
          resolve(data);
        } else {
          reject();
        }
      },
      onError: () => {
        reject();
      }
    },
  ));
})
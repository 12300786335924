import { ApiBaseFront } from "../../../front/api-base-front";

type Params = {
  kijshopCd: string,
  token: string,
};

export type Cf2PPMTokenCheckResponse = {
  shopOrderId: string,
  sessionId: string,
  machineId: string,
  token: string,
  isAdmin: 0 | 1,
  shopName: string,
}

export class ApiCheckCf2PPMToken extends ApiBaseFront<ApiCheckCf2PPMToken> {
  constructor(param: Params) {
    super(
      'POST',
      '/api/v1/app/redirect/check_expiry_token',
      'JSON',
      param,
    );
  }
}

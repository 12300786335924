
const kijshopCd = ':kijshopCd';
const shopOrderId = ':shopOrderId';
const orderId = ':orderId';
const staffPkId = ':staffPkId';

const orders = `/${kijshopCd}/orders`;
const oneStopOrders = `/${kijshopCd}/one-stop`
const ordersPreparation = orders + `/${shopOrderId}/preparation`;
/* カート画面 ワンストップ用 */
const oneStopOrdersPreparation = oneStopOrders + `/${shopOrderId}/preparation`;
const ordersItemPage = ordersPreparation + `/${orderId}`;
const oneStopOrdersItemPage = oneStopOrdersPreparation + `/${orderId}`;
const ordersPrint = ordersItemPage + '/print';
const ordersAdd = ordersPreparation + '/add';
const oneStopOrdersAdd = oneStopOrdersPreparation + '/add';
const ordersEdit = ordersItemPage + '/add';
const oneStopOrdersEdit = oneStopOrdersItemPage + '/add';
const ordersLayout = ordersItemPage + '/layout';
const ordersRetouch = ordersPreparation + '/retouch';
const uploadManager = orders + '/upload-manager';
const config = `/${kijshopCd}/config`;
const configSystem = config + '/system';
const configAccount = config + '/account';
const configPc = config + '/pc';
const staff = `/${kijshopCd}/staff`;
const staffMngEnable = `/${kijshopCd}/staff-mng/enable`;
const staffMngMailChange = `/${kijshopCd}/staff-mng/mail-change`;
const staffMngPasswordChange = `/${kijshopCd}/staff-mng/password-change`;
const staffPasswordRegister = `/${kijshopCd}/staff-password-register`;
const shop = `/${kijshopCd}/shop`;
const cFolderOrder = `/${kijshopCd}/c-folder-order`;
const cloudFolder = `/${kijshopCd}/cloud-folder`;

// react-router に PathParam があるので注意
export type PathParams = {
  kijshopCd: string,
  shopOrderId: string,
  orderId: string,
  staffPkId: string,
};

const replace = (path: string, param: Partial<PathParams>) => {
  let str = path;
  if (param.kijshopCd) {
    str = str.replace(kijshopCd, param.kijshopCd);
  }
  if (param.shopOrderId) {
    str = str.replace(shopOrderId, param.shopOrderId);
  }
  if (param.orderId) {
    str = str.replace(orderId, param.orderId);
  }
  if (param.staffPkId) {
    str = str.replace(staffPkId, param.staffPkId);
  }
  return str;
};

export const RoutingPath = {
  // - ログイン -
  // -- 店舗ログイン --
  login: '/login',
  // -- スタッフログイン --
  staffLogin: '/staff/login',
  // - 注文 -
  // -- 注文一覧 --
  orders,
  // -- 注文作成準備 -
  ordersPreparation,
  // -- 注文作成準備(ワンストップ) -
  oneStopOrdersPreparation,
  // -- 注文画像印刷 --
  ordersPrint,
  // -- 注文作成画面 --
  ordersAdd,
  // -- 注文作成画面(ワンストップ) --
  oneStopOrdersAdd,
  // -- 注文編集画面 --
  ordersEdit,
  // -- 注文編集画面(ワンストップ) --
  oneStopOrdersEdit,
  // -- レイアウト --
  ordersLayout,
  // -- レタッチ --
  ordersRetouch,
  // -- Upload Manager --
  uploadManager,
  // - config -
  // -- システム設定 --
  configSystem,
  // -- アカウント設定 --
  configAccount,
  // -- PC設定 --
  configPc,
  // - debug -
  debug:'/debug',
  // -- ui --
  debugUi: '/debug/ui',
  // -- dialog --
  debugDialog: '/debug/dialog',
  // -- xml parse --
  debugXml: '/debug/xml',
  // -- クラウドフォルダ店舗一覧 --
  shop,
  // -- クラウドフォルダ注文一覧 --
  cFolderOrder,
  // -- クラウドフォルダセッション移行 --
  cloudFolder,
  // - パス変換用 -
  cnv: {
    orders: (param: { kijshopCd: string }) => replace(orders, param),
    shop: (param: { kijshopCd: string }) => replace(shop, param),
    cFolderOrder: (param: { kijshopCd: string }) => replace(cFolderOrder, param),
    ordersPreparation: (param: { kijshopCd: string, shopOrderId: string }) => replace(ordersPreparation, param),
    oneStopOrdersPreparation: (param: { kijshopCd: string, shopOrderId: string }) => replace(oneStopOrdersPreparation, param),
    ordersPrint: (param: { kijshopCd: string, shopOrderId: string, orderId: string }) => replace(ordersPrint, param),
    ordersAdd: (param: { kijshopCd: string, shopOrderId: string }) => replace(ordersAdd, param),
    oneStopOrdersAdd: (param: { kijshopCd: string, shopOrderId: string }) => replace(oneStopOrdersAdd, param),
    ordersEdit: (param: { kijshopCd: string, shopOrderId: string, orderId: string }) => replace(ordersEdit, param),
    oneStopOrdersEdit: (param: { kijshopCd: string, shopOrderId: string, orderId: string }) => replace(oneStopOrdersEdit, param),
    ordersLayout: (param: { kijshopCd: string, shopOrderId: string, orderId: string }) => replace(ordersLayout, param),
    ordersRetouch: (param: { kijshopCd: string, shopOrderId: string }) => replace(ordersRetouch, param),
    uploadManager: (param: { kijshopCd: string }) => replace(uploadManager, param),
    configSystem: (param: { kijshopCd: string }) => replace(configSystem, param),
    configAccount: (param: { kijshopCd: string }) => replace(configAccount, param),
    configPc: (param: { kijshopCd: string }) => replace(configPc, param),
    staff: (param: { kijshopCd: string }) => replace(staff, param),
    staffMngEnable: (param: { kijshopCd: string }) => replace(staffMngEnable, param),
    staffMngMailChange: (param: { kijshopCd: string }) => replace(staffMngMailChange, param),
    staffMngPasswordChange: (param: { kijshopCd: string }) => replace(staffMngPasswordChange, param),
    staffPasswordRegister: (param: { kijshopCd: string }) => replace(staffPasswordRegister, param),
  },
  // - スタッフ管理 -
  staff,
  // - ウィザード -
  // -- 店舗パスワード忘れ (管理者ログイン画面→パスワードをお忘れの方はこちら) --
  adminPasswordReset: '/admin/password-reset',
  // -- スタッフパスワード忘れ (スタッフログイン画面→パスワードをお忘れの方はこちら) --
  staffPasswordReset: '/staff/password-reset',
  // -- スタッフ管理機能有効 (システム設定→店舗設定→スタッフ管理機能ON) --
  staffMngEnable: staffMngEnable,
  // -- スタッフ管理 メールアドレス変更 (システム設定→店舗設定→店舗管理メールアドレス→変更) --
  staffMngMailChange: staffMngMailChange,
  // -- スタッフ管理 パスワード変更 (システム設定→店舗設定→店舗パスワード→変更) --
  staffMngPasswordChange: staffMngPasswordChange,
  // -- スタッフパスワード登録 (スタッフログイン→仮会員の場合表示) --
  staffPasswordRegister: staffPasswordRegister,
};

import './remarks-container.scss';

export type RemarksData = {
  name: string,
  remarks: string,
}

type Props = {
  data: RemarksData[],
}
export const RemarksContainer = (props: Props) => {
  const {
    data,
  } = props;
  return (
    <>
      {data.map((v, i) => (
        <div key={`remark_cotainer_${i}`}>
          <div>&#9642;{v.name}</div>
          <div className="remarks_letter_container">
            {v.remarks}
          </div>
          {!i && <br />}
        </div>
      ))}
    </>
  )
}